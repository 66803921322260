import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CLOSE_ICON } from '@marvelapp/ballpark-theme';
import Box from '../Box';
import Flex from '../Flex';
import Icon from '../Icon';
import PinTR from '../PinTR';
import Text from '../Text';
/** @param {any} */
function HintBar(_a) {
    var { bg, image, title, description, callToAction, onClickClose } = _a, rest = __rest(_a, ["bg", "image", "title", "description", "callToAction", "onClickClose"]);
    const maxWidthTextContent = 650;
    return (_jsxs(Flex, Object.assign({ bg: bg, borderRadius: 2, flexDirection: ['column', 'column', 'row'], minHeight: 72, pb: [5, 5, 3], pl: [5, 5, 0], position: "relative", pr: [5, 5, onClickClose ? 40 : 5], pt: [4, 4, 3], width: "100%" }, rest, { children: [image && (_jsx(Flex, { alignItems: "center", justifyContent: "center", mx: 5, children: image })), _jsxs(Flex, { display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", ml: [0, 0, image ? 0 : 5], my: 1, children: [_jsx(Text, { as: "span", color: "white", fontSize: 3, fontWeight: 500, maxWidth: maxWidthTextContent, textAlign: ['center', 'center', 'left'], children: title }), _jsx(Text, { as: "span", color: "white", fontSize: 2, maxWidth: maxWidthTextContent, textAlign: ['center', 'center', 'left'], children: description })] }), callToAction && (_jsx(Flex, { alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", ml: [0, 0, 2], mt: [2, 2, 0], children: _jsx(Box, { ml: 2, children: callToAction }) })), onClickClose && (_jsx(PinTR, { padding: 2, cursor: "pointer", onClick: onClickClose, children: _jsx(Icon, { color: "white", display: "block", hoverColor: "snowDark", paths: CLOSE_ICON }) }))] })));
}
HintBar.defaultProps = {
    bg: 'purple',
};
export default HintBar;
