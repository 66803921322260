import { jsx as _jsx } from "react/jsx-runtime";
import { useCurrentUser, userSelectors } from '@marvelapp/ballpark-application';
import { Button, Link } from '@marvelapp/ballpark-components';
export function UpgradeButton() {
    const user = useCurrentUser();
    const isEnterpriseAndTrialExpired = userSelectors.isEnterpriseAndTrialExpired(user);
    const isLockedFreePlan = userSelectors.isLockedFreePlan(user);
    if (isEnterpriseAndTrialExpired || isLockedFreePlan) {
        return (_jsx(Link, { display: "block", "data-testid": "upgrade-button", to: { pathname: '/plans', state: { modal: true } }, children: _jsx(Button, { kind: "ghost", children: "Upgrade now" }) }));
    }
    return null;
}
