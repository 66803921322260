import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { SvgElement } from './styles';
function Svg(_a) {
    var { children } = _a, props = __rest(_a, ["children"]);
    return _jsx(SvgElement, Object.assign({}, props, { children: children }));
}
Svg.defaultProps = {
    display: 'block',
    fill: 'currentColor',
    height: '24px',
    viewBox: '0 0 24 24',
    width: '24px',
};
export default Svg;
