import { jsx as _jsx } from "react/jsx-runtime";
import { color, layout, space } from 'styled-system';
import styled from '@marvelapp/styled';
import { cursor, fill, hoverColor, hoverFill, transition, } from '../customProperties';
import { getDOMProps } from '../utils';
export const Svg = styled((props) => _jsx("svg", Object.assign({}, getDOMProps(props)))) `
  ${color};
  ${cursor};
  ${fill};
  ${layout};
  ${space};
  ${transition};

  &:hover {
    ${hoverColor};
    ${hoverFill};
  }
`;
