import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import theme, { CHECKBOX_TICK_ICON } from '@marvelapp/ballpark-theme';
import Icon from '../Icon';
import Loader from '../Loader';
import kinds from './kinds';
import { buttonSizes, iconSizes, loaderSizes } from './sizes';
import { ButtonElement, IconContainer, IconContainerWithText } from './styles';
const Button = forwardRef((_a, ref) => {
    var _b;
    var { children, hasSucceeded, isDisabled, isLoading, loaderWithText, isRounded, kind, size, tag, testId, translateOnHover = true } = _a, props = __rest(_a, ["children", "hasSucceeded", "isDisabled", "isLoading", "loaderWithText", "isRounded", "kind", "size", "tag", "testId", "translateOnHover"]);
    const disabledProps = isDisabled && Object.assign(Object.assign({}, kinds.disabled), { disabled: isDisabled });
    const loadingProps = isLoading && {
        bg: 'snow',
        boxShadow: 'button.loading',
        color: 'transparent',
        disabled: true,
        fill: 'snow',
        hoverColor: 'transparent',
        pointerEvents: 'none',
        position: 'relative',
        textShadow: 'none',
    };
    const loadingWithTextProps = isLoading &&
        loaderWithText && {
        color: props.color,
        display: 'flex',
        alignItems: 'center',
        gridColumnGap: 2,
    };
    const succeededProps = hasSucceeded && Object.assign({ bg: 'green', color: 'transparent', disabled: true, fill: 'green', hoverColor: 'transparent', pointerEvents: 'none', position: 'relative', textShadow: 'none' }, (_b = props === null || props === void 0 ? void 0 : props.overrideProps) === null || _b === void 0 ? void 0 : _b.hasSucceeded);
    const animation = {
        animate: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.7,
                delay: 0.15,
                ease: theme.easings.smooth.array,
            },
        },
        exit: {
            opacity: 0,
            scale: 0,
            transition: {
                duration: 0.6,
                ease: theme.easings.smooth.array,
            },
        },
        initial: {
            opacity: 0,
            scale: 0,
        },
    };
    const LoadingIconContainer = loaderWithText
        ? IconContainerWithText
        : IconContainer;
    const icons = (_jsxs(AnimatePresence, { children: [isLoading && (_jsx(LoadingIconContainer, { animate: animation.animate, exit: animation.exit, initial: animation.initial, children: _jsx(Loader, Object.assign({}, loaderSizes[size], { testId: "button-loader" })) }, "loader")), hasSucceeded && (_jsx(IconContainer, { animate: animation.animate, exit: animation.exit, initial: animation.initial, children: _jsx(Icon, Object.assign({ color: "white", paths: CHECKBOX_TICK_ICON }, iconSizes[size])) }, "tick"))] }));
    /* Order is important here because the last three objects contain stateful
     * props which should overwrite all the rest */
    const allProps = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, buttonSizes(isRounded)[size]), kinds[kind]), props), succeededProps), loadingProps), loadingWithTextProps), disabledProps);
    return (_jsxs(ButtonElement, Object.assign({ ref: ref, as: tag, role: "button", "data-testid": testId, translateOnHover: translateOnHover }, allProps, { children: [icons, children] })));
});
/** @type {any} */
Button.propTypes = {
    borderRadius: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    boxShadow: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    display: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    fill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    fontSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    fontWeight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    hoverBg: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    hoverBoxShadow: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    hoverColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    hoverFill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    lineHeight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    hasSucceeded: PropTypes.bool,
    isLoading: PropTypes.bool,
    /** margin */
    m: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    /** margin-top */
    mt: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-right */
    mr: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-bottom */
    mb: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-left */
    ml: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-left and margin-right */
    mx: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-top and margin-bottom */
    my: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding */
    p: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    /** padding-top */
    pt: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-right */
    pr: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-bottom */
    pb: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-left */
    pl: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-left and padding-right */
    px: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-top and padding-bottom */
    py: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
};
Button.defaultProps = {
    display: 'inline-block',
    fontFamily: 0,
    fontWeight: '500',
    hasSucceeded: false,
    isLoading: false,
    isRounded: true,
    size: 1,
    theme,
};
export default Button;
