import { jsx as _jsx } from "react/jsx-runtime";
import { AppleIcon, BagShoppingIcon, BuildingsIcon, ChecklistBoxSearchIcon, EarthIcon, FlagIcon, GamepadIcon, GraduateCapIcon, MacbookIcon, PeopleCircleIcon, PeopleTogetherIcon, SpaceIcon, TagIcon, WalletIcon, } from '@marvelapp/ballpark-icons';
export const getIconForFilter = (id) => {
    switch (id) {
        case 'GROUP_BELIEFS_PROLIFIC':
            return _jsx(SpaceIcon, {});
        case 'GROUP_HEALTH_PROLIFIC':
            return _jsx(AppleIcon, {});
        case 'GROUP_FAMILY__RELATIONSHIPS_PROLIFIC':
            return _jsx(PeopleTogetherIcon, {});
        case 'GROUP_LANGUAGES_PROLIFIC':
            return _jsx(FlagIcon, {});
        case 'GROUP_GEOGRAPHIC_PROLIFIC':
            return _jsx(EarthIcon, {});
        case 'FILTER_EMPLOYMENT':
        case 'FILTER_COMPANY_SIZE':
        case 'FILTER_INDUSTRY':
        case 'FILTER_JOB_TITLES':
        case 'GROUP_WORK_PROLIFIC':
            return _jsx(BuildingsIcon, {});
        case 'FILTER_EDUCATION_LEVEL':
        case 'FILTER_SKILLS':
        case 'GROUP_EDUCATION_PROLIFIC':
            return _jsx(GraduateCapIcon, {});
        case 'FILTER_ETHNICITY':
        case 'FILTER_GENDER':
        case 'GROUP_GENERAL':
            return _jsx(PeopleCircleIcon, {});
        case 'FILTER_HOUSEHOLD_INCOME':
        case 'GROUP_FINANCE_PROLIFIC':
            return _jsx(WalletIcon, {});
        case 'GROUP_LIFESTYLE_AND_INTERESTS_PROLIFIC':
            return _jsx(GamepadIcon, {});
        case 'GROUP_TECHNOLOGY_AND_ONLINE_BEHAVIOUR_PROLIFIC':
            return _jsx(MacbookIcon, {});
        case 'GROUP_SHOPPING_AND_CONSUMER_HABITS_PROLIFIC':
            return _jsx(BagShoppingIcon, {});
        case 'GROUP_OTHER_PROLIFIC':
            return _jsx(ChecklistBoxSearchIcon, {});
        case 'FILTER_TOPICS':
        default:
            return _jsx(TagIcon, {});
    }
};
