import { jsx as _jsx } from "react/jsx-runtime";
import * as Toggle from '@radix-ui/react-toggle';
import * as React from 'react';
import theme from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box, Flex, Tooltip } from '@marvelapp/ui';
/**
 * @deprecated Use
 * ```
 * import { BtwToggle } from '@marvelapp/ballpark-components'
 * ```
 * instead
 */
export function SettingsToggle({ disabledTooltipPosition = 'left', disabledTooltipText, isChecked, isDisabled = false, onChange, testId, }) {
    return (_jsx(Flex, { alignItems: "center", children: _jsx(Tooltip, { content: isDisabled && disabledTooltipText, position: disabledTooltipPosition, children: _jsx(ToggleRoot, { "data-state": isChecked ? 'on' : 'off', "data-testid": testId, disabled: isDisabled, onPressedChange: onChange, children: _jsx(SettingToggle, { bg: "smokeDark", activeBg: "primary", size: 42 }) }) }) }));
}
const DISTANCE = 3;
const ToggleRoot = styled(Toggle.Root) `
  position: relative;
  border: 0;
  padding: 0;
  cursor: pointer;
  background: none;
`;
const SettingToggle = styled(Box) `
  background-color: ${theme.colors.smokeDark};
  border-radius: ${(props) => props.size / 2}px;
  width: ${(props) => props.size - DISTANCE * 2}px;
  height: ${(props) => props.size / 2}px;
  transition: all 300ms ${theme.easings.smooth.string};
  ${ToggleRoot}[data-state='on'] & {
    background-color: ${theme.colors.primary};
    &:after {
      left: calc(100% - ${DISTANCE}px);
      transform: translateX(-100%);
    }
  }
  ${ToggleRoot}[data-disabled] & {
    opacity: 0.5;
    pointer-events: none;
  }
  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: ${(props) => props.size / 2 - DISTANCE * 2}px;
    height: ${(props) => props.size / 2 - DISTANCE * 2}px;
    background-color: white;
    border-radius: ${(props) => props.size / 2 - DISTANCE * 2}px;
    transition: all 300ms ${theme.easings.smooth.string};
  }
`;
