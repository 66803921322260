import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import Box from './Box';
/** @type {any} */
const Position = forwardRef((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsx(Box, Object.assign({ position: "relative", ref: ref }, props, { children: children })));
});
export default Position;
