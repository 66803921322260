import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Position from '../Position';
import { InputElement, LabelElement } from './styles';
/** @param {any} */
function Toggle(_a) {
    var { activeColor = 'marvel', disabled = false, display, id, inactiveColor = 'smokeDark', isChecked, size = 60, 'data-testid': testId } = _a, inputProps = __rest(_a, ["activeColor", "disabled", "display", "id", "inactiveColor", "isChecked", "size", 'data-testid']);
    return (_jsxs(Position, { "data-testid": testId, display: display, position: "relative", children: [_jsx(InputElement, Object.assign({ bg: activeColor, checked: isChecked, disabled: disabled, id: id, type: "checkbox" }, inputProps, { "data-testid": `${testId}-input` })), _jsx(LabelElement, { bg: inactiveColor, "data-testid": `${testId}-label`, disabled: disabled, htmlFor: id, size: size })] }));
}
export default Toggle;
