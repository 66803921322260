import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as React from 'react';
import { ArrowRightIcon, BranchesIcon, ExpandIcon, EyeIcon, FileIcon, PlayIcon, SettingsSliderIcon, } from '@marvelapp/ballpark-icons';
import { BtwButton } from './BtwButton';
const DownloadCsv = forwardRef(function DownloadCsv(props, ref) {
    return (_jsx(BtwButton, Object.assign({ leadingIcon: _jsx(FileIcon, {}), ref: ref, size: "sm", variant: "ghost" }, props, { children: "Download CSV" })));
});
const ViewAllResponses = forwardRef(function ViewAllResponses(props, ref) {
    return (_jsx(BtwButton, Object.assign({ ref: ref, size: "sm", trailingIcon: _jsx(ArrowRightIcon, {}), variant: "ghost" }, props, { children: "View all responses" })));
});
const ViewBreakdown = forwardRef(function ViewBreakdown(props, ref) {
    return (_jsx(BtwButton, Object.assign({ leadingIcon: _jsx(ExpandIcon, {}), ref: ref, size: "sm", variant: "secondary", testId: "view-fullscreen" }, props, { children: "View full breakdown" })));
});
const Conditions = forwardRef(function Conditions(props, ref) {
    return (_jsx(BtwButton, Object.assign({ leadingIcon: _jsx(BranchesIcon, {}), ref: ref, size: "sm", variant: "ghost" }, props, { children: "Conditions" })));
});
const VideoGuide = forwardRef(function VideoGuide(props, ref) {
    return (_jsx(BtwButton, Object.assign({ leadingIcon: _jsx(PlayIcon, {}), ref: ref, size: "sm", variant: "ghost" }, props, { children: "Add video guide" })));
});
const Preview = forwardRef(function Preview(props, ref) {
    return (_jsx(BtwButton, Object.assign({ leadingIcon: _jsx(EyeIcon, {}), ref: ref, size: "sm", variant: "ghost" }, props, { children: "Preview" })));
});
const Options = forwardRef(function Options(props, ref) {
    return (_jsx(BtwButton, Object.assign({ leadingIcon: _jsx(SettingsSliderIcon, {}), ref: ref, size: "sm", variant: "ghost" }, props, { children: "Options" })));
});
export const StepButtons = {
    Conditions,
    DownloadCsv,
    Options,
    Preview,
    VideoGuide,
    ViewAllResponses,
    ViewBreakdown,
};
