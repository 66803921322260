import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Slot, Slottable } from '@radix-ui/react-slot';
import { forwardRef } from 'react';
import * as React from 'react';
import { tv } from 'tailwind-variants';
import { ChildrenWithIcon, } from './ChildrenWithIcon';
const baseButtonStyles = [
    // Basic reset
    'appearance-none',
    'bg-transparent',
    'border-0',
    'cursor-pointer',
    'ring-1',
    'ring-transparent',
    'select-none',
    // Layout
    'py-0',
    'flex',
    'min-w-0',
    'box-border',
    'items-center',
    'justify-center',
    // Typography
    'font-body',
    'font-semibold',
    'text-center',
    'no-underline',
    'tracking-normal',
    // Transition
    'duration-300',
    'ease-smooth',
    'transition-combined',
    'will-change-transform',
    // Repeated active styles
    'active:translate-y-px',
    'active:outline-transparent',
    // Repeated focus styles
    'focus-visible:outline-none',
    'focus-visible:outline-gray-300',
    // It's easier and more maintainable to replicate the disabled state across
    // all variants, than to try and override create a custom one for each variant
    'disabled:bg-gray-200',
    'disabled:text-gray-500',
    'disabled:ring-transparent',
    'disabled:shadow-none',
    'disabled:cursor-not-allowed',
    'disabled:hover:translate-y-0',
    'disabled:active:translate-y-0',
];
const secondaryButtonStyles = [
    'bg-white',
    'text-gray-900',
    'ring-gray-600/20',
    'shadow-sm-longer',
    // Hover state
    'hover:bg-gray-50',
    'hover:shadow-md',
    'hover:ring-gray-600/40',
    'hover:-translate-y-px',
    // Focus state
    'focus-visible:ring-gray-600',
    // Active state
    'active:bg-gray-100',
    'active:ring-gray-600/25',
    'active:shadow-sm-longer',
    // Pressed state (for popovers etc)
    'data-[state=active]:bg-gray-100',
    'data-[state=active]:ring-gray-600/40',
    'data-[state=active]:hover:shadow-sm-longer',
    'data-[state=active]:hover:translate-y-0',
    'data-[state=active]:hover:bg-gray-200/75',
    'data-[state=active]:hover:ring-gray-600/50',
    'data-[state=open]:bg-gray-100',
    'data-[state=open]:ring-gray-600/40',
    'data-[state=open]:hover:shadow-sm-longer',
    'data-[state=open]:hover:translate-y-0',
    'data-[state=open]:hover:bg-gray-200/75',
    'data-[state=open]:hover:ring-gray-600/50',
];
const ghostButtonStyles = [
    'bg-transparent',
    'text-gray-900',
    // Hover state
    'hover:bg-gray-400/25',
    'hover:ring-gray-400/25',
    'active:bg-gray-400/50',
    'active:ring-transparent',
    // Focus state
    'focus-visible:ring-gray-600',
    // Pressed state – this is used for popover triggers, dropdown menu
    // triggers, as well as combobox list items
    'data-[state=active]:bg-gray-400/25',
    'data-[state=active]:ring-gray-400/25',
    'data-[state=active]:hover:bg-gray-400/40',
    'data-[state=active]:hover:ring-gray-400/40',
    'data-[state=open]:bg-gray-400/25',
    'data-[state=open]:ring-gray-400/25',
    'data-[state=open]:hover:bg-gray-400/40',
    'data-[state=open]:hover:ring-gray-400/40',
    'aria-[selected=true]:bg-gray-400/25',
    'aria-[selected=true]:ring-gray-400/25',
    'aria-[selected=true]:hover:bg-gray-400/40',
    'aria-[selected=true]:hover:ring-gray-400/40',
];
const button = tv({
    base: baseButtonStyles,
    variants: {
        variant: {
            base: [],
            primary: [
                'bg-gray-900',
                'text-gray-50',
                'ring-gray-900',
                // Hover state
                'hover:shadow-md',
                'hover:bg-gray-950',
                'hover:ring-gray-950',
                'hover:-translate-y-px',
                // Active state
                'active:shadow-none',
            ],
            secondary: secondaryButtonStyles,
            ghost: ghostButtonStyles,
            danger: [
                'bg-red-600',
                'text-white',
                'ring-red-600',
                // Hover state
                'hover:shadow-md',
                'hover:bg-red-700',
                'hover:ring-red-700',
                'hover:-translate-y-px',
                // Active state
                'active:shadow-none',
            ],
            legacyBlue: [
                'bg-legacyBlue',
                'text-gray-50',
                'ring-legacyBlue',
                // Hover state
                'hover:shadow-md',
                'hover:bg-legacyBlueHover',
                'hover:ring-legacyBlueHover',
                'hover:-translate-y-px',
                // Active state
                'active:shadow-none',
            ],
            amber: [
                'bg-amber-200',
                'text-amber-900',
                'ring-amber-600/40',
                'shadow-sm-longer',
                // Hover state
                'hover:bg-amber-300/75',
                'hover:shadow-md',
                'hover:ring-amber-600/50',
                'hover:-translate-y-px',
                // Focus state
                'focus-visible:ring-amber-600',
                // Active state
                'active:bg-amber-100',
                'active:ring-amber-600/25',
                'active:shadow-sm-longer',
            ],
        },
        size: {
            // Potentially rename to 'base'?
            default: [],
            sm: [
                'h-7',
                'leading-7',
                'pl-3',
                'pr-3',
                'gap-1.5',
                'text-xs',
                'rounded-md',
                // Tailwind doesn't work with dynamically generated classes so we'll
                // have to hardcode the class name here. It is defined in ./ChildrenWithIcon
                '[&>.inner-icon>svg]:h-3.5',
                '[&>.inner-icon>svg]:w-3.5',
            ],
            // TODO: After the redesign, we should deprecate 'base' and rename
            // `intermediate` to `base`
            intermediate: [
                'h-8',
                'pl-4',
                'pr-4',
                'gap-2',
                // TODO: The text-13 class breaks the button styles for some reason, to investigate
                'text-[13px]/8',
                'rounded-md',
                // Tailwind doesn't work with dynamically generated classes so we'll
                // have to hardcode the class name here. It is defined in ./ChildrenWithIcon
                '[&>.inner-icon>svg]:h-4',
                '[&>.inner-icon>svg]:w-4',
            ],
            base: [
                'h-9',
                'leading-9',
                'pl-4',
                'pr-4',
                'gap-2',
                'text-sm',
                'rounded-md',
                // Tailwind doesn't work with dynamically generated classes so we'll
                // have to hardcode the class name here. It is defined in ./ChildrenWithIcon
                '[&>.inner-icon>svg]:h-4',
                '[&>.inner-icon>svg]:w-4',
            ],
        },
        width: {
            full: ['w-full'],
        },
        leadingIcon: {
            true: [],
            false: [],
        },
        trailingIcon: {
            true: [],
            false: [],
        },
        standaloneIcon: {
            true: ['pl-0', 'pr-0'],
            false: [],
        },
        // Make the transform transition optional. We need this to be able to combine
        // our buttons with framer-motion components
        transform: {
            true: ['transition-combined'],
            false: ['transition-noTransform'],
        },
    },
    compoundVariants: [
        // Standalone icon
        {
            className: 'w-7',
            size: 'sm',
            standaloneIcon: true,
        },
        {
            className: 'w-8',
            size: 'intermediate',
            standaloneIcon: true,
        },
        {
            className: 'w-9',
            size: 'base',
            standaloneIcon: true,
        },
        // Leading icon
        {
            className: 'pl-2.5',
            size: 'sm',
            leadingIcon: true,
        },
        {
            className: 'pl-3.5',
            size: 'intermediate',
            leadingIcon: true,
        },
        {
            className: 'pl-3.5',
            size: 'base',
            leadingIcon: true,
        },
        // Trailing icon
        {
            className: 'pr-2.5',
            size: 'sm',
            trailingIcon: true,
        },
        {
            className: 'pr-3.5',
            size: 'intermediate',
            trailingIcon: true,
        },
        {
            className: 'pr-3.5',
            size: 'base',
            trailingIcon: true,
        },
        // Both trailing & leading icons (make sure the trailing icon is on the right)
        {
            trailingIcon: true,
            leadingIcon: true,
            className: '[&>.inner-icon:last-child]:ml-auto',
        },
    ],
    defaultVariants: {
        size: 'base',
        transform: true,
        variant: 'secondary',
    },
});
/**
  All purpose button component
  */
const BtwButton = forwardRef(function BtwButton(props, ref) {
    const { asChild, children, className, disabled, isLoading, leadingIcon, size, standaloneIcon, testId, trailingIcon, transform, type = 'button', variant, width } = props, rest = __rest(props, ["asChild", "children", "className", "disabled", "isLoading", "leadingIcon", "size", "standaloneIcon", "testId", "trailingIcon", "transform", "type", "variant", "width"]);
    const Comp = asChild ? Slot : 'button';
    return (_jsxs(Comp, Object.assign({ className: button({
            className,
            size,
            transform,
            variant,
            width,
            standaloneIcon: !!standaloneIcon,
            leadingIcon: (!!leadingIcon || isLoading) && !standaloneIcon,
            trailingIcon: !!trailingIcon && !isLoading && !standaloneIcon,
        }), "data-testid": testId, disabled: disabled || isLoading, ref: ref, type: type }, rest, { children: [isLoading ? (_jsx(ChildrenWithIcon.Loader, {})) : (_jsx(_Fragment, { children: (standaloneIcon || leadingIcon) && (_jsx(ChildrenWithIcon.Icon, { children: standaloneIcon || leadingIcon })) })), _jsx(Slottable, { children: children }), !isLoading && !standaloneIcon && trailingIcon && (_jsx(ChildrenWithIcon.Icon, { children: trailingIcon }))] })));
});
export { BtwButton };
export { baseButtonStyles, secondaryButtonStyles, ghostButtonStyles };
