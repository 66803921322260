import { jsx as _jsx } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import theme from '@marvelapp/ballpark-theme';
import styled, { css } from '@marvelapp/styled';
export default function ModalOverlay({ onClick, kind = 'black', disablePointer = false, }) {
    const animation = {
        animate: {
            opacity: kind === 'black' ? 0.65 : 1,
        },
        exit: {
            opacity: 0,
        },
        initial: {
            opacity: 0,
        },
        transition: {
            ease: theme.easings.smooth.array,
            duration: 0.6,
        },
    };
    return (_jsx(Overlay, { animate: animation.animate, disablePointer: disablePointer, exit: animation.exit, initial: animation.initial, kind: kind, onClick: onClick, transition: animation.transition }, "overlay"));
}
const Overlay = styled(motion.div) `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: ${(props) => (props.disablePointer ? 'none' : 'all')};
  z-index: ${(props) => props.theme.zIndices.modal};

  ${(props) => props.kind === 'black' &&
    css `
      background-color: ${props.theme.colors.licorice};
    `};

  ${(props) => props.kind === 'white' &&
    css `
      background-color: ${props.theme.colors.white};
    `};
`;
