import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridFindIcon } from '@marvelapp/ballpark-icons';
import { FigmaIntegrationIcon, MarvelIntegrationIcon, SlackIntegrationIcon, } from '@marvelapp/ballpark-icons/src/integrationIcons';
import { BtwButton } from '../BtwButton';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { CtaMessage } from '../CtaMessage';
import { AppSidebar, SidebarNavItem, SidebarPanel } from '../Sidebar';
import { Stack } from '../Stack';
import { SettingsHeader } from './SettingsHeader';
export var ConnectedApps;
(function (ConnectedApps) {
    ConnectedApps["figma"] = "Figma";
    ConnectedApps["marvel"] = "Marvel";
    ConnectedApps["slack"] = "Slack";
})(ConnectedApps || (ConnectedApps = {}));
function ConnectedAppsSettingsPage(props) {
    const { connectedApps = [ConnectedApps.figma] } = props;
    return (_jsxs(Stack, { width: "full", className: "h-full", direction: "row", children: [_jsx(AppSidebar, { initialMenuItem: SidebarNavItem.Settings_ConnectedApps, initialActivePanel: SidebarPanel.Settings }), _jsxs(Stack, { width: "full", className: "h-full overflow-y-scroll", children: [_jsxs(SettingsHeader.Container, { children: [_jsx(SettingsHeader.Title, { children: "Connected apps" }), _jsx(SettingsHeader.Actions, { children: _jsx(BtwButton, { size: "sm", children: "Request an integration" }) })] }), _jsx(Stack, { width: "full", gap: "8", className: "mx-auto h-full max-w-6xl flex-1 overflow-y-scroll", children: connectedApps.length > 0 ? (_jsx(Stack, { width: "full", className: "p-8", gap: "4", direction: { initial: 'col', xl: 'row' }, wrap: "wrap", align: "stretch", children: connectedApps.map((app) => {
                                const connectedApp = ConnectedAppsList.find((item) => item.name === app);
                                if (connectedApp) {
                                    return (_jsxs(Stack, { direction: "row", align: "center", gap: "5", className: "ease-smooth relative w-full rounded-xl p-6 ring-1 ring-gray-600/10 transition-all duration-1000 only:w-full hover:bg-gray-50 xl:w-[calc(50%_-_8px)]", children: [_jsx(Stack, { className: "rounded-xl p-2 ring-1 ring-inset ring-gray-600/10", style: {
                                                    backgroundColor: getBackgroundColor(connectedApp.name),
                                                }, children: connectedApp.icon }), _jsxs(Stack, { width: "full", gap: "1", children: [_jsx(BtwHeading, { size: "sm", children: connectedApp.name }), _jsx(BtwText, { size: "13", children: connectedApp.description })] }), _jsx(Stack, { children: _jsx(BtwButton, { variant: "danger", size: "intermediate", children: "Disconnect" }) })] }));
                                }
                                return null;
                            }) })) : (_jsx(Stack, { width: "full", align: "center", justify: "center", className: "h-full", children: _jsx(CtaMessage.Root, { size: "sm", children: _jsxs(CtaMessage.Header, { icon: _jsx(GridFindIcon, {}), children: [_jsx(CtaMessage.Heading, { children: "No connected apps\u2026 yet!" }), _jsx(CtaMessage.Description, { children: "You have not added any integrations!" })] }) }) })) })] })] }));
}
const ConnectedAppsList = [
    {
        name: ConnectedApps.figma,
        description: 'Embed prototypes or single screens in your research projects',
        icon: _jsx(FigmaIntegrationIcon, {}),
    },
    {
        name: ConnectedApps.marvel,
        description: 'Embed your Marvel prototypes in your research projects',
        icon: _jsx(MarvelIntegrationIcon, {}),
    },
    {
        name: ConnectedApps.slack,
        description: 'Notify a channel whenever a new response is received in your Ballpark project',
        icon: _jsx(SlackIntegrationIcon, {}),
    },
];
function getBackgroundColor(app) {
    switch (app) {
        case ConnectedApps.figma:
            return '#1C1C1C';
        case ConnectedApps.marvel:
            return '#1FB6FF';
        default:
            return '#ffffff';
    }
}
export { ConnectedAppsSettingsPage };
