import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useVirtualizer } from '@tanstack/react-virtual';
import { useCallback } from 'react';
import * as React from 'react';
import { DataTable } from './DataTable';
export function VirtualizedDataTable(_a) {
    var { table, tableContainerRef, overscan = 10, estimateSize, getItemKey } = _a, otherProps = __rest(_a, ["table", "tableContainerRef", "overscan", "estimateSize", "getItemKey"]);
    const { rows } = table.getRowModel();
    const virtualizer = useVirtualizer({
        count: rows.length,
        getScrollElement: useCallback(() => tableContainerRef.current, []),
        estimateSize: useCallback(() => estimateSize, [estimateSize]),
        getItemKey,
        overscan,
        // debug: process.env.NODE_ENV === 'development',
    });
    const virtualRows = virtualizer.getVirtualItems();
    const [paddingTop, paddingBottom] = virtualRows.length > 0
        ? [
            (virtualRows[0].start || 0) - virtualizer.options.scrollMargin,
            virtualizer.getTotalSize() -
                (virtualRows[virtualRows.length - 1].end || 0),
        ]
        : [0, 0];
    const getRows = () => virtualRows.map((virtualRow) => rows[virtualRow.index]);
    const renderRowAttrs = (row) => {
        const virtualRow = virtualRows.find((virtualRow) => virtualRow.index === row.index);
        if (!virtualRow)
            return {};
        return {
            'data-index': virtualRow.index,
            key: virtualRow.index,
            ref: virtualizer.measureElement,
        };
    };
    return (_jsx(_Fragment, { children: _jsx(DataTable, Object.assign({ table: table, getRows: getRows, paddingTop: paddingTop, paddingBottom: paddingBottom, renderRowAttrs: renderRowAttrs, adjustColumnWidths: true }, otherProps)) }));
}
