import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CategorySlug } from '../types';
export const landingPagePreferenceTest = {
    slug: 'landing-page-preference-test',
    title: 'Landing Page Preference Test Template | Ballpark',
    metaDescription: 'See which landing page your users prefer with this landing page preference test template. Gather feedback and identify improvements.',
    heading: 'Landing page preference test template',
    subheading: 'Determine which page design would lead to better results',
    categories: [CategorySlug.BrandAndCopyTesting, CategorySlug.Marketing],
    body: _jsx(LandingPagePreferenceTestBody, {}),
    templateUUID: 'ut_966c5ccf-0449-4098-bd92-b8e4a61beec5',
    recordLink: 'https://app.ballparkhq.com/record/966c5ccf-0449-4098-bd92-b8e4a61beec5',
    accordionTitle: 'Landing page preference test template FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'What type of data can I gather in a landing page preference test?',
            answerParagraphs: [
                'You’ll be able to see the percentage preference between the designs as well as feedback from open-ended follow-up questions.',
            ],
        },
        {
            id: 2,
            question: 'What other marketing testing can I do?',
            answerParagraphs: [
                'You can test other campaign assets such as paid search ads, copy, taglines and social.',
            ],
        },
    ],
};
function LandingPagePreferenceTestBody() {
    return (_jsxs("div", { children: [_jsx("p", { children: "A landing page is used within a marketing campaign to help promote a new product or feature and drive an action whether that be a sign-up or registering a user's details." }), _jsx("p", { children: "Gathering feedback with our landing page preference test template helps to determine which design a user is more likely to click on and therefore." }), _jsx("h3", { children: "Example questions you can ask in a landing page preference test:" }), _jsxs("ul", { children: [_jsx("li", { children: "Which design would you be more likely to sign up?" }), _jsx("li", { children: "Which design would make you want to learn more?" }), _jsx("li", { children: "What would have to change to make you want to buy this product from this page?" })] })] }));
}
