import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';
import { tv } from 'tailwind-variants';
const textBaseVariants = tv({
    base: [],
    variants: {
        align: {
            left: ['text-left'],
            center: ['text-center'],
            right: ['text-right'],
        },
        size: {
            xxs: ['text-xxs', 'tracking-normal'],
            xs: ['text-xs', 'tracking-normal'],
            '13': ['text-13', 'tracking-normal'],
            sm: ['text-sm', 'tracking-normal'],
            '15': ['text-15', 'tracking-normal'],
            base: ['text-base', 'tracking-normal'],
            lg: ['text-lg', 'tracking-normal'],
            xl: ['text-xl', '-tracking-[0.01em]'],
            '2xl': ['text-2xl', '-tracking-[0.01em]'],
            '3xl': ['text-3xl', '-tracking-[0.02em]'],
            '4xl': ['text-4xl', '-tracking-[0.02em]'],
            '5xl': ['text-5xl', '-tracking-[0.02em]'],
            '6xl': ['text-6xl', '-tracking-[0.02em]'],
            '7xl': ['text-7xl', '-tracking-[0.02em]'],
            '8xl': ['text-8xl', '-tracking-[0.03em]'],
            '9xl': ['text-9xl', '-tracking-[0.03em]'],
        },
        leading: {
            none: ['leading-none'],
            tight: ['leading-tight'],
            snug: ['leading-snug'],
            normal: ['leading-normal'],
            relaxed: ['leading-relaxed'],
            loose: ['leading-loose'],
        },
        weight: {
            normal: ['font-normal'],
            medium: ['font-medium'],
            semibold: ['font-semibold'],
            bold: ['font-bold'],
            black: ['font-black'],
        },
        truncate: {
            true: ['truncate'],
            false: [],
        },
        font: {
            body: ['font-body'],
            legacy: ['font-legacy'],
            mono: ['font-mono'],
        },
    },
}, {
    responsiveVariants: true,
    twMerge: false, // https://github.com/nextui-org/tailwind-variants/issues/18
});
const text = tv({
    extend: textBaseVariants,
    base: [],
    variants: {
        variant: {
            current: ['text-current'],
            green: ['text-green-600'],
            inherit: ['text-inherit'],
            primary: ['text-gray-900'],
            purple: ['text-purple-700'],
            red: ['text-red-600'],
            secondary: ['text-gray-600'],
            white: ['text-white'],
            blue: ['text-blue-700'],
            yellow: ['text-yellow-600'],
            disabled: ['text-gray-400'],
        },
    },
    defaultVariants: {
        font: 'body',
        variant: 'secondary',
    },
}, {
    responsiveVariants: true,
    twMerge: false, // https://github.com/nextui-org/tailwind-variants/issues/18
});
/**
  To be used for all text elements
  */
function BtwText(props) {
    const { align, asChild, className, font, leading, size, truncate, variant, weight } = props, rest = __rest(props, ["align", "asChild", "className", "font", "leading", "size", "truncate", "variant", "weight"]);
    const Comp = asChild ? Slot : 'div';
    return (_jsx(Comp, Object.assign({ className: text({
            align,
            className,
            font,
            leading,
            size,
            truncate,
            variant,
            weight,
        }) }, rest)));
}
export { textBaseVariants };
export { BtwText };
