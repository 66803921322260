import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { motion } from 'framer-motion';
import { isObject } from 'lodash-es';
import * as React from 'react';
import theme from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { Link } from './Link';
const overlayAnimation = {
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
    initial: {
        opacity: 0,
    },
    transition: {
        ease: theme.easings.smooth.array,
        duration: 0.35,
    },
};
const modalAnimation = {
    animate: {
        y: 0,
    },
    exit: {
        y: '20%',
    },
    initial: {
        y: '20%',
    },
    transition: {
        ease: theme.easings.smooth.array,
        duration: 0.35,
    },
};
export function ModalOverlay({ children, shouldReduceMotion, }) {
    const animation = !shouldReduceMotion ? overlayAnimation : {};
    return (_createElement(Overlay, Object.assign({ "data-testid": "overlay" }, animation, { key: "guard-overlay" }), children));
}
const Overlay = styled(motion(Box)).attrs({
    bg: 'blackA8',
    zIndex: 'modal',
}) `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;
export function AnimatedModal({ children, shouldReduceMotion, testId, }) {
    const animation = !shouldReduceMotion ? modalAnimation : {};
    return (_jsx(Modal, Object.assign({ "data-testid": testId }, animation, { children: children })));
}
const Modal = styled(motion(Box)).attrs({
    bg: 'background',
    borderRadius: 'xl',
    boxShadow: 'popover.default',
    padding: ['m', 'm', 'l', 'xl'],
}) `
  max-width: 370px;
  min-width: 290px;
  text-align: center;
`;
export function ModalLink(_a) {
    var { to: origTo } = _a, rest = __rest(_a, ["to"]);
    let to;
    if (!origTo) {
        // Seems there are a bunch of places where 'to' is undefined until some data
        // or a feature flag is loaded. Should probably fix them ...
        to = origTo;
    }
    else if (isObject(origTo)) {
        to = Object.assign(Object.assign({}, origTo), { state: Object.assign(Object.assign({}, origTo.state), { modal: true }) });
    }
    else {
        const hashIndex = origTo.indexOf('#');
        const searchIndex = origTo.indexOf('?');
        if (hashIndex !== -1 || searchIndex !== -1) {
            throw new Error("To use ModalLink with hash or search params use the object form of the 'to' argument");
        }
        to = {
            pathname: origTo,
            state: { modal: true },
        };
    }
    return _jsx(Link, Object.assign({}, rest, { to: to }));
}
