import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useResponsiveValue } from '@theme-ui/match-media';
import { range } from 'lodash-es';
import * as React from 'react';
import { Box, ContentLoader, Flex, Grid, Text, Tooltip } from '@marvelapp/ui';
import { ModalLink } from '../Modal';
import { Player } from './Player';
export function CollaboratorsView({ activeCollaborators, isLoading, }) {
    const MAX_LENGTH = useResponsiveValue([5, 5, 5, 5, 3, 3, 5]);
    const moreThanMax = activeCollaborators.length > MAX_LENGTH;
    const remainingCollaborators = moreThanMax
        ? activeCollaborators.length - MAX_LENGTH
        : 0;
    if (isLoading) {
        return (_jsx(Flex, { "data-testid": "collaborators-loading", marginRight: "10px", children: range(MAX_LENGTH).map((loader, index) => (_jsx(Box, { mr: "-6px", position: "relative", zIndex: range(4).length - index, children: _jsx(ContentLoader, { border: "3px solid", borderColor: "white", borderRadius: 18, height: 34, kind: "medium", width: 34 }, loader) }, loader))) }));
    }
    return (_jsx(ModalLink, { display: ['none', 'block'], mr: 2, to: { search: '?share=1' }, "data-testid": "open-share-modal", children: _jsxs(Grid, { alignItems: "center", gridAutoFlow: "column", gridColumnGap: 1, justifyContent: "center", children: [activeCollaborators
                    .slice(0, MAX_LENGTH)
                    .map(({ avatarUrl, name, id, color }, index) => (_jsx(Box, { mr: "-10px", position: "relative", zIndex: MAX_LENGTH - index + 1, children: _jsx(Player, { activeColor: color, avatarUrl: avatarUrl, avatarSize: 34, name: name }, id) }, id))), moreThanMax && (_jsx(Box, { mr: "-6px", position: "relative", zIndex: 0, children: _jsx(Tooltip, { content: `And ${remainingCollaborators} more`, cursor: "pointer", position: "bottom", children: _jsx(MoreCollaborators, { num: remainingCollaborators }) }) }))] }) }));
}
export function MoreCollaborators({ num, }) {
    return (_jsx(Box, { alignItems: "center", bg: "smoke", borderRadius: "50%", "data-testid": "extra-collaborators", display: "flex", height: 34, justifyContent: "center", position: "relative", pr: "2px", width: 34, children: _jsxs(Text, { color: "slate", fontSize: 34 / 3, fontWeight: 600, opacity: 0.7, children: ["+", num] }) }));
}
