import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { get, merge } from 'lodash-es';
import { useContext, useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { __HistoryContext as HistoryContext, __RouterContext as RouterContext, } from 'react-router';
import { ToastContainer, cssTransition, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseTheme from '@marvelapp/ballpark-theme';
import { ThemeProvider } from '@marvelapp/styled';
import { GlobalTransitionStyles, ToastCloseButton, ToastCloseWrap, ToastContainerElement, } from './styles';
const SlideIn = cssTransition({
    enter: 'slideIn',
    exit: 'slideOut',
});
const getTheme = (mode) => merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
    shadows: get(baseTheme.shadows.modes, mode, baseTheme.shadows),
});
// This component needs to be rendered once in your application,
// so add it to the root of any app that needs to support toast notifications
// Api reference can be found here: https://github.com/fkhadra/react-toastify#api
function StyledToastContainer(props) {
    return (_jsxs("div", { "data-testid": "toast-container", children: [_jsx(GlobalTransitionStyles, {}), _jsx(ToastContainerElement, { children: _jsx(ToastContainer, Object.assign({}, props)) })] }));
}
StyledToastContainer.defaultProps = {
    closeButton: (_jsx(ToastCloseWrap, { children: _jsx(ToastCloseButton, {}) })),
    hideProgressBar: true,
    position: 'bottom-left',
    transition: SlideIn,
};
export { showErrorNotification, showGenericServerError, showLoadingNotification, showProgressNotification, showSuccessNotification, showWarningNotification, } from './notifications';
export { toast };
// Need to use React 17 so the replacing the current toast rapidly works as
// expected with, eg, undo/redo
const MountedToastContainer = (props) => {
    const root = useRef(null);
    const client = useApolloClient();
    const router = useContext(RouterContext);
    const history = useContext(HistoryContext);
    const theme = getTheme('light');
    // Unmount when this gets unmounted
    useLayoutEffect(() => {
        const container = root.current;
        return () => {
            if (container)
                ReactDOM.unmountComponentAtNode(container);
        };
    }, []);
    useLayoutEffect(() => {
        const container = root.current;
        if (!container)
            return;
        ReactDOM.render(
        // Some Toasts poll the server for updates, so we need to wrap the container in an ApolloProvider
        // Likewise some use <Link> and require the RouterContext and HistoryContext to be re-exposed
        _jsx(RouterContext.Provider, { value: router, children: _jsx(HistoryContext.Provider, { value: history, children: _jsx(ApolloProvider, { client: client, children: _jsx(ThemeProvider, { theme: theme, children: _jsx(StyledToastContainer, Object.assign({}, props)) }) }) }) }), container);
    }, [root, props, client, router, history, theme]);
    return _jsx("div", { ref: root });
};
export default MountedToastContainer;
