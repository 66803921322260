import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CategorySlug } from '../types';
export const onboardingFlowTest = {
    slug: 'onboarding-flow-test',
    title: 'Onboarding Flow Test Template | Ballpark ',
    metaDescription: 'Improve your product usage with this onboarding flow template. See how your users sign up and engage with functionality so you can identify optimisations.',
    heading: 'Onboarding flow test template',
    subheading: 'Understand how you can improve your customers first-time use experience',
    categories: [CategorySlug.PrototypeTesting, CategorySlug.DesignAndProduct],
    body: _jsx(OnboardingFlowTestBody, {}),
    templateUUID: 'ut_fd8c1722-bfa1-4563-afeb-45236d1f9138',
    recordLink: 'https://app.ballparkhq.com/record/fd8c1722-bfa1-4563-afeb-45236d1f9138',
    accordionTitle: 'Onboarding flow template FAQ',
    accordionItems: [
        {
            id: 1,
            question: 'Can I add multiple onboarding flows to the same test?',
            answerParagraphs: [
                'Yes! Ballpark supports adding multiple prototypes so you can test dozens of variations of your onboarding flow within the same test.',
            ],
        },
        {
            id: 2,
            question: 'What are some different things I can test for onboarding?',
            answerParagraphs: [
                'The most common is to test the performance of showcasing new features in the onboarding flow vs showing a video.',
                'Highlighting the features allows you to show exactly what to click on in the product but may become overwhelming if your product is complicated. A video may be more useful for users who prefer to learn in their own time, but may not convert as well straight after sign-up.',
            ],
        },
    ],
};
function OnboardingFlowTestBody() {
    return (_jsxs("div", { children: [_jsx("p", { children: "Product onboarding is the process of introducing your features, app or website to users after they sign-up." }), _jsxs("p", { children: ["A great onboarding experience allows a user to quickly understand how to use your core features and hopefully, become activated and retained.", ' '] }), _jsx("p", { children: "Often the best time to do this is within seconds of that user signing up to your product as that is when you have their maximum (yet limited) attention." }), _jsx("p", { children: "Get it right and they could be your next power user!" }), _jsx("p", { children: "This template gives you an example of how you can use a Figma prototype embedded into Ballpark to test your onboarding flow before going into production. You can ask questions along the way and get feedback on the participants\u2019 experiences." }), _jsx("h3", { children: "5 great ways to use our onboarding flow template" }), _jsxs("ul", { children: [_jsx("li", { children: "Refine and optimise your onboarding flow before going live" }), _jsx("li", { children: "Discover whether the design of your onboarding flow is likely to convert users by tracking tasks and goal completion" }), _jsx("li", { children: "Track duration to complete the goal of signing up and create a benchmark" }), _jsx("li", { children: "Follow-up with targeted survey questions about their experience signing up" }), _jsx("li", { children: "Test your competitors' website sign-up flows and discover their strengths and weaknesses" })] })] }));
}
