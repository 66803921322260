import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as React from 'react';
import { getImage, getImage2x, getThumbnail, getThumbnail2x, } from '@marvelapp/core';
import { BtwButton } from './BtwButton';
import { FullscreenModal } from './FullscreenModal';
import { Image as ImagePrimitive } from './Image';
import { cn } from './utils';
const METRIC_IMAGE_WIDTH = 24;
function CardSortThumbBase(props) {
    const { title, imageSrc, type } = props, rest = __rest(props, ["title", "imageSrc", "type"]);
    return (_jsx(FullscreenModal, Object.assign({ trigger: _jsx(Thumb, Object.assign({}, props)) }, rest, { children: _jsx(Image, Object.assign({}, props)) })));
}
function Image(props) {
    const { imageSrc, title, type } = props, rest = __rest(props, ["imageSrc", "title", "type"]);
    return (_jsx(FullscreenModal.Image, Object.assign({ alt: title || `${type} image`, "data-testid": `${type}-image`, height: "contain", src: getImage(imageSrc), srcSet: `${getImage2x(imageSrc)} 2x` }, rest)));
}
const Thumb = forwardRef(function Thumb(props, ref) {
    const { imageSrc, title, type } = props, rest = __rest(props, ["imageSrc", "title", "type"]);
    return (_jsxs(BtwButton, Object.assign({ className: "relative size-6 rounded", size: "default", variant: "base", "data-testid": `${type}-image-button`, ref: ref }, rest, { children: [_jsx(ImagePrimitive, { alt: title || `${type} image`, className: "block size-6 rounded object-cover", "data-testid": `${type}-image`, src: getThumbnail(imageSrc, METRIC_IMAGE_WIDTH), srcSet: `${getThumbnail2x(imageSrc, METRIC_IMAGE_WIDTH)} 2x` }), _jsx("div", { className: cn('absolute', 'inset-0', 'rounded', 'border', 'border-gray-600/10', 'pointer-events-none') })] })));
});
export const CardSortThumb = Object.assign(CardSortThumbBase, {
    Image,
    Thumb,
});
