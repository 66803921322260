import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as React from 'react';
import { CloseIcon, ImageIcon, RefreshIcon, ZoomIcon, } from '@marvelapp/ballpark-icons';
import { AspectImage } from './AspectImage';
import { BtwButton } from './BtwButton';
import { BtwText } from './BtwText';
import { BtwTooltip } from './BtwTooltip';
import { FullscreenModal } from './FullscreenModal';
import { Loader } from './Loader';
import { Stack } from './Stack';
import { cn } from './utils';
const EmptyState = forwardRef(function EmptyState(props, ref) {
    const { className, disabled, draggedOver, isLoading, onClick } = props, rest = __rest(props, ["className", "disabled", "draggedOver", "isLoading", "onClick"]);
    return (_jsx(Stack, Object.assign({ className: cn(
        // Base styles
        'aspect-video', 'rounded', 'border', 'border-dashed', 'select-none', 
        // Transition
        'ease-smooth', 'duration-300', 'cursor-pointer', 'transition-combined', 'will-change-transform', {
            'opacity-50': disabled,
            'cursor-not-allowed': disabled,
            // Hover and active states
            'hover:bg-gray-50': !disabled,
            'hover:text-gray-900': !disabled,
            'hover:border-gray-600/40': !disabled,
            'active:bg-gray-100': !disabled,
            'active:translate-y-px': !disabled,
            'border-blue-600/20': draggedOver,
            'border-gray-600/20': !draggedOver,
        }, className), align: "center", gap: "2", justify: "center", onClick: disabled ? undefined : onClick, ref: ref, width: "full" }, rest, { children: isLoading ? (_jsx(Loader, {})) : (_jsxs(Stack, { align: "center", className: "min-w-0 px-1", direction: "row", gap: "1", justify: "center", width: "full", children: [_jsx(ImageIcon, { className: "size-4 [&>path]:stroke-1" }), _jsx(BtwText, { size: "xs", truncate: true, variant: "current", children: "Add/drop image (optional)" })] })) })));
});
function Image(props) {
    const { alt, imageSrc, imageSrcSet, onRemove, onReplace, thumbnail, thumbnailSet, 'data-testid': testId, } = props;
    return (_jsxs("div", { className: cn('relative', 'w-full', 'rounded', 'group/image', 'aspect-video'), "data-testid": "card-image-container", children: [_jsx(AspectImage, { "data-testid": testId, alt: alt, aspect: "video", size: "full", src: thumbnail, srcSet: thumbnailSet }), _jsxs(Overlay, { children: [_jsx(FullscreenModal, { trigger: _jsx(BtwButton
                        // TODO: remove as part of BALL-1861
                        , { 
                            // TODO: remove as part of BALL-1861
                            onPointerDown: (e) => e.preventDefault(), "data-testid": "zoom-card-image", leadingIcon: _jsx(ZoomIcon, {}), size: "sm", variant: "secondary", children: "View" }), children: _jsx(FullscreenModal.Image, { alt: alt, height: "contain", src: imageSrc, srcSet: imageSrcSet }) }), onReplace && (_jsx(BtwButton, { "data-testid": "replace-card-image", leadingIcon: _jsx(RefreshIcon, {}), onClick: onReplace, size: "sm", variant: "secondary", children: "Replace" })), onRemove && (_jsx(BtwTooltip, { content: "Remove image", children: _jsx(BtwButton, { onClick: onRemove, size: "sm", standaloneIcon: _jsx(CloseIcon, {}), variant: "secondary", "data-testid": "remove-card-image" }) }))] })] }));
}
const Overlay = forwardRef(function Overlay(props, ref) {
    const { className, children } = props, rest = __rest(props, ["className", "children"]);
    return (_jsx("div", Object.assign({ className: cn('rounded', 'inset-px', 'absolute', 'bg-white/80', 'opacity-0', 'group-hover/image:opacity-100', 'ease-smooth', 'duration-300', 'transition-combined', className), ref: ref }, rest, { children: _jsx(Stack, { align: "center", direction: "row", gap: "1.5", height: "full", justify: "center", width: "full", wrap: "wrap", children: children }) })));
});
export const AddImageCta = {
    EmptyState,
    Image,
};
