import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 *
 * Loader
 *
 */
import PropTypes from 'prop-types';
import Position from '../Position';
import { Spinny, Static } from './styles';
function Loader({ size, color, borderWidth, testId, speed = 500 }) {
    return (_jsxs(Position, { "data-testid": testId, display: "block", position: "relative", children: [_jsx(Static, { borderColor: color, borderWidth: borderWidth, height: size, width: size }), _jsx(Spinny, { borderColor: color, borderWidth: borderWidth, height: size, width: size, speed: speed })] }));
}
Loader.propTypes = {
    borderWidth: PropTypes.any,
    color: PropTypes.any,
    size: PropTypes.any,
    testId: PropTypes.string,
};
Loader.defaultProps = {
    borderWidth: '4px',
    color: 'silver',
    size: 24,
};
export default Loader;
