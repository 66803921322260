import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { Stack } from '../Stack';
import { cn } from '../utils';
const OrderSummary = function OrderSummaryModal({ asChild, children, }) {
    return (_jsx(Stack, { asChild: asChild, className: "h-full divide-y divide-gray-600/10 sm:divide-x sm:divide-y-0", direction: { initial: 'col', sm: 'row' }, width: "full", children: children }));
};
const CreditSummary = function CreditSummary({ children, showSeparator, }) {
    return (_jsx(Stack, { className: cn('flex-1 sm:h-full sm:max-w-[340px]', {
            'divide-y divide-gray-600/10': showSeparator,
        }), justify: "between", width: "full", children: children }));
};
const OrderSummaryModal = {
    Root: OrderSummary,
    CreditSummary,
};
export { OrderSummaryModal };
