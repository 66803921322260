import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as React from 'react';
import { cn } from './utils';
const Root = forwardRef(function Root(props, ref) {
    const { className, minWidth } = props, rest = __rest(props, ["className", "minWidth"]);
    return (_jsx("div", { style: { minWidth }, children: _jsx("table", Object.assign({ className: cn('w-full', 'table-fixed', 'overflow-hidden', className), ref: ref }, rest)) }));
});
const Thead = forwardRef(function Thead(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return _jsx("thead", Object.assign({ className: cn(className), ref: ref }, rest));
});
const Tbody = forwardRef(function Tbody(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return _jsx("tbody", Object.assign({ className: cn(className), ref: ref }, rest));
});
const Tfoot = forwardRef(function Tfoot(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return _jsx("tfoot", Object.assign({ className: cn(className), ref: ref }, rest));
});
const Tr = forwardRef(function Tr(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return _jsx("tr", Object.assign({ className: cn(className), ref: ref }, rest));
});
const Th = forwardRef(function Th(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return _jsx("th", Object.assign({ className: cn(className), ref: ref }, rest));
});
const Td = forwardRef(function Td(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return _jsx("td", Object.assign({ className: cn(className), ref: ref }, rest));
});
const Caption = forwardRef(function Caption(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return _jsx("caption", Object.assign({ className: cn(className), ref: ref }, rest));
});
export const Table = Object.assign(Root, {
    Caption,
    Tbody,
    Td,
    Tfoot,
    Th,
    Thead,
    Tr,
});
