import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as React from 'react';
import { PlusIcon } from '@marvelapp/ballpark-icons';
import { BtwAvatar } from './BtwAvatar';
import { BtwButton } from './BtwButton';
import { Separator as SeparatorPrimitive } from './Separator';
import { Stack } from './Stack';
import { cn } from './utils';
const HEADER_HEIGHT = 52;
/**
 * Header root
 */
function Root(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (_jsx(Stack, Object.assign({ gap: "4", align: "center", className: cn('z-30', 'relative', 'w-full', 'px-2.5', 'bg-white', 'shadow-sm-bottom', className), direction: "row", justify: "between", width: "full", style: { height: HEADER_HEIGHT } }, rest)));
}
/**
 * Left
 */
function Left(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (_jsx(Stack, Object.assign({ align: "center", className: cn('min-w-0', 'flex-1', className), direction: "row", gap: "3" }, rest)));
}
/**
 * Right
 */
function Right(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (_jsx(Stack, Object.assign({ align: "center", className: cn('flex-1', className), direction: "row", gap: { initial: '0.5', lg: '4' }, justify: "end" }, rest)));
}
/**
 * Tabs
 */
function Tabs(props) {
    return _jsx(Stack, Object.assign({ gap: "1", direction: "row" }, props));
}
const Collaborators = forwardRef(function Collaborators(props, ref) {
    const { collaborators } = props, rest = __rest(props, ["collaborators"]);
    return (_jsxs(Stack, Object.assign({ align: "center", direction: "row", className: "pl-1 [&>span]:-ml-1 [&>span]:ring-2 [&>span]:ring-white", ref: ref }, rest, { children: [collaborators.map((collaborator) => {
                const { id, name, avatarUrl } = collaborator;
                return _jsx(BtwAvatar, { name: name, size: "lg", src: avatarUrl }, id);
            }), _jsx("div", { className: "relative -ml-1 rounded-full bg-white p-1", children: _jsx(BtwButton, { className: "rounded-full", size: "sm", standaloneIcon: _jsx(PlusIcon, {}) }) })] })));
});
/**
 * Actions
 */
function Actions(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (_jsx(Stack, Object.assign({ className: cn('pl-1', className), direction: "row", gap: "2" }, rest)));
}
/**
 * Separator
 */
function Separator(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (_jsx(SeparatorPrimitive, Object.assign({ direction: "vertical", className: cn('h-6', 'max-lg:hidden', className) }, rest)));
}
/**
 * Responsive button that turns into standalone icon on mobile
 */
function ResponsiveButton(props) {
    const { className, children, icon } = props, rest = __rest(props, ["className", "children", "icon"]);
    return (_jsxs(_Fragment, { children: [_jsx(BtwButton, Object.assign({}, rest, { className: cn('lg:hidden', className), size: "sm", standaloneIcon: icon })), _jsx(BtwButton, Object.assign({}, rest, { className: cn('max-lg:hidden', className), size: "sm", leadingIcon: icon, children: children }))] }));
}
const ProjectHeader = {
    Actions,
    Collaborators,
    Left,
    ResponsiveButton,
    Right,
    Root,
    Separator,
    Tabs,
};
export { ProjectHeader, HEADER_HEIGHT };
