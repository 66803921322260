import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { withApollo } from '@apollo/client/react/hoc';
import { Suspense, useEffect, useMemo, useRef } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { LastLocationProvider, useLastLocation, } from 'react-router-last-location';
import compose from 'recompose/compose';
import { EnsureBrowserSupported } from '@marvelapp/askhub-ui';
import { FloatingUpsell } from '@marvelapp/askhub-upsells';
import { FeatureFlagsContext, Marvel3Provider, PublicRoute, } from '@marvelapp/ballpark-application';
import { NotFound, UnhandledError } from '@marvelapp/ballpark-components';
import { ModalPortalDestination } from '@marvelapp/ui';
import { BackLinkProvider, CenteredLoader, ModalSwitch, ToastContainer, } from '@marvelapp/ui-internal';
import { routingMap } from '../../routingMap';
import { dangerouslySetupGoogleTranslateObserver } from '../../utils/googleTranslateObserver';
import logout from '../../utils/logout';
import { ProjectArchived, ProjectLocked } from '../PrototypeUnavailable';
import { Sidebar } from '../Sidebar';
dangerouslySetupGoogleTranslateObserver();
// Record page provides is its own localized browser check
const browserCheckIgnorePaths = ['/record'];
function App({ client: apolloClient, accessToken, user, config, pusher, flags, subscription, setSubscription, transactionalKey, }) {
    const lastLocation = useLastLocation();
    const sidebar = useMemo(() => _jsx(Sidebar, { user: user }), [user]);
    const modalContainerRef = useRef();
    return (_jsx(EnsureBrowserSupported, { ignoredPaths: browserCheckIgnorePaths, children: _jsx(Suspense, { fallback: _jsx(CenteredLoader, {}), children: _jsx(FeatureFlagsContext.Provider, { value: flags, children: _jsxs(Marvel3Provider, { accessToken: accessToken, apolloClient: apolloClient, config: config, featureFlags: flags, lastLocation: lastLocation, logout: logout, pusher: pusher, setSubscription: setSubscription, sidebar: sidebar, subscription: subscription, transactionalKey: transactionalKey, user: user, children: [_jsx(LastLocationProvider, { children: _jsx(PublicRoute, { children: (routeProps) => (_jsx(BackLinkProvider, { children: _jsxs(ModalSwitch, Object.assign({}, routeProps, { containerRef: modalContainerRef, children: [_jsx(Redirect, { exact: true, from: "/", to: "/projects" }), _jsx(PublicRoute, { component: UnhandledError, exact: true, path: "/error" }), _jsx(PublicRoute, { component: ProjectLocked, exact: true, path: "/prototype-locked" }), _jsx(PublicRoute, { component: ProjectArchived, exact: true, path: "/prototype-archived" }), routingMap.map((route) => {
                                                const { path, Application, flag } = route;
                                                if (flag && !flags[flag])
                                                    return null;
                                                return (_jsx(PublicRoute, { path: path, render: ({ history }) => {
                                                        return (_jsx(Application
                                                        // TODO refactor all the apps to get these values from
                                                        // the useXXX hooks
                                                        , { 
                                                            // TODO refactor all the apps to get these values from
                                                            // the useXXX hooks
                                                            accessToken: accessToken, apolloClient: apolloClient, config: config, featureFlags: flags, history: history, lastLocation: lastLocation, pusher: pusher, setSubscription: setSubscription, sidebar: sidebar, subscription: subscription, transactionalKey: transactionalKey, user: user }));
                                                    } }, path));
                                            }), _jsx(PublicRoute, { component: NotFound })] })) })) }) }), _jsx("div", { ref: modalContainerRef }), _jsx(ToastContainer, { icon: () => null }), _jsx(ModalPortalDestination, {}), user && subscription && _jsx(FloatingUpsell, {}), _jsx(IntercomVisibility, {})] }) }) }) }));
}
function IntercomVisibility() {
    const { pathname } = useLocation();
    useEffect(() => {
        if (window.Intercom) {
            if (!['/projects', '/archive', '/company', '/templates'].some((path) => pathname.startsWith(path))) {
                window.Intercom('hide');
            }
        }
    }, [pathname]);
    return null;
}
export default compose(withApollo)(App);
