import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CategorySlug } from '../types';
export const productUpdateAndChangelog = {
    slug: 'product-update-and-changelog',
    title: 'Product Update Template | Ballpark',
    metaDescription: 'Determine the best way to keep customers updated on product changes and new features with this product update and changelog template.',
    heading: 'Product update and changelog template',
    subheading: 'A simple way to keep your users in the loop with product changes',
    categories: [CategorySlug.VideoGuides, CategorySlug.CustomerSuccess],
    body: _jsx(ProductUpdateAndChangelogBody, {}),
    templateUUID: 'ut_406d0472-7da2-4034-bfd6-770155197de5',
    recordLink: 'https://app.ballparkhq.com/record/406d0472-7da2-4034-bfd6-770155197de5',
    accordionTitle: 'Product update/changelog template FAQ',
    accordionItems: [
        {
            id: 1,
            question: 'What types of content can I include in this template?',
            answerParagraphs: [
                'You can add JPGs, PNGs and GIFs to most steps in the template, as well as Figma and Marvel prototypes. You also have the option to link from your instructions to any content on other sites including videos and PDFs.',
            ],
        },
        {
            id: 2,
            question: '',
            answerParagraphs: [
                'Every project you create in Ballpark automatically comes with a unique URL to share with as many people as you like. Simply click the share button in your project and paste the URL provided into your communication.',
            ],
        },
    ],
};
function ProductUpdateAndChangelogBody() {
    return (_jsxs("div", { children: [_jsxs("p", { children: ["Keep your users up-to-date with new product releases by sending our interactive product update template, enabling you to give a tour of new features in your changelog.", ' '] }), _jsx("p", { children: "Our video guides feature allows you to add a pre-recorded video to each new feature to explain how it works and when to use it. The videos can sit alongside product images, prototypes, questions and tasks." }), _jsxs("p", { children: ["You can also add interactive prototypes to the template so that users can try the feature without signing in.", ' '] }), _jsx("p", { children: "Open questions, multiple-choice and rating steps allow you to ask questions and gather feedback on features or suggestions for the roadmap." }), _jsx("h3", { children: "4 great ways to use the product update template" }), _jsxs("ul", { children: [_jsx("li", { children: "Keep users (or potential users) a chance to try out new features using Figma or Marvel prototypes without needing to sign in" }), _jsx("li", { children: "Give guided tours of new product features using video guides " }), _jsx("li", { children: "Gather feedback on your updates and what users would like to see next" }), _jsx("li", { children: "Collect email addresses to build lists of people interested in trying beta releases" })] })] }));
}
