import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { Input } from './Input';
import { Label, LabelContainer } from './Label';
import { Text } from './Text';
export const InputWithLabel = forwardRef((props, ref) => {
    const { error, id, inputSize = 'm', isOptional, kind, label, labelSize = 's', labelWidth, lineHeight, onChange, placeholder, subheading, value } = props, rest = __rest(props, ["error", "id", "inputSize", "isOptional", "kind", "label", "labelSize", "labelWidth", "lineHeight", "onChange", "placeholder", "subheading", "value"]);
    return (_jsxs(_Fragment, { children: [_jsxs(LabelContainer, { marginBottom: 12, children: [_jsxs(Box, { display: "flex", flexDirection: "column", children: [_jsx(Label, { display: "inline-block", htmlFor: id, isOptional: isOptional, size: labelSize, width: labelWidth, children: label }), subheading && (_jsx(Text, { color: "slate12", mt: "xs", size: "s", children: subheading }))] }), error && (_jsx(Error, { "data-testid": "input-error", fontSize: labelSize, children: error }))] }), _jsx(Input, Object.assign({ id: id, kind: kind, lineHeight: lineHeight, onChange: onChange, placeholder: placeholder, ref: ref, size: inputSize, value: value, width: 1 }, rest))] }));
});
const Error = styled(Box).attrs({
    lineHeight: 'base',
    color: 'red11',
}) ``;
