import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';
import { tv } from 'tailwind-variants';
const contentPrimitive = tv({
    base: [
        'rounded-md',
        'bg-gray-900/90',
        'px-2.5',
        'py-1.5',
        'text-xs',
        'font-medium',
        'text-gray-100',
        'data-[state=delayed-open]:motion-safe:animate-in',
        'data-[state=delayed-open]:motion-safe:fade-in',
        'data-[state=delayed-open]:motion-safe:duration-300',
        'data-[state=delayed-open]:motion-safe:ease-smooth',
        'data-[state=delayed-open]:motion-safe:slide-in-from-bottom-2',
        'data-[state=closed]:motion-safe:animate-out',
        'data-[state=closed]:motion-safe:fade-out',
        'data-[state=closed]:motion-safe:duration-200',
        'data-[state=closed]:motion-safe:ease-smooth',
        'data-[state=closed]:motion-safe:slide-out-to-bottom-2',
        'relative',
        'z-[1010]', // TODO: {tailwind} Sort this out when we move away from z-indexes in the dashboard
    ],
    variants: {},
    defaultVariants: {},
});
function Content(props) {
    const { className, sideOffset = 8 } = props, rest = __rest(props, ["className", "sideOffset"]);
    return (_jsx(TooltipPrimitive.Content, Object.assign({ className: contentPrimitive({ className }), sideOffset: sideOffset }, rest)));
}
function BtwTooltipBase(props) {
    const { 'data-testid': dataTestId = 'tooltip-content', asChild = true, children, className, content, disableHoverableContent, side, sideOffset = 8, } = props;
    if (!content) {
        return _jsx(_Fragment, { children: children });
    }
    return (_jsxs(TooltipPrimitive.Root, { disableHoverableContent: disableHoverableContent, children: [_jsx(TooltipPrimitive.Trigger, { asChild: asChild, children: children }), _jsx(TooltipPrimitive.Portal, { children: _jsx(TooltipPrimitive.Content, { className: contentPrimitive({ className }), "data-testid": dataTestId, side: side, sideOffset: sideOffset, children: content }) })] }));
}
const { Root } = TooltipPrimitive;
Root.defaultProps = {
    delayDuration: 0,
};
const BtwTooltip = Object.assign(BtwTooltipBase, {
    Provider: TooltipPrimitive.Provider,
    Root,
    Trigger: TooltipPrimitive.Trigger,
    Portal: TooltipPrimitive.Portal,
    Content,
});
export { BtwTooltip };
