import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import theme, { PLUS_ICON } from '@marvelapp/ballpark-theme';
import { Box, Flex, Tooltip } from '@marvelapp/ui';
import { Button, kinds as buttonKinds, sizes as buttonSizes, } from './Buttons';
import { Icon } from './Icon';
import { MinusIcon } from './Minus';
export function Stepper({ children, decrementDisabledReason, incrementDisabledReason, isDecrementDisabled, isIncrementDisabled, kind = 'ghost', onDecrement, onIncrement, size = 'm', stepperTestId, tooltipWhiteSpace, tooltipWidth, width = '100%', }) {
    const buttonProps = {
        px: 2,
        display: 'flex',
        alignItems: 'center',
        translateOnHover: false,
        kind,
        size,
    };
    const borderRadius = theme.radii[buttonSizes[size].borderRadius];
    return (_jsxs(Flex, { "data-testid": stepperTestId, width: width, children: [_jsx(StepperButton, { disabledReason: decrementDisabledReason, tooltipWhiteSpace: tooltipWhiteSpace, tooltipWidth: tooltipWidth, children: _jsx(Button, Object.assign({ "data-testid": "decrease-button", isDisabled: isDecrementDisabled, onClick: onDecrement }, buttonProps, { px: 0, display: "flex", justifyContent: "center", borderRadius: `${borderRadius}px 0 0 ${borderRadius}px`, children: _jsx(MinusIcon, { height: "90%" }) })) }), _jsx(Box, Object.assign({}, buttonSizes[size], buttonKinds[kind], { borderRadius: "0px", flex: 1, fontWeight: "medium", hoverBg: buttonKinds[kind].bg, hoverColor: buttonKinds[kind].color, hoverBoxShadow: buttonKinds[kind].boxShadow, marginLeft: "-1px", marginRight: "-1px", textAlign: "center", children: _jsx("div", { "data-testid": "member-info", children: children }) })), _jsx(StepperButton, { disabledReason: incrementDisabledReason, tooltipWhiteSpace: tooltipWhiteSpace, tooltipWidth: tooltipWidth, children: _jsx(Button, Object.assign({ "data-testid": "increase-button", isDisabled: isIncrementDisabled, onClick: onIncrement, justifyContent: "center" }, buttonProps, { px: 0, display: "flex", borderRadius: `0 ${borderRadius}px ${borderRadius}px 0`, children: _jsx(Icon, { paths: PLUS_ICON }) })) })] }));
}
function StepperButton({ children, disabledReason, tooltipWhiteSpace, tooltipWidth, }) {
    if (disabledReason) {
        return (_jsx(Tooltip, { content: disabledReason, cursor: "default", whiteSpace: tooltipWhiteSpace, width: tooltipWidth, children: children }));
    }
    return _jsx(_Fragment, { children: children });
}
