import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as React from 'react';
import { CheckIcon, SettingsIcon } from '@marvelapp/ballpark-icons';
import { BtwButton } from '../BtwButton';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { Stack } from '../Stack';
import { cn } from '../utils';
function Root(props) {
    return (_jsx(Stack, { gap: "4", className: "max-w-sm", width: "full", children: props.children }));
}
function Box(props) {
    const { access, description, disabled, media, onEnableAccess, settingsPopover, soundTest, title, } = props;
    return (_jsxs(Stack, { gap: "3", width: "full", className: cn('rounded-lg', 'p-4', {
            'bg-gray-200': disabled,
            // Access enabled styles
            'ring-1': !disabled,
            'bg-white': !disabled,
            'ring-gray-600/20': !disabled,
            'shadow-sm-longer': !disabled,
        }), children: [_jsxs(Stack, { width: "full", direction: "row", align: "center", gap: "1", children: [_jsxs(Stack, { gap: "1", width: "full", children: [_jsxs(BtwHeading, { className: "flex items-center gap-1", variant: disabled ? 'secondary' : 'primary', size: "sm", children: [title, " ", access === 'enabled' && _jsx(CheckIcon, { className: "h-4 w-4" })] }), _jsx(BtwText, { size: "xs", children: description })] }), access === 'enabled' && (_jsx("div", { className: "relative", children: settingsPopover }))] }), access === 'enabled' ? (_jsx(_Fragment, { children: (media || soundTest) && (_jsxs(Stack, { gap: "4", width: "full", children: [media, soundTest] })) })) : (_jsx(BtwButton, { size: "intermediate", variant: "primary", width: "full", disabled: disabled, onClick: onEnableAccess, className: cn({ '!bg-gray-300': disabled }), children: "Enable access" }))] }));
}
function Media(props) {
    return (_jsx("div", { className: cn('aspect-video', 'overflow-hidden', 'rounded-md', 
        // Image
        '[&>img]:block', '[&>img]:h-full', '[&>img]:w-full', '[&>img]:object-cover', 
        // Video
        '[&>video]:block', '[&>video]:h-full', '[&>video]:w-full', '[&>video]:object-cover'), children: props.children }));
}
const SettingsButton = forwardRef(function SettingsButton(props, ref) {
    return (_jsx(BtwButton, Object.assign({ size: "sm", standaloneIcon: _jsx(SettingsIcon, {}), ref: ref }, props)));
});
export const PermissionsWidgets = {
    Box,
    Media,
    Root,
    SettingsButton,
};
