import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo } from 'react';
import * as React from 'react';
import { createPermissions } from './createPermissions';
const PermissionsContext = createContext(null);
export const PermissionsProvider = ({ user, subscription, children, flags }) => {
    const permissions = useMemo(() => createPermissions(user, subscription, flags), [user, subscription, flags]);
    return (_jsx(PermissionsContext.Provider, { value: permissions, children: children }));
};
export function usePermissions() {
    return useContext(PermissionsContext);
}
