import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CategorySlug } from '../types';
export const productMarketFitSurvey = {
    slug: 'product-market-fit-survey',
    title: 'Product Market Fit (PMF) Survey Template | Ballpark',
    metaDescription: 'A product market fit survey template will help you measure whether your product fits the demand of the market and to gain insights on how to improve.',
    heading: 'Product-Market Fit (PMF) survey template',
    subheading: 'Get insights into the market demand of your product',
    categories: [
        CategorySlug.Popular,
        CategorySlug.ProductFeedback,
        CategorySlug.DesignAndProduct,
    ],
    body: _jsx(ProductMarketFitBody, {}),
    templateUUID: 'ut_4ab64d54-2a22-4330-a166-b885abb92f54',
    recordLink: 'https://app.ballparkhq.com/record/4ab64d54-2a22-4330-a166-b885abb92f54',
    accordionTitle: 'Product-market fit survey FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'Who should be invited to participate in a product-market fit survey?',
            answerParagraphs: [
                'Generally, the ideal prerequisites for a product-market fit survey are 40-50 participants that:',
                _jsx(ProductMarketFitAccordionList, {}),
            ],
        },
        {
            id: 2,
            question: 'What if the product-market fit score is lower than 40%?',
            answerParagraphs: [
                'Sean Ellis, creator of the product-market fit test, suggests that businesses that score below 40% struggle to gain traction. If the product-market fit score is below 40%, it’s best to avoid thinking about scaling that product and instead start listening to customers, and find out what would need to change in order to increase demand for the product.',
            ],
        },
    ],
};
function ProductMarketFitBody() {
    return (_jsxs("div", { children: [_jsx("p", { children: "A product-market fit survey should ask customers how disappointed they\u2019d be if they could no longer use your product. The respondent should choose only one answer from the following list of 4 possible answers:" }), _jsxs("ul", { children: [_jsx("li", { children: "Very disappointed" }), _jsx("li", { children: "Somewhat disappointed" }), _jsx("li", { children: "Not disappointed" }), _jsx("li", { children: "I no longer use [product]" })] }), _jsx("p", { children: "Once all of the respondents have answered, the survey results should then be quantified as the total percentage of respondents who chose \u201Cvery disappointed\u201D. If this percentage score is 40% or above then the product has likely reached \u2018product-market fit\u2019, making it easier for it to gain traction if the product was scaled." }), _jsx("p", { children: "Additional (more qualitative) questions can be asked in order to find out why the customer chose their answer." }), _jsx("h3", { children: "3 great ways to use our product-market fit survey template" }), _jsx("p", { children: "Our product-market fit survey template provides an ultra-simple framework for assessing product-market fit, plus additional context and insight into next steps." }), _jsxs("ul", { children: [_jsx("li", { children: "Discover your product\u2019s product-market fit score quickly" }), _jsx("li", { children: "Find out if it\u2019s the right time to scale" }), _jsx("li", { children: "Find out what can be changed (if anything) to raise the score" })] })] }));
}
function ProductMarketFitAccordionList() {
    return (_jsxs("ul", { children: [_jsx("li", { children: "Have experienced the core product" }), _jsx("li", { children: "Have used the product as least twice" }), _jsx("li", { children: "Have used the product within the last two weeks" })] }));
}
