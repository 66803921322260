import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { addMonths, format, getYear, setMonth, setYear, startOfMonth, } from 'date-fns';
import { useCallback, useMemo, useState, } from 'react';
import * as React from 'react';
import { DayPicker, useNavigation, } from 'react-day-picker';
import { CalendarIcon, ChevronBottomSmallIcon, ChevronLeftIcon, ChevronRightIcon, } from '@marvelapp/ballpark-icons';
import { BtwButton } from './BtwButton';
import { BtwInput } from './BtwInput';
import { BtwPopover } from './BtwPopover';
import { BtwText } from './BtwText';
import { Stack } from './Stack';
import { cn } from './utils';
const classNames = {
    day_outside: 'text-gray-400',
    day_selected: 'bg-gray-900 text-white hover:bg-gray-900 font-medium',
    day_today: 'font-bold',
    day: 'size-8 hover:bg-gray-100 rounded-md',
    head_cell: 'size-8 font-medium text-center',
    root: 'p-5 font-body text-13',
    table: 'w-full',
    tbody: 'text-gray-900 font-medium',
};
function DatePickerSelect(props) {
    const { currentValue, children, onChange, testId } = props;
    return (_jsxs("div", { className: cn('relative', 'overflow-hidden', 'ring-1', 'ring-transparent', 'rounded-md', 'has-[:focus-visible]:outline-none', 'has-[:focus-visible]:outline-gray-300', 'has-[:focus-visible]:ring-gray-600'), children: [_jsx("select", { className: "absolute left-0 top-0 z-10 opacity-0", onChange: onChange, "data-testid": testId, children: children }), _jsxs(Stack, { direction: "row", gap: "0.5", align: "center", className: "h-7 px-1.5 py-0.5", children: [_jsx(BtwText, { size: "13", weight: "semibold", variant: "primary", className: "z-0", "data-testid": "date-display-text", children: currentValue }), _jsx(ChevronBottomSmallIcon, { className: "size-4" })] })] }));
}
function CustomCaption(props) {
    const { goToMonth, nextMonth, previousMonth } = useNavigation();
    const { displayMonth, displayIndex } = props;
    const months = useMemo(() => {
        const now = new Date();
        return Array.from({ length: 12 }, (_, month) => setMonth(now, month));
    }, []);
    const years = useMemo(() => {
        // According to Kas, the oldest person alive was born in 1904
        const startYear = 1904;
        const currentYear = getYear(new Date());
        // Creating an array from 1904 to a hundred years from now
        return Array.from({ length: currentYear + 100 - startYear }, (_, year) => setYear(startYear, startYear + year));
    }, []);
    const handleMonthChange = useCallback((event) => {
        const selectedMonth = Number(event.target.value);
        const newMonth = setMonth(startOfMonth(displayMonth), selectedMonth);
        goToMonth(addMonths(newMonth, displayIndex ? -displayIndex : 0));
    }, [goToMonth, displayMonth, displayIndex]);
    const handleYearChange = useCallback((event) => {
        const selectedYear = Number(event.target.value);
        const newMonth = setYear(startOfMonth(displayMonth), selectedYear);
        goToMonth(addMonths(newMonth, displayIndex ? -displayIndex : 0));
    }, [goToMonth, displayMonth, displayIndex]);
    return (_jsxs(Stack, { direction: "row", align: "center", justify: "between", className: "mb-4", children: [_jsx(BtwButton, { standaloneIcon: _jsx(ChevronLeftIcon, {}), size: "sm", disabled: !previousMonth, onClick: () => previousMonth && goToMonth(previousMonth) }), _jsxs(Stack, { direction: "row", gap: "0.5", children: [_jsx(DatePickerSelect, { currentValue: format(displayMonth, 'MMMM'), onChange: handleMonthChange, testId: "date-picker-month", children: months.map((month) => (_jsx("option", { value: month.getMonth(), selected: month.getMonth() === displayMonth.getMonth(), children: format(month, 'MMMM') }, month.getMonth()))) }), _jsx(DatePickerSelect, { currentValue: format(displayMonth, 'yyy'), onChange: handleYearChange, testId: "date-picker-year", children: years.map((year) => (_jsx("option", { value: year.getFullYear(), selected: year.getFullYear() === displayMonth.getFullYear(), children: format(year, 'yyy') }, year.getFullYear()))) })] }), _jsx(BtwButton, { standaloneIcon: _jsx(ChevronRightIcon, {}), size: "sm", disabled: !nextMonth, onClick: () => nextMonth && goToMonth(nextMonth) })] }));
}
function DatePicker(props) {
    const { onSelect, trigger, value, size } = props;
    const date = value ? new Date(value) : undefined;
    const [monthInView, setMonthInView] = useState(date);
    return (_jsxs(BtwPopover.Root, { onOpenChange: (isOpen) => {
            // on open, set the selected month
            if (isOpen)
                setMonthInView(date);
        }, children: [_jsx(BtwPopover.Trigger, { className: "w-full", children: trigger || (_jsx(BtwInput.Root, { leadingIcon: _jsx(CalendarIcon, {}), width: "full", size: size, children: _jsx(BtwInput.Field, { id: "date", placeholder: "Pick a date", readOnly: true, value: date && format(date, 'PPP') }) })) }), _jsx(BtwPopover.Content, { align: "center", "data-testid": "date-picker-popover", children: _jsx(DayPicker, { classNames: classNames, components: { Caption: CustomCaption }, mode: "single", month: monthInView, onMonthChange: setMonthInView, onSelect: onSelect, selected: date, showOutsideDays: true, weekStartsOn: 1 }) })] }));
}
export { DatePicker };
