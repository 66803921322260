import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { tv } from 'tailwind-variants';
import { CheckIcon, EnterIcon, LeaveIcon } from '@marvelapp/ballpark-icons';
import { BtwHeading } from '../BtwHeading';
import { Stack } from '../Stack';
const box = tv({
    base: [
        'pl-10',
        'pr-16',
        'py-10',
        'ring-1',
        'bg-white',
        'rounded-full',
        'shadow-sm-longer',
        'ring-gray-600/20',
    ],
    variants: {
        variant: {
            startScreen: ['text-gray-900'],
            droppedOff: ['text-red-800'],
            droppedOffFreeRoam: ['text-gray-900'],
            goalReached: ['text-green-800'],
        },
    },
    defaultVariants: {
        variant: 'startScreen',
    },
});
const iconWrapper = tv({
    base: [
        'flex',
        'h-20',
        'w-20',
        'items-center',
        'justify-center',
        'rounded-full',
        'flex-shrink-0',
    ],
    variants: {
        variant: {
            startScreen: ['bg-gray-200/75'],
            droppedOff: ['bg-red-200'],
            droppedOffFreeRoam: ['bg-gray-200/75'],
            goalReached: ['bg-green-200'],
        },
    },
    defaultVariants: {
        variant: 'startScreen',
    },
});
function StartEndBox(props) {
    const { variant = 'startScreen' } = props;
    return (_jsx(Stack, { "data-testid": variant, children: _jsx(Stack, { justify: "center", align: "center", direction: "row", asChild: true, gap: "6", children: _jsxs("div", { className: box({ variant }), children: [_jsx("div", { className: iconWrapper({ variant }), children: _jsx(Icon, { variant: variant, className: "h-10 w-10 [&>path]:stroke-[4px]" }) }), _jsx(BtwHeading, { truncate: true, variant: "current", size: "3xl", children: getText({ variant }) })] }) }) }));
}
function Icon(props) {
    const { variant } = props, rest = __rest(props, ["variant"]);
    switch (variant) {
        case 'startScreen':
            return _jsx(EnterIcon, Object.assign({}, rest));
        case 'droppedOff':
        case 'droppedOffFreeRoam':
            return _jsx(LeaveIcon, Object.assign({}, rest));
        case 'goalReached':
            return _jsx(CheckIcon, Object.assign({}, rest));
        default:
            return null;
    }
}
const getText = (args) => {
    switch (args.variant) {
        case 'startScreen':
            return 'Test started';
        case 'droppedOff':
        case 'droppedOffFreeRoam':
            return 'Dropped off';
        case 'goalReached':
            return 'Goal reached';
        default:
            return '';
    }
};
export { StartEndBox };
