import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { forwardRef } from 'react';
import * as React from 'react';
import { checkToggleVariants } from './CheckToggle';
const Item = forwardRef(function Item(props, ref) {
    const { className, variant, size } = props, rest = __rest(props, ["className", "variant", "size"]);
    return (_jsx(RadioGroupPrimitive.Item, Object.assign({ className: checkToggleVariants({ className, variant, size }), ref: ref }, rest)));
});
const RadioToggle = {
    Root: RadioGroupPrimitive.Root,
    Item,
    Indicator: RadioGroupPrimitive.Indicator,
};
export { RadioToggle };
