import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip } from 'react-lightweight-tooltip';
import { positions } from './positions';
import { getTooltipStyle } from './styles';
/** @param {any} */
function StyledTooltip(_a) {
    var { arrowRight, children, content, cursor, display, fontSize, fontWeight, maxWidth, padding, pointerEvents, position, whiteSpace, width } = _a, props = __rest(_a, ["arrowRight", "children", "content", "cursor", "display", "fontSize", "fontWeight", "maxWidth", "padding", "pointerEvents", "position", "whiteSpace", "width"]);
    if (!content)
        return children;
    return (_jsx(Tooltip, Object.assign({ content: content, styles: getTooltipStyle({
            fontSize,
            arrowRight,
            cursor,
            display,
            fontWeight,
            maxWidth,
            padding,
            pointerEvents,
            position: positions[position],
            whiteSpace,
            width,
        }) }, props, { children: children })));
}
StyledTooltip.defaultProps = {
    position: 'top',
    cursor: 'pointer',
    display: 'inline-block',
};
export default StyledTooltip;
