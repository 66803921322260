import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { useCurrentUser, usePermissions, userSelectors, } from '@marvelapp/ballpark-application';
import { ProjectVisibility } from '@marvelapp/core';
import { Link } from '@marvelapp/ui';
import { Badge } from './Badge';
import { BtwSettingsItem } from './BtwSettingsItem';
import { Stack } from './Stack';
export function InviteOnlySetting({ onToggle, visibility, }) {
    const user = useCurrentUser();
    const permissions = usePermissions();
    const isLockedFreePlan = userSelectors.isLockedFreePlan(user);
    const isStarterPlan = userSelectors.isPro(user);
    const canUpgrade = isStarterPlan || isLockedFreePlan;
    const canEditVisibility = permissions.canEditVisibility();
    const disabledTooltipText = getInviteOnlyTooltip({
        isLockedFreePlan,
        isStarterPlan,
        canEditVisibility,
    });
    return (_jsx(BtwSettingsItem, { "data-testid": "invite-only-toggle", description: "Only visible to admins and collaborators added to this project", disabled: !!disabledTooltipText, disabledTooltipText: disabledTooltipText, heading: _jsx(SettingTitle, { showUpgradeBadge: canUpgrade, title: "Invite-only project" }), onPressedChange: onToggle, pressed: visibility === ProjectVisibility.Private, variant: "legacyBlue" }));
}
function SettingTitle({ title, showUpgradeBadge }) {
    const badge = showUpgradeBadge ? (_jsx(Link, { href: "/plans?intent=invite-only-projects", children: _jsx(Badge, { variant: "outline", size: "sm", children: "Upgrade" }) })) : null;
    return (_jsxs(Stack, { direction: "row", align: "center", gap: "2", children: [title, badge] }));
}
function getInviteOnlyTooltip({ isLockedFreePlan, isStarterPlan, canEditVisibility, }) {
    if (isLockedFreePlan) {
        return 'Invite only projects are available on paid plans. Upgrade to unlock.';
    }
    if (isStarterPlan) {
        return 'Available on Business and Enterprise plans';
    }
    if (!canEditVisibility) {
        return "You don't have permissions to create invite-only projects";
    }
    return '';
}
