import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { tv } from 'tailwind-variants';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { Stack } from '../Stack';
import { cn } from '../utils';
const root = tv({
    base: [],
    variants: {
        variant: {
            base: [],
            boxed: [
                'ring-1',
                'bg-white',
                'rounded-lg',
                'ring-gray-600/20',
                'shadow-sm-longer',
            ],
        },
    },
    defaultVariants: {
        variant: 'boxed',
    },
});
/**
 * Renders the root container for a summary stats
 * @param props StackProps containing width and children
 * @returns JSX element for the summary stats root container
 */
function Root(props) {
    const { className, variant } = props, rest = __rest(props, ["className", "variant"]);
    return (_jsx(Stack, Object.assign({ align: "stretch", direction: { initial: 'col', sm: 'row' }, width: "full", className: root({ className, variant }) }, rest)));
}
/**
 * Renders a single stat item for a summary stats
 * @param props StatProps containing value, icon, and label
 * @returns JSX element for a single stat item
 */
function Stat(props) {
    const { 'data-testid': testId, className, icon, label, postValue, title, value, } = props;
    return (_jsxs(Stack, { className: cn('py-4', 'px-6', 'flex-1', 'sm:border-r', 'max-sm:border-b', 'border-gray-200', 'last:border-none', className), gap: "0.5", "data-testid": testId, children: [_jsxs(Stack, { align: "start", className: "min-w-0", direction: "row", gap: "1", width: "full", children: [_jsx("div", { className: cn('[&>svg]:size-3', '[&>svg]:mt-[1px]', '[&>svg>path]:stroke-1', '[&>svg]:flex-shrink-0'), children: icon }), _jsx(BtwText, { align: "left", size: "xs", children: label })] }), _jsx(BtwHeading, { font: "mono", size: "2xl", title: title, weight: "bold", children: _jsxs(Stack, { align: "baseline", className: "h-8", "data-testid": "stat-count", direction: "row", gap: "2", children: [value, postValue && (_jsx(BtwText, { "data-testid": "stat-percentage", font: "mono", size: "13", weight: "normal", children: postValue }))] }) })] }));
}
export const SummaryStats = {
    Root,
    Stat,
};
