import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CategorySlug } from '../types';
export const userPersonaSurvey = {
    slug: 'user-persona-survey',
    title: 'User Persona Survey Template | Ballpark',
    metaDescription: 'Understand your who your users are, their needs and motivations with a user persona test template. ',
    heading: 'User persona survey template',
    subheading: 'Build pictures of the different types of people that use a product',
    categories: [CategorySlug.Popular],
    body: _jsx(UserPersonaSurveyBody, {}),
    templateUUID: 'ut_1bceb296-96f9-4128-aca5-4d1178b7604b',
    recordLink: 'https://app.ballparkhq.com/record/1bceb296-96f9-4128-aca5-4d1178b7604b',
    accordionTitle: 'User persona survey FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'What questions are asked during a user persona survey?',
            answerParagraphs: [
                'Besides some basic questions regarding the user’s age and level of education, a user persona survey also enquires about their job/job industry and company size before diving into open-ended questions about what their work day looks like, what success looks like at their company, the challenges they face, and finally the problem that they’re hoping to solve with our product.',
            ],
        },
        {
            id: 2,
            question: 'How are user persona survey results synthesised?',
            answerParagraphs: [
                'User persona surveys tend to reveal more than one type of user, which means that in order to properly document accurate user personas of each one, we must be able to identify patterns in the respondents answers. Affinity mapping (which in this case can help to group similar responses) is a great way to synthesize all of this data.',
            ],
        },
    ],
};
function UserPersonaSurveyBody() {
    return (_jsxs("div", { children: [_jsx("p", { children: "Building user personas of the different types of people that use a product can provide teams with invaluable insight into their users. This data, in combination with analytics and other types of data, can help teams to truly understand their users and what their needs and motivations are." }), _jsx("h3", { children: "3 great ways to use our user persona survey template" }), _jsxs("ul", { children: [_jsx("li", { children: "Learn about your users needs and motivations" }), _jsx("li", { children: "Segment and compare your different user types" }), _jsx("li", { children: "Acquire insight into the overarching industry" })] })] }));
}
