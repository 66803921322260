import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Tooltip from '@radix-ui/react-tooltip';
import * as React from 'react';
import { color, typography, variant } from 'styled-system';
import theme from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { boxStyles } from '@marvelapp/ui';
const { Root, Portal } = Tooltip;
Root.defaultProps = {
    delayDuration: 0,
};
const Trigger = styled(Tooltip.Trigger).attrs((props) => {
    var _a;
    return ({
        fontFamily: 0,
        width: (_a = props.width) !== null && _a !== void 0 ? _a : undefined,
        cursor: props.cursor || 'auto',
    });
}) `
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  outline: none;
  ${boxStyles};
  ${color}
  ${typography}
`;
const Content = styled(Tooltip.Content).attrs({
    borderRadius: 'm',
    fontFamily: 0,
    lineHeight: 'body',
    paddingX: 'xs',
    paddingY: 'xxs',
    zIndex: 'tooltip',
}) `
  ${boxStyles};
  ${typography};
  ${variant({
    prop: 'kind',
    variants: {
        dark: {
            bg: 'blackA10',
            color: 'whiteA12',
        },
        light: {
            bg: 'whiteA12',
            color: 'blackA10',
            border: '1px solid',
            borderColor: 'slate7',
            boxShadow: 'button.secondary',
        },
    },
})};
  ${variant({
    prop: 'size',
    variants: {
        s: {
            fontSize: 's',
        },
        xs: {
            fontSize: '11px',
        },
    },
})}
`;
Content.defaultProps = {
    size: 'xs',
    sideOffset: theme.space.xxs,
    kind: 'dark',
};
const Arrow = styled(Tooltip.Arrow).attrs({
    fill: 'blackA10',
}) `
  ${boxStyles};
`;
function Quick({ align, asChild = false, children, content, cursor, maxWidth, triggerHasFullWidth = false, side, sideOffset, }) {
    return (_jsxs(Root, { children: [_jsx(Trigger, { asChild: asChild, cursor: cursor, width: triggerHasFullWidth ? 1 : undefined, children: children }), _jsx(Portal, { children: _jsxs(Content, { align: align, maxWidth: maxWidth, side: side, sideOffset: sideOffset, children: [_jsx(Arrow, { offset: 8 }), content] }) })] }));
}
export { Arrow, Content, Portal, Quick, Root, Trigger };
