import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Command } from 'cmdk';
import { createContext, useContext, useState, } from 'react';
import * as React from 'react';
import { CheckIcon, ChevronBottomIcon, SearchIcon, } from '@marvelapp/ballpark-icons';
import { useLockBodyScroll } from '@marvelapp/hooks';
import { BtwButton } from './BtwButton';
import { BtwCheckbox } from './BtwCheckbox';
import { BtwInput } from './BtwInput';
import { BtwPopover } from './BtwPopover';
import { BtwSkeleton } from './BtwSkeleton';
import { BtwText } from './BtwText';
import { Pin } from './Pin';
import { Stack } from './Stack';
import { cn } from './utils';
export const ComboboxContext = createContext(undefined);
export const useComboboxState = () => {
    const comboboxState = useContext(ComboboxContext);
    if (comboboxState === undefined) {
        throw new Error('useComboboxState used outside of Provider');
    }
    return comboboxState;
};
/**
 * Root
 */
function Root(props) {
    const { children, variant } = props, rest = __rest(props, ["children", "variant"]);
    const [open, onOpenChange] = useState(false);
    return (_jsx(BtwPopover.Root, Object.assign({ open: open, onOpenChange: onOpenChange }, rest, { children: _jsx(ComboboxContext.Provider, { value: Object.assign({ open,
                onOpenChange }, (variant === 'multi-select' || variant === 'multi-select-with-cta'
                ? {
                    variant,
                    onClear: props.onClear,
                    selected: props.selected,
                    total: props.total,
                }
                : { variant })), children: children }) })));
}
function Trigger(props) {
    const { children, className, hasPlaceholder, truncate = true } = props, rest = __rest(props, ["children", "className", "hasPlaceholder", "truncate"]);
    return (_jsx(BtwPopover.Trigger, { asChild: true, children: _jsx(BtwButton, Object.assign({ className: cn('justify-between', '[&>:not(.inner-icon)]:w-full', '[&>:not(.inner-icon)]:text-left', {
                'font-normal text-gray-500': hasPlaceholder,
            }, className), trailingIcon: _jsx(ChevronBottomIcon, {}) }, rest, { children: _jsx("span", { className: cn({ 'min-w-0 truncate': truncate }), children: children }) })) }));
}
/**
 * Separator
 */
function Separator(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (_jsx(Command.Separator, Object.assign({ className: cn('h-px', 'w-full', 'bg-gray-200', className) }, rest)));
}
function Item(props) {
    const { check = 'icon', children, onSelect, selected, disabled, size, leadingIcon } = props, rest = __rest(props, ["check", "children", "onSelect", "selected", "disabled", "size", "leadingIcon"]);
    const { onOpenChange, variant } = useComboboxState();
    return (_jsx(BtwButton, { asChild: true, className: cn('text-[13px]', 'h-auto', 'justify-start', 'py-2', 'min-h-9', 'pl-3', 'pr-3', 'text-left', 'font-normal', '[&>.inner-icon]:ml-auto', '[&>.inner-icon]:flex-none', {
            'cursor-not-allowed': disabled,
        }), variant: "ghost", width: "full", trailingIcon: selected && check === 'icon' ? _jsx(CheckIcon, {}) : undefined, size: size, leadingIcon: leadingIcon, children: _jsxs(Command.Item, Object.assign({ "data-disabled": disabled, onSelect: (value) => {
                // Handle disabling the onSelect event manually because cmdk does not trigger the mouse exit event
                // when hovering from an item to a disabled item, leaving the previous item appearing as hovered.
                if (disabled) {
                    return;
                }
                if (onSelect) {
                    onSelect(value);
                }
                if (variant === 'single-select') {
                    onOpenChange(false);
                }
            } }, rest, { children: [check === 'checkbox' && (_jsx("div", { 
                    // We should potentially handle this spacing alignment on <Button /> component
                    // and make it per size, but it seems fine for now since we only have this button size
                    // that uses a checkbox inside
                    className: "-ml-0.5", children: _jsx(BtwCheckbox, { disabled: disabled, checked: selected, 
                        // The selection logic is handled by the parent <Command.Item /> component
                        size: "sm" }) })), _jsx("span", { className: cn('flex-1 truncate', {
                        'opacity-50': disabled,
                    }), children: children })] })) }));
}
/**
 * Content
 */
const CONTENT_HEADER_HEIGHT = 60;
const CONTENT_FOOTER_HEIGHT = 48;
const CONTENT_CTA_FOOTER_HEIGHT = 56;
function Content(props) {
    const { children, className, filter, modal = false, placeholder = 'Search for options…', width = 'trigger', listRef, headerInput = (_jsx(BtwInput.Field, { asChild: true, id: "search", children: _jsx(Command.Input, { placeholder: placeholder }) })), headerInputSize, onCloseAutoFocus, onFocusOutside, helpButton } = props, rest = __rest(props, ["children", "className", "filter", "modal", "placeholder", "width", "listRef", "headerInput", "headerInputSize", "onCloseAutoFocus", "onFocusOutside", "helpButton"]);
    const comboboxState = useComboboxState();
    return (_jsx(BtwPopover.Content, { className: className, "data-testid": "combobox-list-content", width: width, onCloseAutoFocus: onCloseAutoFocus, onFocusOutside: onFocusOutside, asChild: true, children: _jsxs(Command, Object.assign({ filter: filter }, rest, { children: [modal && _jsx(BodyLock, {}), comboboxState.variant !== 'popover' && (_jsxs("header", { className: "shadow-sm-bottom relative z-10 px-2.5", children: [_jsx(Stack, { justify: "center", style: { height: CONTENT_HEADER_HEIGHT }, children: _jsx(BtwInput.Root, { className: "w-full", leadingIcon: _jsx(SearchIcon, {}), size: headerInputSize, children: headerInput }) }), helpButton && (_jsx(Pin.Rc, { className: "pointer-events-none p-[18px]", children: helpButton }))] })), _jsx(Command.List, { "data-testid": "list-dropdown", className: cn('p-1.5', 'overflow-auto', '[&>div]:flex', '[&>div]:w-full', '[&>div]:flex-col', '[&>div]:gap-0.5'), style: {
                        // We only show the footer for multi-select comboboxes, so we need to
                        // take this into account for the overall max height of the popover
                        maxHeight: getComboboxContentHeight(comboboxState.variant),
                    }, ref: listRef, children: children }), (comboboxState.variant === 'multi-select' ||
                    comboboxState.variant === 'multi-select-with-cta') && (_jsx(Stack, { direction: "row", justify: "between", align: "center", asChild: true, children: _jsxs("div", { className: "shadow-sm-top pl-[18px] pr-2.5", style: { height: CONTENT_FOOTER_HEIGHT }, children: [_jsxs(BtwText, { className: "text-gray-500", "data-testid": "selected-options-count", size: "13", weight: "medium", children: [comboboxState.selected, " of ", comboboxState.total, " selected"] }), _jsx(BtwButton, { "data-testid": "clear-selection-combobox-content", disabled: comboboxState.selected === 0, onClick: comboboxState.onClear, size: "sm", variant: "ghost", children: "Clear selection" })] }) })), (comboboxState.variant === 'multi-select-with-cta' ||
                    comboboxState.variant === 'popover') && (_jsx("div", { className: "shadow-sm-top w-full bg-white p-3", style: { height: CONTENT_CTA_FOOTER_HEIGHT }, children: props.cta }))] })) }));
}
function LoadingState(props) {
    const { itemCount = 3, hasCheckboxes = false } = props;
    const elements = Array(itemCount).fill(null);
    return (_jsx(_Fragment, { children: elements.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        _jsx(Command.Loading, { children: _jsxs(Stack, { direction: "row", gap: "2", align: "center", className: cn('h-9', 'py-2', 'pl-2.5', 'pr-3', 'animate-pulse'), width: "full", children: [hasCheckboxes && _jsx(BtwSkeleton, { className: "size-[18px] rounded" }), _jsx(BtwSkeleton, { className: "h-3 rounded", style: {
                            // Making the width random between 200 and 80 px, which is visually a good range
                            width: Math.floor(Math.random() * (200 - 80)) + 80,
                        } })] }) }, index))) }));
}
function BodyLock() {
    useLockBodyScroll();
    return null;
}
const getComboboxContentHeight = (variant) => {
    switch (variant) {
        case 'single-select':
            return `calc(var(--radix-popover-content-available-height) - ${CONTENT_HEADER_HEIGHT}px)`;
        case 'multi-select':
            return `calc(var(--radix-popover-content-available-height) - ${CONTENT_HEADER_HEIGHT}px - ${CONTENT_FOOTER_HEIGHT}px)`;
        case 'multi-select-with-cta':
            return `calc(var(--radix-popover-content-available-height) - ${CONTENT_HEADER_HEIGHT}px - ${CONTENT_FOOTER_HEIGHT}px - ${CONTENT_CTA_FOOTER_HEIGHT}px)`;
        case 'popover':
        default:
            return 'var(--radix-popover-content-available-height)';
    }
};
/**
 * Empty state
 */
function Empty(props) {
    const { className, icon, children = _jsx(_Fragment, { children: "No matches found\u2026" }) } = props, rest = __rest(props, ["className", "icon", "children"]);
    return (_jsx(BtwText, { align: "center", asChild: true, className: cn('p-4', {
            'flex flex-col items-center justify-center gap-2': Boolean(icon),
        }, className), size: "sm", children: _jsx(Command.Empty, Object.assign({}, rest, { children: children })) }));
}
/**
 * Empty state
 */
function Group(props) {
    const { children, className } = props, rest = __rest(props, ["children", "className"]);
    return (_jsx(Command.Group, Object.assign({ className: cn(
        // Groups
        'flex', '[&[hidden]]:hidden', 'gap-0.5', 'flex-col', 'font-body', 'pt-2', '[&:not(:first-child)]:pt-4', '[&:not(:last-child)]:pb-2.5', '[&:not(:last-child)]:border-b', '[&:not(:last-child)]:border-gray-600/10', 
        // Group items
        '[&>_[cmdk-group-items]]:flex', '[&>_[cmdk-group-items]]:gap-0.5', '[&>_[cmdk-group-items]]:flex-col', 
        // Group headings
        '[&>_[cmdk-group-heading]]:font-body', '[&>_[cmdk-group-heading]]:font-bold', '[&>_[cmdk-group-heading]]:uppercase', '[&>_[cmdk-group-heading]]:px-3', '[&>_[cmdk-group-heading]]:pb-1', '[&>_[cmdk-group-heading]]:text-gray-500', '[&>_[cmdk-group-heading]]:text-xxs', className) }, rest, { children: children })));
}
const Combobox = {
    Content,
    Empty,
    Group,
    Item,
    Root,
    Separator,
    Trigger,
    LoadingState,
};
export { Combobox };
