import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { createElement, useMemo } from 'react';
import { getDOMProps } from '../utils';
import kinds from './kinds';
import { LinkElement } from './styles';
/** @param {any} */
function Link(_a) {
    var { activeClassName, children, isDisabled, kind, tag: Tag } = _a, props = __rest(_a, ["activeClassName", "children", "isDisabled", "kind", "tag"]);
    // https://github.com/styled-components/styled-components/issues/439
    //
    // There is a long standing issue with prop whitelisting for libs such
    // as emotion and styled-components. The problem we have is that when we
    // use a Link component from react-router, we can't pass in a component
    // to render with, we have to instead pass Link into this component. This
    // means that we need an intermediate component to strip out stuff we don't
    // want passed down to the DOM element.
    //
    // There is probably a proper way to solve this, but I've already spent hours
    // trying and the only thing we get out of it is removing a dev warning, so
    // this is the solution I've gone with.
    //
    // We're using a memo because we don't want to be making a new component
    // everytime, that will break react as it will think it's a new component on
    // every render
    const asTag = useMemo(() => (p) => createElement(Tag, getDOMProps(p), p.children), [Tag]);
    /* Order is important here because the last two objects contain stateful
     * props which should overwrite all the rest */
    const allProps = Object.assign(Object.assign({}, kinds[kind]), props);
    return (_jsx(LinkElement, Object.assign({ activeClassName: activeClassName, as: asTag, cursor: isDisabled ? 'default' : 'pointer', draggable: false, pointerEvents: isDisabled ? 'none' : 'auto', rel: 
        // https://mathiasbynens.github.io/rel-noopener/
        Tag === 'a' && props.target === '_blank' ? 'noreferrer noopener' : null }, allProps, { children: children })));
}
Link.defaultProps = {
    activeClassName: 'activeHeavy',
    display: 'inline-block',
    hasUnderline: false,
    kind: 'normal',
    lineHeight: 1.5,
    tag: 'a',
};
export default Link;
