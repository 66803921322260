import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Route } from 'react-router-dom';
import { encodeParams } from '@marvelapp/core';
import { usePermissions } from '../PermissionsProvider';
import { useConfig, useRouteConfig } from '../react';
export function AuthedRoute(_a) {
    var { redirectToSignUp } = _a, props = __rest(_a, ["redirectToSignUp"]);
    const config = useConfig();
    const permissions = usePermissions();
    const { loginUri } = useRouteConfig();
    if (permissions.canViewAuthedRoutes()) {
        return _jsx(Route, Object.assign({}, props));
    }
    if (config.ballpark) {
        const { marketingSite, applicationSite } = config.ballpark;
        const redirectUri = getSignInPageUrl(marketingSite, applicationSite, window.location.pathname, redirectToSignUp);
        window.location.href = redirectUri;
        return null;
    }
    // can't use a <Redirect> here as it results in an endless loop
    window.location.href = `${loginUri}?${encodeParams({
        origin: window.location.pathname,
    })}`;
    return null;
}
function getSignInPageUrl(marketingSiteUrl, applicationSite, origin, redirectToSignUp) {
    const searchParam = new URLSearchParams(`?next=${applicationSite}${origin}&no-redirect`);
    return `${marketingSiteUrl}/sign-${redirectToSignUp ? 'up' : 'in'}?${searchParam}`;
}
