import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { kinds, sizes } from '../Input';
import { Label, LabelContainer } from '../Label';
import { StyledTelInput } from './TelInput.styles';
export function TelInputWithLabel(_a) {
    var { setIsNumberValid, setNumber, size, kind, error, theme } = _a, rest = __rest(_a, ["setIsNumberValid", "setNumber", "size", "kind", "error", "theme"]);
    const allProps = Object.assign(Object.assign(Object.assign({}, sizes[size]), kinds[kind]), rest);
    return (_jsxs(Box, { mt: 2, width: 1, children: [_jsxs(LabelContainer, { marginBottom: 12, children: [_jsx(Label, { display: "inline-block", size: "s", children: "Phone Number" }), error && (_jsx(Error, { "data-testid": "tel-input-error", fontSize: "s", children: error }))] }), _jsx(StyledTelInput, Object.assign({}, allProps, { children: _jsx(IntlTelInput, { containerClassName: "intl-tel-input", inputClassName: "form-control", onPhoneNumberChange: (validation, value, countryData) => {
                        setNumber(`${countryData.dialCode}${value}`);
                        setIsNumberValid(validation);
                    }, separateDialCode: true }) }))] }));
}
const Error = styled(Box).attrs({
    lineHeight: 'base',
    color: 'red11',
}) ``;
