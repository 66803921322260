import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { Link } from '../Link';
import { CategorySlug } from '../types';
export const emailSubjectLineTesting = {
    slug: 'email-subject-line-testing',
    title: 'Email Subject Line Test Template | Ballpark',
    metaDescription: 'See which subject lines resonate the best with your audience with this email subject line testing template.',
    heading: 'Email subject line testing template',
    subheading: 'Discover the best performing email subject line and designs before going live',
    categories: [CategorySlug.BrandAndCopyTesting, CategorySlug.Marketing],
    body: _jsx(EmailSubjectLineTestingBody, {}),
    templateUUID: 'ut_4e507bc7-e788-48be-9929-835c0b45034c',
    recordLink: 'https://app.ballparkhq.com/record/4e507bc7-e788-48be-9929-835c0b45034c',
    accordionTitle: 'Email test template FAQs ',
    accordionItems: [
        {
            id: 1,
            question: 'What are some example questions in an email test?',
            answerParagraphs: [
                'When running your test you can ask questions such as, would you be likely to open an email with this subject line? Based on this subject line, what do you think this email is going to be about? Is there any content missing from this email that would make it more useful for you?',
            ],
        },
        {
            id: 2,
            question: 'What type of data can I gather in an email test?',
            answerParagraphs: [
                'You can gather a range of data including heatmaps of a user’s movements on your email design, open-ended questions and pick a design preference.',
            ],
        },
        {
            id: 3,
            question: 'What other marketing testing can I do?',
            answerParagraphs: [_jsx(AccordionItem1, {})],
        },
    ],
};
function EmailSubjectLineTestingBody() {
    return (_jsxs("div", { children: [_jsx("p", { children: "Emails are often one of the core channels within a marketing campaign. They give you the opportunity to update your customers on a new feature or encourage active use or purchase." }), _jsx("p", { children: "Our email testing template helps you measure which subject lines are more likely to lead to an open and designs that will improve clicks in order to increase your conversion." }), _jsx("h3", { children: "Components of an email that you can test in our template:" }), _jsxs("ul", { children: [_jsx("li", { children: "Performance of a subject line and preview text" }), _jsx("li", { children: "Preferred images in a design" }), _jsx("li", { children: "Email content and messaging" })] })] }));
}
function AccordionItem1() {
    return (_jsxs("p", { children: ["You can test other campaign assets such as", ' ', _jsx(Link, { href: "https://ballparkhq.com/template/competitor-paid-search-test", children: "paid search ads" }), ", copy,", ' ', _jsx(Link, { href: "https://ballparkhq.com/template/tagline-test", children: "taglines" }), ' ', "and", ' ', _jsx(Link, { href: "https://ballparkhq.com/template/landing-page-preference-test", children: "landing pages" }), "."] }));
}
