import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { tv } from 'tailwind-variants';
import { Stack } from '../Stack';
import amexIcon from './src/amex.svg?url';
import dinersIcon from './src/diners.svg?url';
import discoverIcon from './src/discover.svg?url';
import jcbIcon from './src/jcb.svg?url';
import mastercardIcon from './src/mastercard.svg?url';
import unionPayIcon from './src/unionpay.svg?url';
import unknownCardIcon from './src/unknown.svg?url';
import visaIcon from './src/visa.svg?url';
const paymentIcon = tv({
    base: [
        'rounded-md',
        'ring-1',
        'ring-inset',
        'ring-gray-600/10',
        'overflow-hidden',
        'bg-cover',
    ],
    variants: {
        size: {
            sm: ['w-[34px]', 'h-6'],
            md: ['w-[46px]', 'h-8'],
        },
    },
    defaultVariants: {
        size: 'sm',
    },
}, {
    responsiveVariants: true,
    twMerge: false, // https://github.com/nextui-org/tailwind-variants/issues/18
});
var PaymentIconType;
(function (PaymentIconType) {
    PaymentIconType["amex"] = "amex";
    PaymentIconType["diner"] = "diner";
    PaymentIconType["discover"] = "discover";
    PaymentIconType["jcb"] = "jcb";
    PaymentIconType["mastercard"] = "mastercard";
    PaymentIconType["unionpay"] = "unionpay";
    PaymentIconType["visa"] = "visa";
    PaymentIconType["unknown"] = "unknown";
})(PaymentIconType || (PaymentIconType = {}));
function PaymentIcon(props) {
    const { className, size, type } = props;
    return (_jsx(Stack, { className: paymentIcon({ className, size }), style: {
            backgroundImage: `url(${getPaymentIconImage(type)})`,
        } }));
}
function getPaymentIconImage(type) {
    switch (type) {
        case PaymentIconType.amex:
            return amexIcon;
        case PaymentIconType.diner:
            return dinersIcon;
        case PaymentIconType.discover:
            return discoverIcon;
        case PaymentIconType.jcb:
            return jcbIcon;
        case PaymentIconType.mastercard:
            return mastercardIcon;
        case PaymentIconType.unionpay:
            return unionPayIcon;
        case PaymentIconType.visa:
            return visaIcon;
        case PaymentIconType.unknown:
            return unknownCardIcon;
        default:
            return '';
    }
}
export { PaymentIcon, PaymentIconType };
