import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as React from 'react';
import { Label } from './Label';
import { LegacySelect } from './LegacySelect';
export const SelectWithLabel = forwardRef((props, ref) => {
    const { children, disabled, id, isOptional, label, withLabel = true, labelSize = 's', onChange, selectSize = 'm', value, mb = 's' } = props, rest = __rest(props, ["children", "disabled", "id", "isOptional", "label", "withLabel", "labelSize", "onChange", "selectSize", "value", "mb"]);
    return (_jsxs(_Fragment, { children: [withLabel && (_jsx(Label, { display: "inline-block", htmlFor: id, isOptional: isOptional, marginBottom: mb, size: labelSize, children: label })), _jsx(LegacySelect, Object.assign({ disabled: disabled, id: id, onChange: onChange, ref: ref, size: selectSize, value: value, width: 1, "aria-label": label }, rest, { children: children }))] }));
});
