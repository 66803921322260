import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import { BtwButton } from './BtwButton';
const tinyButton = tv({
    base: [
        '!size-4',
        '[&[data-state=open]_svg]:rotate-180',
        '[&_svg]:!size-2.5',
        '[&_svg]:duration-300',
        '[&_svg]:ease-smooth',
        '[&_svg]:transition-combined',
        '[&_svg]:will-change-transform',
    ],
    variants: {
        rounded: {
            full: ['rounded-full'],
            partial: ['rounded'],
        },
    },
    defaultVariants: {
        rounded: 'partial',
    },
});
export const TinyButton = forwardRef((_a, ref) => {
    var { className, rounded } = _a, rest = __rest(_a, ["className", "rounded"]);
    return (_jsx(BtwButton, Object.assign({ className: tinyButton({ className, rounded }), size: "sm", variant: "ghost" }, rest, { ref: ref })));
});
