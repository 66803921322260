import { jsx as _jsx } from "react/jsx-runtime";
import { BtwButton, FullScreenPage } from '@marvelapp/ballpark-components';
import { Page } from '@marvelapp/ui-internal';
function ProjectUnavailable({ status }) {
    const isEmbedded = window.top !== window.self;
    const title = `This project is ${status}`;
    return (
    // Important to set this because embeds are transparent, this avoids the project looking odd when embedded on a red page.
    _jsx("div", { className: isEmbedded ? 'bg-gray-50' : 'bg-white', children: _jsx(Page, { title: title, children: _jsx(FullScreenPage.Container, { noFooter: true, children: _jsx(FullScreenPage.Message, { heading: title, description: "Ask the owner to verify the share link and/or update permissions to play this project.", children: _jsx(BtwButton, { asChild: true, variant: "legacyBlue", children: _jsx("a", { "data-testid": "archived-page-cta", href: "https://ballparkhq.com", children: "Visit Ballpark" }) }) }) }) }) }));
}
export function ProjectLocked() {
    return _jsx(ProjectUnavailable, { status: "locked" });
}
export function ProjectArchived() {
    return _jsx(ProjectUnavailable, { status: "archived" });
}
