import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { Button, Link } from '@marvelapp/ballpark-components';
import { CONTACT_SUPPORT } from '@marvelapp/ballpark-help';
import { HintBar } from '@marvelapp/ui';
export function HBLockedAccount({ userInfo, identifier, }) {
    const bannerContent = getBannerContent(userInfo);
    if (!bannerContent)
        return null;
    const { title, description, cta, testId } = bannerContent;
    return (_jsx(HintBar, { "data-testid": `${identifier}--${testId}`, bg: "primary", callToAction: 'link' in cta ? (_jsx(Button, { as: Link, "data-testid": "cta", kind: "ghost", to: cta.link, children: cta.copy })) : (cta), description: description, title: title }));
}
function getBannerContent(userInfo) {
    if (!userInfo.canUpgradePlan) {
        const { ownerEmail } = userInfo;
        const link = `mailto:${ownerEmail}`;
        return {
            title: 'Contact your workspace admin to upgrade.',
            description: (_jsxs(_Fragment, { children: ["Please contact", ' ', _jsx(Link, { kind: "white", to: link, target: "_blank", fontWeight: 500, children: ownerEmail }), ' ', "to upgrade your account to continue testing."] })),
            cta: { copy: 'Contact workspace owner', link },
            testId: 'guest',
        };
    }
    if (userInfo.isLockedFreePlan) {
        return {
            title: 'Your free trial has ended. Upgrade now to keep testing.',
            description: 'Get unlimited users and responses on every plan. Click the button to view all upgrade options.',
            cta: (_jsxs(_Fragment, { children: [_jsx(Button, { to: CONTACT_SUPPORT, mr: "s", "data-testid": "extend-trial", as: Link, kind: "ghost", children: "Extend trial" }), _jsx(Button, { as: Link, kind: "ghost", to: "/plans", "data-testid": "cta", children: "Upgrade now" })] })),
            testId: 'free',
        };
    }
    if (userInfo.isEnterpriseAndTrialExpired) {
        return {
            title: 'Your Ballpark enterprise trial has ended. Pick a plan to upgrade.',
            description: 'Get unlimited users and responses on every plan. Click the button to view all upgrade options.',
            cta: { copy: 'Upgrade now', link: '/plans' },
            testId: 'enterprise-trial',
        };
    }
    if (userInfo.isEnterprise) {
        const link = 'mailto:help@ballparkhq.com';
        return {
            title: 'Your workspace has been locked. Please get in touch.',
            description: (_jsxs(_Fragment, { children: ["Please contact", ' ', _jsx(Link, { kind: "white", to: link, target: "_blank", fontWeight: 500, children: "help@ballparkhq.com" }), ' ', "to speak to us about your account."] })),
            cta: { copy: 'Contact us', link },
            testId: 'enterprise',
        };
    }
}
