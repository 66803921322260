import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { pluralize } from '@marvelapp/core';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { CardSortThumb } from '../CardSortThumb';
import { ScrollArea } from '../ScrollArea';
import { Stack } from '../Stack';
import { cn } from '../utils';
function Root(props) {
    const { children, type = 'always' } = props, rest = __rest(props, ["children", "type"]);
    return (_jsxs(ScrollArea.Root, { type: type, children: [_jsxs(ScrollArea.Viewport, { children: [_jsx(Stack, Object.assign({ align: "stretch", direction: "row" }, rest, { children: children })), type === 'always' && (_jsxs(_Fragment, { children: [_jsx("div", { className: "h-[38px]" }), _jsx("div", { className: "absolute bottom-0 left-0 right-0 h-[38px] border-t border-gray-200" })] }))] }), _jsx(ScrollArea.Scrollbar
            // Target the thumb
            , { 
                // Target the thumb
                className: cn({
                    'p-4 [&>div>div]:bg-gray-900 [&>div>div]:hover:bg-gray-900/70': type === 'always',
                }), orientation: "horizontal" })] }));
}
function Category(props) {
    const { title, className, categoriesCount, cardsCount, children } = props, rest = __rest(props, ["title", "className", "categoriesCount", "cardsCount", "children"]);
    return (_jsx("div", Object.assign({ "data-testid": "category", className: cn('border-r', 'last:border-none', 'border-gray-200', '[&>div>div]:px-4', '[&>div>div]:py-3', '[&>div>div]:first:pl-8', '[&>div>span]:p-4', '[&>div>span]:first:pl-8', className) }, rest, { children: _jsxs(Stack, { className: cn({
                'w-60': categoriesCount > 2,
                'w-[348px]': categoriesCount === 2,
                'w-[698px]': categoriesCount === 1,
            }), children: [_jsxs(Stack, { align: "baseline", className: "border-b border-gray-200", direction: "row", gap: "1", justify: "between", width: "full", children: [_jsx(BtwHeading, { truncate: true, asChild: true, size: "sm", children: _jsx("h3", { "data-testid": "category-title", children: title }) }), _jsx(BtwText, { className: "whitespace-nowrap", size: "xs", "data-testid": "card-count", children: cardsCount
                                ? `${cardsCount} ${pluralize(cardsCount, 'card')}`
                                : null })] }), _jsx(Stack, { asChild: true, gap: "2.5", width: "full", children: _jsx("span", { children: children }) })] }) })));
}
function Card(props) {
    const { title, index, imageSrc } = props, rest = __rest(props, ["title", "index", "imageSrc"]);
    return (_jsxs(Stack, Object.assign({ align: "center", direction: "row", justify: "between", width: "full", "data-testid": "card" }, rest, { children: [_jsxs("div", { className: "min-w-0 flex-1 text-left", children: [index !== undefined && (_jsx(BtwText, { size: "sm", className: "pr-2", asChild: true, "data-testid": "card-rank", children: _jsxs("span", { children: [index + 1, "."] }) })), _jsx(BtwText, { size: "sm", "data-testid": "card-title", asChild: true, className: "break-words", children: _jsx("span", { children: title }) })] }), imageSrc && (_jsx("div", { className: "flex-shrink-0", children: _jsx(CardSortThumb, { title: title, imageSrc: imageSrc, type: "card" }) }))] })));
}
export const CardSortingResponse = {
    Root,
    Category,
    Card,
};
