import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useOverrideNativeUndo } from '@ballpark/realtime-plugin-history';
import * as React from 'react';
import { Select as MarvelSelect } from '@marvelapp/ui';
export const kinds = {
    primary: {
        bg: 'background',
        color: 'slate12',
        border: '1px solid',
        borderColor: 'slate6',
        boxShadow: 'none',
        hoverBoxShadow: 'none',
        hoverBorderColor: 'slate8',
        focusBorderColor: 'blue8',
        focusBoxShadow: 'select.focus',
    },
};
export const sizes = {
    s: {
        borderRadius: 's',
        fontSize: 's',
        height: '30px',
        lineHeight: '28px',
        minWidth: '30px',
        paddingX: 'xs',
    },
    m: {
        borderRadius: 's',
        fontSize: 's',
        height: '36px',
        lineHeight: '34px',
        minWidth: '36px',
    },
    l: {
        borderRadius: 's',
        fontSize: 's',
        height: '40px',
        lineHeight: '38px',
        minWidth: '40px',
    },
    xl: {
        borderRadius: 'm',
        fontSize: 'l',
        height: '50px',
        lineHeight: '42px',
        minWidth: '50px',
    },
};
export function LegacySelect(_a) {
    var { kind = 'primary', size = 'm', onKeyDown } = _a, rest = __rest(_a, ["kind", "size", "onKeyDown"]);
    const overrideNativeUndo = useOverrideNativeUndo(onKeyDown);
    return (_jsx(MarvelSelect, Object.assign({ iconColor: "slate12", iconSize: sizes[size].minWidth }, kinds[kind], sizes[size], rest, { onKeyDown: overrideNativeUndo })));
}
