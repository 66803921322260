import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useRef, useState, } from 'react';
import * as React from 'react';
import { AiTextIcon, TranscriptionIcon } from '@marvelapp/ballpark-icons';
import { BtwTabs } from './BtwTabs';
import { BtwText } from './BtwText';
import { CopyToClipboardButton } from './CopyToClipboardButton';
import { CtaMessage } from './CtaMessage';
import { Pin } from './Pin';
import { ScrollArea } from './ScrollArea';
import { Stack } from './Stack';
import { cn } from './utils';
const TAB = {
    Transcription: 'Transcription',
    Summary: 'Summary',
};
const TranscriptionContext = createContext({});
function Root(props) {
    const { children, actions, className } = props;
    const childrenArray = React.Children.toArray(children);
    const hasSummary = childrenArray.some((child) => React.isValidElement(child) && child.type === SummaryTab);
    const hasTranscription = childrenArray.some((child) => React.isValidElement(child) && child.type === TranscriptionTab);
    const scrollAreaViewportRef = useRef(null);
    const lastActiveIndex = useRef(-1);
    const scrollItemIntoView = useCallback((element, index) => {
        if (!element || !scrollAreaViewportRef.current) {
            return;
        }
        const container = scrollAreaViewportRef.current;
        lastActiveIndex.current = index;
        const elementTop = element.offsetTop;
        const topPadding = 10;
        container.scrollTo({
            top: elementTop - topPadding,
            behavior: 'smooth',
        });
    }, []);
    // Tracking whether the transcription container is scrollable,
    // so we can adjust the copy button positioning for each line
    const [isScrollable, setIsScrollable] = useState(false);
    useEffect(() => {
        const container = scrollAreaViewportRef === null || scrollAreaViewportRef === void 0 ? void 0 : scrollAreaViewportRef.current;
        if (!container)
            return;
        const checkIfScrollable = () => {
            const { clientHeight, scrollHeight } = container;
            setIsScrollable(scrollHeight > clientHeight);
        };
        // Initial check
        checkIfScrollable();
        // Adding a ResizeObserver to update state when viewport size changes
        const resizeObserver = new ResizeObserver(() => {
            checkIfScrollable();
        });
        resizeObserver.observe(container);
        // Cleanup
        return () => {
            resizeObserver.disconnect();
        };
    }, [scrollAreaViewportRef, hasTranscription]);
    const contextValue = {
        onScrollIntoView: scrollItemIntoView,
        scrollAreaViewportRef,
        isScrollable,
    };
    return (_jsx(TranscriptionContext.Provider, { value: contextValue, children: _jsx(Stack, { width: "full", asChild: true, "data-testid": "transcription", children: _jsxs(BtwTabs.Root, { defaultValue: TAB.Transcription, children: [_jsxs(BtwTabs.List, { className: "relative z-10 w-full", "aria-label": "Transcription", children: [_jsx(BtwTabs.Trigger, { leadingIcon: _jsx(TranscriptionIcon, {}), size: "intermediate", value: TAB.Transcription, variant: "ghost", children: TAB.Transcription }), hasSummary && (_jsx(BtwTabs.Trigger, { disabled: !hasSummary, leadingIcon: _jsx(AiTextIcon, {}), size: "intermediate", value: TAB.Summary, variant: "ghost", children: TAB.Summary })), actions && _jsx("div", { className: "ml-auto", children: actions })] }), hasTranscription ? (_jsxs(ScrollArea.Root, { className: className, children: [_jsxs(ScrollArea.Viewport, { ref: scrollAreaViewportRef, children: [_jsx("div", { className: "h-4" }), children, _jsx("div", { className: "h-4" }), _jsx("div", { className: "absolute left-0 right-0 top-0 h-4 bg-gradient-to-b from-gray-50 to-transparent" }), _jsx("div", { className: "absolute bottom-0 left-0 right-0 h-4 bg-gradient-to-t from-gray-50 to-transparent" })] }), _jsx(ScrollArea.Scrollbar, { orientation: "horizontal" }), _jsx(ScrollArea.Scrollbar, { orientation: "vertical" })] })) : (_jsx("div", { className: "w-full pt-4", children: children }))] }) }) }));
}
export function Item(props) {
    const { isActive, name, nameVariant, text, timestamp, index } = props;
    const { onScrollIntoView, isScrollable } = useContext(TranscriptionContext);
    const ref = React.useRef(null);
    const prevActiveRef = useRef(isActive);
    useEffect(() => {
        const wasActive = prevActiveRef.current;
        prevActiveRef.current = isActive;
        // We check both isActive && !wasActive to ensure we only scroll when an item
        // becomes active, not when it remains active or becomes inactive. This prevents
        // unnecessary scroll operations on every re-render while an item is active.
        if (isActive &&
            !wasActive &&
            onScrollIntoView &&
            typeof index === 'number') {
            requestAnimationFrame(() => {
                onScrollIntoView(ref.current, index);
            });
        }
    }, [isActive, onScrollIntoView, index, text]);
    return (_jsxs(Stack, { ref: ref, "data-testid": "segment", className: cn('p-2', 'group', 'relative', 'rounded-lg', 'duration-300', 'ease-smooth', 'transition-combined', 'hover:bg-gray-600/10', {
            'bg-gray-100': isActive,
        }), direction: "row", gap: "1", width: "full", "data-active": isActive, children: [_jsx("div", { className: "w-14 py-1", children: _jsx(BtwText, { asChild: true, variant: isActive ? 'primary' : 'secondary', leading: "normal", size: "13", weight: isActive ? 'medium' : 'normal', children: _jsx("span", { "data-testid": "timestamp", children: timestamp }) }) }), _jsxs("div", { className: "flex-1 py-1", children: [name && (_jsxs(_Fragment, { children: [_jsx(BtwText, { asChild: true, leading: "normal", size: "13", variant: nameVariant, weight: "semibold", children: _jsx("span", { children: name }) }), _jsx(BtwText, { asChild: true, leading: "normal", size: "13", children: _jsx("span", { children: ": " }) })] })), _jsx(BtwText, { asChild: true, leading: "normal", size: "13", variant: isActive ? 'primary' : 'secondary', weight: isActive ? 'medium' : 'normal', children: _jsx("span", { "data-testid": "text", children: text }) })] }), _jsx(Pin.Tr, { className: cn('p-2.5 opacity-0 group-hover:opacity-100', isScrollable ? 'mr-2.5' : 'mr-0'), children: _jsx(CopyToClipboardButton, { size: "sm", text: text, testId: "copy-segment" }) })] }));
}
function TranscriptionTab(props) {
    const { children } = props;
    const isGeneratingTranscription = false;
    return (_jsx(BtwTabs.Content
    // height is 100%, minus the h-4 spacers top and bottom in the Root component.
    // Without this, the container will always have a scroll bar, because
    // it's effectively 100% + h-4 * 2
    , { 
        // height is 100%, minus the h-4 spacers top and bottom in the Root component.
        // Without this, the container will always have a scroll bar, because
        // it's effectively 100% + h-4 * 2
        className: "h-[calc(100%_-_32px)] w-full flex-1", value: TAB.Transcription, children: _jsx(Stack, { className: "h-full", width: "full", children: isGeneratingTranscription ? (_jsx(Stack, { justify: "center", align: "center", className: "h-full rounded-lg border border-dashed border-gray-600/20", width: "full", children: _jsx("div", { className: "max-w-72 p-8", children: _jsx(CtaMessage.Root, { size: "sm", children: _jsxs(CtaMessage.Header, { icon: _jsx(AiTextIcon, {}), children: [_jsx(CtaMessage.Heading, { children: "Generating transcription" }), _jsx(CtaMessage.Description, { children: "Transcription is currently being generated, it\u2019ll appear here soon" })] }) }) }) })) : (children) }) }));
}
function SummaryTab(props) {
    const { children } = props;
    return (_jsx(BtwTabs.Content, { className: "w-full [&>div]:w-full [&>div]:border [&>div]:border-purple-300", value: TAB.Summary, children: children }));
}
export const Transcription = {
    Item,
    Root,
    SummaryTab,
    TranscriptionTab,
};
