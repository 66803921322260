import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { ClockAlertIcon, EarthIcon, PeopleBehindIcon, TargetIcon, } from '@marvelapp/ballpark-icons';
import { pluralize } from '@marvelapp/core';
import { BtwText } from '../BtwText';
import { BtwTooltip } from '../BtwTooltip';
import { Stack } from '../Stack';
export const OrderFilterSummary = function OrderFilterSummary({ age, country, duration, numberOfResponses, studySpecificFilters, studyType, }) {
    return (_jsxs(Stack, { className: "flex-1 divide-y divide-gray-600/10 sm:h-full sm:overflow-y-scroll", width: "full", children: [_jsx(MainTargeting, { age: age, country: country, duration: duration, numberOfResponses: numberOfResponses, studyType: studyType }), !!(studySpecificFilters === null || studySpecificFilters === void 0 ? void 0 : studySpecificFilters.length) && (_jsx(Stack, { className: "divide-y divide-gray-600/10 ", width: "full", children: studySpecificFilters.map(([id, { rowIcon, name, tooltipText, options }]) => (_jsx(ListItem, { className: "p-6", icon: rowIcon, label: name, tooltipText: tooltipText, children: options.map((option) => (_jsx(BtwText, { size: "13", variant: "primary", children: option.name }, option.id))) }, id))) }))] }));
};
const MainTargeting = function MainTargeting({ age, country, duration, numberOfResponses, studyType, }) {
    return (_jsxs(Stack, { className: "p-6", gap: "5", width: "full", children: [_jsx(ListItem, { icon: _jsx(TargetIcon, {}), label: "Study focus", children: _jsx(BtwText, { size: "13", variant: "primary", weight: "medium", children: studyType }) }), _jsx(ListItem, { icon: _jsx(PeopleBehindIcon, {}), label: "Number of responses", children: _jsx(BtwText, { size: "13", variant: "primary", weight: "medium", children: numberOfResponses }) }), _jsx(ListItem, { icon: _jsx(ClockAlertIcon, {}), label: "Study duration", children: _jsxs(BtwText, { size: "13", variant: "primary", weight: "medium", children: [duration, " ", pluralize(duration, 'minute')] }) }), country && (_jsx(ListItem, { icon: _jsx(EarthIcon, {}), label: "Country", children: _jsx(BtwText, { size: "13", variant: "primary", weight: "medium", children: country }) })), age && (_jsx(ListItem, { icon: _jsx(PeopleBehindIcon, {}), label: "Age", children: age }))] }));
};
export const ListItem = function ListItem({ className, children, icon, label, tooltipText, }) {
    return (_jsxs(Stack, { className: className, "data-testid": "summary-item", direction: { initial: 'col', md: 'row' }, width: "full", gap: "4", children: [_jsxs(Stack, { align: "center", className: "[&>svg]:size-4.5 flex-1 [&>svg]:mt-px [&>svg]:shrink-0", direction: "row", gap: "2", children: [icon, _jsx(BtwTooltip, { content: tooltipText, "data-testid": "filter-info-tooltip", children: _jsx("div", { children: _jsx(BtwText, { "data-testid": "summary-item-label", size: "13", className: "line-clamp-1 cursor-pointer text-left", children: label }) }) })] }), _jsx(Stack, { className: "flex-1", "data-testid": "summary-item-value", gap: "2", children: children })] }));
};
