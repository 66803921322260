import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { position } from 'styled-system';
import styled from '@marvelapp/styled';
import { CircleButton } from './CircleButton';
export function CornerButton(_a) {
    var { icon, isDisabled, kind, onClick, right = '-15px', size = 'xs', testId, top = '-15px' } = _a, props = __rest(_a, ["icon", "isDisabled", "kind", "onClick", "right", "size", "testId", "top"]);
    return (_jsx(Button, Object.assign({ "data-testid": testId, isDisabled: isDisabled, kind: kind, onClick: onClick, size: size, right: right, top: top }, props, { children: icon })));
}
const Button = styled(CircleButton).attrs({
    borderRadius: 'circle',
}) `
  ${position}
  position: absolute;
  padding: 2px;
  fill: ${(props) => props.fill || undefined}

  &:hover {
    fill: ${(props) => props.hoverFill || undefined}
  }
`;
