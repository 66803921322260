import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CategorySlug } from '../types';
export const ratingScale = {
    slug: 'rating-scale',
    title: 'Rating Scale Survey Test Template | Ballpark',
    metaDescription: 'Participants can rate the degree of their opinions on a topic through this rating scale survey test template.',
    heading: 'Rating scale template',
    subheading: 'Evaluate individual or collective opinion',
    categories: [CategorySlug.Basics, CategorySlug.Research],
    body: _jsx(RatingScaleBody, {}),
    templateUUID: 'ut_0f60ea3d-8c4c-4bd0-95df-95cf958e9dd2',
    recordLink: 'https://app.ballparkhq.com/record/0f60ea3d-8c4c-4bd0-95df-95cf958e9dd2',
    accordionTitle: 'Rating scale FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'Are inconsistent ratings a bad thing?',
            answerParagraphs: [
                'No.',
                'Opinions are subjective, especially when it comes to users. Different users often have different needs (or the same needs but to varying degrees). Regardless of whether you decide to address all user needs or only some of them, if the ratings aren’t consistent you’ll want to segment respondents based on their rating in order to build the right product for the right audience.',
            ],
        },
    ],
};
function RatingScaleBody() {
    return (_jsxs("div", { children: [_jsx("p", { children: "Rating scales are closed-ended questions. They\u2019re similar to \u2018yes or no\u2019 questions except that rating scales are used to evaluate the extremity of the respondents\u2019 opinions, usually on a scale of 1-5 (or 1-10 to allow for an ambivalent response of \u201C5\u201D)." }), _jsx("p", { children: "An alternative approach is to ask respondents how much they agree (or disagree) with a statement, with answers ranging from \u201CStrongly disagree\u201D to \u201CStrongly agree\u201D. It\u2019s essentially the same thing as numbered answers but with a different style of communication." }), _jsxs("ul", { children: [_jsx("li", { children: "Numbered: \u201CHow would you rate your booking experience?\u201D [1, 2, 3, 4, 5]" }), _jsx("li", { children: "Statement: \u201Chas the best booking experience\u201D [strongly disagree, somewhat disagree, neither agree or disagree, somewhat agree, strongly agree]" })] }), _jsx("h3", { children: "2 great ways to use our rating scale template" }), _jsx("p", { children: "By calculating the average rating regularly and consistently, improvement can be tracked over time, which is extremely useful for monitoring objectives." }), _jsx("p", { children: "Alternatively, responses can be segmented. As an example, further, more qualitative research could be conducted with respondents that chose a low rating." })] }));
}
