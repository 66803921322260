import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { isInABTestVariant } from '@marvelapp/core';
import { PermissionsProvider } from './PermissionsProvider';
const AuthContext = createContext();
const ConfigContext = createContext();
const FeatureFlagsContext = createContext();
const LastLocationContext = createContext();
const LogoutContext = createContext();
const SidebarContext = createContext();
const PusherContext = createContext();
const RouteConfigContext = createContext({
    loginUri: '/login',
});
const SubscriptionContext = createContext();
const UserContext = createContext();
function Marvel3Provider({ accessToken, children, config, featureFlags, lastLocation, logout, pusher, setSubscription, sidebar, subscription, transactionalKey, user, }) {
    return (_jsx(AuthContext.Provider, { value: accessToken, children: _jsx(FeatureFlagsContext.Provider, { value: featureFlags, children: _jsx(ConfigContext.Provider, { value: config, children: _jsx(LastLocationContext.Provider, { value: lastLocation, children: _jsx(SidebarContext.Provider, { value: sidebar, children: _jsx(PermissionsProvider, { subscription: subscription, user: user, flags: featureFlags, children: _jsx(PusherContext.Provider, { value: { pusher, transactionalKey }, children: _jsx(UserContext.Provider, { value: user, children: _jsx(LogoutContext.Provider, { value: logout, children: _jsx(SubscriptionContext.Provider, { value: [subscription, setSubscription], children: children }) }) }) }) }) }) }) }) }) }));
}
const FeatureFlagsConsumer = FeatureFlagsContext.Consumer;
const ConfigConsumer = ConfigContext.Consumer;
const useAuth = () => useContext(AuthContext);
const useConfig = () => useContext(ConfigContext);
const useCurrentSubscription = () => useContext(SubscriptionContext);
const useCurrentUser = () => useContext(UserContext);
const useFeatureFlag = (key) => useContext(FeatureFlagsContext)[key];
const useLastLocation = () => useContext(LastLocationContext);
const useLogout = () => useContext(LogoutContext);
const usePusher = () => useContext(PusherContext);
const useRouteConfig = () => useContext(RouteConfigContext);
const useSidebar = () => useContext(SidebarContext);
const useABTest = (testKey) => {
    const currentUser = useCurrentUser();
    return isInABTestVariant(testKey, currentUser);
};
export { Marvel3Provider, 
// contexts
FeatureFlagsContext, RouteConfigContext, 
// consumers for old components
FeatureFlagsConsumer, ConfigConsumer, 
// hooks
useAuth, useConfig, useCurrentSubscription, useCurrentUser, useABTest, useFeatureFlag, useLastLocation, useLogout, usePusher, useRouteConfig, useSidebar, };
