import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createColumnHelper, getCoreRowModel, useReactTable, } from '@tanstack/react-table';
import { useState } from 'react';
import { CheckIcon, CrossedIcon, HourglassIcon, } from '@marvelapp/ballpark-icons';
import { Badge } from '../Badge';
import { BtwButton } from '../BtwButton';
import { BtwDataTable } from '../BtwDataTable';
import { BtwLink } from '../BtwLink';
import { BtwText } from '../BtwText';
import { PaymentIcon, PaymentIconType } from '../PaymentIcon';
import { ScrollArea } from '../ScrollArea';
import { AppSidebar, SidebarNavItem, SidebarPanel } from '../Sidebar';
import { Stack } from '../Stack';
import { cn } from '../utils';
import { SettingsHeader } from './SettingsHeader';
function BillingSettingsPage() {
    const [data] = useState(() => [...BillingData]);
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        initialState: {
            sorting: [
                {
                    id: 'date',
                    desc: false,
                },
            ],
        },
    });
    return (_jsxs(Stack, { width: "full", className: "h-full", direction: "row", children: [_jsx(AppSidebar, { initialMenuItem: SidebarNavItem.Settings_Billing, initialActivePanel: SidebarPanel.Settings }), _jsxs(Stack, { className: "h-full overflow-y-hidden", width: "full", children: [_jsx(SettingsHeader.Container, { children: _jsx(SettingsHeader.Title, { children: "Billing" }) }), _jsx(Stack, { width: "full", gap: "8", className: "mx-auto h-full max-w-6xl flex-1 overflow-y-scroll", children: _jsxs(Stack, { width: "full", gap: "8", className: "h-full  p-8", children: [_jsxs(Stack, { width: "full", direction: { initial: 'col', md: 'row' }, gap: "8", align: "stretch", children: [_jsxs(Stack, { width: "full", gap: "6", className: "flex-1 rounded-xl bg-gray-50 px-8 py-6 shadow-sm ring-1 ring-gray-600/10", children: [_jsx(BtwText, { size: "13", weight: "medium", children: "Payment method" }), _jsx("div", { className: "flex-1", children: _jsxs(Stack, { direction: { initial: 'col', lg: 'row' }, gap: "4", className: "flex-1", align: { initial: 'start', lg: 'center' }, children: [_jsx(Stack, { children: _jsx(PaymentIcon
                                                                // This needs to be whatever the payment type is on file.
                                                                , { 
                                                                    // This needs to be whatever the payment type is on file.
                                                                    type: PaymentIconType.unionpay, size: "md" }) }), _jsxs(Stack, { gap: "1", children: [_jsx(BtwText, { size: "13", variant: "primary", weight: "medium", children: "UnionPay (**** 2424)" }), _jsx(BtwText, { size: "13", variant: "primary", children: "Expires 10/24" })] })] }) }), _jsx(Stack, { children: _jsx(BtwButton, { size: "intermediate", children: "Update card" }) })] }), _jsxs(Stack, { width: "full", gap: "6", className: "min-w-0 flex-1 rounded-xl bg-gray-50 px-8 py-6 shadow-sm ring-1 ring-gray-600/10", children: [_jsx(BtwText, { size: "13", weight: "medium", children: "Billing details" }), _jsxs(Stack, { gap: "1", className: "min-w-0 flex-1", width: "full", justify: "start", children: [_jsx(BtwText, { size: "13", variant: "primary", weight: "medium", className: "w-full", truncate: true, children: _jsx(BtwLink, { href: "mailto:ywilkinson@alphawing.com", variant: "secondary", children: "ywilkinson@alphawing.com" }) }), _jsx(BtwText, { size: "13", variant: "primary", className: "w-full", children: "199 Oakway Lane, Woodland Hills, CA 91303" })] }), _jsx(Stack, { children: _jsx(BtwButton, { size: "intermediate", children: "Update details" }) })] })] }), _jsx(Stack, { className: cn(tableClassNames), width: "full", children: _jsx(ScrollArea, { children: _jsx(BtwDataTable, { table: table, withStickyHeader: true }) }) })] }) })] })] }));
}
const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor('date', {
        size: 140,
        header: 'Date',
        cell: (data) => {
            const timestamp = data.getValue();
            const date = new Date(timestamp);
            const formattedDate = date.toLocaleDateString('en-GB', {
                dateStyle: 'medium',
            });
            return _jsx(BtwText, { children: formattedDate });
        },
        enableSorting: false,
        id: 'date',
        meta: {
            sorting: {
                name: 'Date',
                id: 'date',
                type: 'date',
            },
        },
    }),
    columnHelper.accessor('name', {
        size: 300,
        header: 'Product',
        cell: (data) => (_jsx(BtwText, { weight: "medium", variant: "primary", truncate: true, children: data.getValue() })),
        enableSorting: false,
    }),
    columnHelper.accessor('amount', {
        size: 100,
        header: 'Amount',
        cell: (data) => {
            const rawData = data.getValue();
            const amount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(rawData);
            return _jsx(BtwText, { children: amount });
        },
        enableSorting: false,
    }),
    columnHelper.accessor('paymentDetails', {
        size: 160,
        header: 'Payment method',
        cell: (data) => {
            const paymentDetails = data.getValue();
            const { paymentMethod, cardNumber } = paymentDetails;
            const paymentIconType = getPaymentIconType(paymentMethod);
            return (_jsxs(Stack, { direction: "row", gap: "2", align: "center", children: [_jsx(PaymentIcon, { type: paymentIconType }), _jsxs(BtwText, { children: ["**** ", cardNumber] })] }));
        },
        enableSorting: false,
    }),
    columnHelper.accessor('status', {
        size: 100,
        header: 'Status',
        cell: (data) => {
            const paymentStatus = data.getValue();
            switch (paymentStatus) {
                case PaymentStatus.Paid:
                    return (_jsx(Badge, { leadingIcon: _jsx(CheckIcon, {}), variant: "green", children: "Paid" }));
                case PaymentStatus.Pending:
                    return (_jsx(Badge, { leadingIcon: _jsx(HourglassIcon, {}), variant: "amber", children: "Pending" }));
                case PaymentStatus.Failed:
                    return (_jsx(Badge, { leadingIcon: _jsx(CrossedIcon, {}), variant: "red", children: "Failed" }));
                default:
                    return null;
            }
        },
        enableSorting: false,
    }),
    columnHelper.accessor('invoiceLink', {
        size: 80,
        header: 'Invoice',
        cell: () => _jsx(BtwButton, { size: "sm", children: "Download" }),
        enableSorting: false,
    }),
];
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["Paid"] = "Paid";
    PaymentStatus["Pending"] = "Pending";
    PaymentStatus["Failed"] = "Failed";
})(PaymentStatus || (PaymentStatus = {}));
const BillingData = [
    {
        date: '2023-06-10T12:16:01Z',
        name: 'Rexnord Corporation',
        amount: 329,
        paymentDetails: {
            paymentMethod: 'unionpay',
            cardNumber: '4317',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2023-05-31T14:17:06Z',
        name: 'iPath US Treasury 5-year Bull ETN',
        amount: 2289,
        paymentDetails: {
            paymentMethod: 'jcb',
            cardNumber: '0840',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2023-08-18T20:08:01Z',
        name: 'Kadmon Holdings, Inc.',
        amount: 1151,
        paymentDetails: {
            paymentMethod: 'visa',
            cardNumber: '8033',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2024-02-07T07:30:38Z',
        name: 'SandRidge Energy, Inc.',
        amount: 1544,
        paymentDetails: {
            paymentMethod: 'mastercard',
            cardNumber: '0133',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2024-05-09T04:18:07Z',
        name: 'Zhaopin Limited',
        amount: 1439,
        paymentDetails: {
            paymentMethod: 'jcb',
            cardNumber: '2027',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2023-06-15T08:49:41Z',
        name: 'ASML Holding N.V.',
        amount: 1901,
        paymentDetails: {
            paymentMethod: 'jcb',
            cardNumber: '0507',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2024-02-03T18:56:31Z',
        name: 'Koppers Holdings Inc.',
        amount: 925,
        paymentDetails: {
            paymentMethod: 'jcb',
            cardNumber: '2557',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2023-10-25T16:26:46Z',
        name: 'BlueLinx Holdings Inc.',
        amount: 1642,
        paymentDetails: {
            paymentMethod: 'jcb',
            cardNumber: '4114',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2024-01-30T15:02:21Z',
        name: 'Limoneira Co',
        amount: 2307,
        paymentDetails: {
            paymentMethod: 'amex',
            cardNumber: '8069',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2024-01-01T14:53:38Z',
        name: 'Jazz Pharmaceuticals plc',
        amount: 754,
        paymentDetails: {
            paymentMethod: 'jcb',
            cardNumber: '9391',
        },
        status: PaymentStatus.Pending,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2023-12-16T17:26:11Z',
        name: 'Avenue Therapeutics, Inc.',
        amount: 1438,
        paymentDetails: {
            paymentMethod: 'visa',
            cardNumber: '4346',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2024-04-28T19:11:58Z',
        name: 'Safe Bulkers, Inc',
        amount: 1534,
        paymentDetails: {
            paymentMethod: 'diners',
            cardNumber: '4542',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2024-02-26T12:37:19Z',
        name: 'Cedar Fair, L.P.',
        amount: 2205,
        paymentDetails: {
            paymentMethod: 'mastercard',
            cardNumber: '0220',
        },
        status: PaymentStatus.Failed,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2023-09-25T07:06:58Z',
        name: 'Alliance World Dollar Government Fund II',
        amount: 1628,
        paymentDetails: {
            paymentMethod: 'jcb',
            cardNumber: '5929',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2023-07-23T04:08:40Z',
        name: 'Baytex Energy Corp',
        amount: 2668,
        paymentDetails: {
            paymentMethod: 'amex',
            cardNumber: '3398',
        },
        status: PaymentStatus.Failed,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2023-07-06T13:32:59Z',
        name: 'Westlake Chemical Partners LP',
        amount: 2857,
        paymentDetails: {
            paymentMethod: 'amex',
            cardNumber: '0701',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2023-11-18T20:34:26Z',
        name: 'GenVec, Inc.',
        amount: 428,
        paymentDetails: {
            paymentMethod: 'jcb',
            cardNumber: '9293',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2023-06-20T22:12:00Z',
        name: 'CTI Industries Corporation',
        amount: 1817,
        paymentDetails: {
            paymentMethod: 'unionpay',
            cardNumber: '7529',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2024-02-23T09:25:41Z',
        name: 'MagneGas Corporation',
        amount: 2193,
        paymentDetails: {
            paymentMethod: 'mastercard',
            cardNumber: '1547',
        },
        status: PaymentStatus.Pending,
        invoiceLink: 'https://google.com',
    },
    {
        date: '2023-07-25T06:13:40Z',
        name: 'PowerShares LadderRite 0-5 Year Corporate Bond Portfolio',
        amount: 533,
        paymentDetails: {
            paymentMethod: 'diners',
            cardNumber: '7593',
        },
        status: PaymentStatus.Paid,
        invoiceLink: 'https://google.com',
    },
];
const tableClassNames = [
    'rounded-lg',
    '[&_table]:border-spacing-0',
    'border',
    'border-gray-600/10',
    'overflow-hidden',
    '[&_td]:border-x-0',
    '[&_th]:border-x-0',
    '[&_td]:px-6',
    '[&_td]:py-3.5',
    'first:[&_td]:border-x-0',
    'first:[&_th]:border-x-0',
    'first:[&_tbody>tr]:hidden',
    'last:[&_tbody>tr]:hidden',
    // A small little z-index helper for the scrollArea cause of the increased z-index on the table header
    '[&_div[data-orientation=vertical]]:z-50',
];
// I don't know if this is the best way of doing it, but it is what it is.
// I'm basically trying to map a string to an enum to render the correct icon.
const getPaymentIconType = (type) => {
    // Check if the string is a valid key in the enum
    if (Object.values(PaymentIconType).includes(type)) {
        return type;
    }
    // If not, return a default value (e.g., 'unknown')
    return PaymentIconType.unknown;
};
export { BillingSettingsPage };
