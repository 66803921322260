import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import Text from '../Text';
function Heading(_a) {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsx(Text, Object.assign({ color: "licorice", "data-testid": props.testId, fontSize: 5, fontWeight: "600", lineHeight: 1.15 }, props, { children: children })));
}
Heading.propTypes = {
    children: PropTypes.any,
};
export default Heading;
