import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { tv } from 'tailwind-variants';
import { BtwText } from './BtwText';
const label = tv({
    base: [],
    variants: {
        disabled: {
            true: ['opacity-50', 'cursor-not-allowed'],
            false: [],
        },
    },
});
function BtwLabel(props) {
    const { children, className, htmlFor, variant = 'primary', weight = 'semibold', disabled } = props, rest = __rest(props, ["children", "className", "htmlFor", "variant", "weight", "disabled"]);
    return (_jsx(BtwText, Object.assign({ asChild: true, className: label({ className, disabled }), variant: variant, weight: weight }, rest, { children: _jsx("label", { htmlFor: htmlFor, children: children }) })));
}
export { BtwLabel };
