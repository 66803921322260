import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckIcon, CopyIcon } from '@marvelapp/ballpark-icons';
import { useCopyToClipboard } from '@marvelapp/hooks';
import { BtwButton } from './BtwButton';
import { BtwTooltip } from './BtwTooltip';
export function CopyToClipboardButton(props) {
    const { text } = props, rest = __rest(props, ["text"]);
    const [value, copy] = useCopyToClipboard();
    return (_jsx(BtwTooltip, { content: _jsx(_Fragment, { children: value ? 'Copied!' : 'Copy' }), children: _jsx(BtwButton, Object.assign({ onClick: () => copy(text), standaloneIcon: value ? _jsx(CheckIcon, {}) : _jsx(CopyIcon, {}) }, rest)) }));
}
