import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CategorySlug } from '../types';
export const email = {
    slug: 'email',
    title: 'Email Survey Template | Ballpark',
    metaDescription: 'Collect email address for lead generation or for follow up for additional research with our email step template.',
    heading: 'Email template',
    subheading: 'A simple way to collect emails for list building',
    categories: [CategorySlug.Basics, CategorySlug.Research],
    body: _jsx(EmailBody, {}),
    templateUUID: 'ut_fb3ea95b-713a-4954-8a06-3a1181da1f8b',
    recordLink: 'https://app.ballparkhq.com/record/fb3ea95b-713a-4954-8a06-3a1181da1f8b',
    accordionTitle: 'Email template FAQ',
    accordionItems: [
        {
            id: 1,
            question: 'How do I get a list of all the email addresses gathered from my survey or test?',
            answerParagraphs: [
                'You can download all of the responses, including the email addresses as a CSV allowing you to open it in Google Sheets or Excel.',
            ],
        },
    ],
};
function EmailBody() {
    return (_jsxs("div", { children: [_jsx("p", { children: "Whether you are collecting email addresses to follow-up with your participants or building a list of early adopters before launching, our email step ensures addresses are correctly formatted so you know you\u2019re collecting accurate information each time." }), _jsx("h3", { children: "4 great ways to use our email template" }), _jsxs("ul", { children: [_jsx("li", { children: "Gather email addresses from survey respondents, allowing you to follow up with additional questions and tasks" }), _jsx("li", { children: "Send a reward (such as a voucher) for taking part in your survey to the email provided" }), _jsx("li", { children: "Build an audience for your product or service before building it (prototypes are a great way to give people an example of what\u2019s in store, without needing to write code)" }), _jsx("li", { children: "Track the performance of a particular set of participant" })] })] }));
}
