import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useOverrideNativeUndo } from '@ballpark/realtime-plugin-history';
import { forwardRef, useImperativeHandle, useLayoutEffect, useRef, } from 'react';
import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { tv } from 'tailwind-variants';
import { useStateFromProp } from '@marvelapp/hooks';
import { textBaseVariants } from './BtwText';
import { Pin } from './Pin';
const editableText = tv({
    extend: textBaseVariants,
    base: [
        'w-full',
        'appearance-none',
        'bg-transparent',
        'block',
        'box-border',
        'flex-1',
        'ring-2',
        'ring-transparent',
        'border-y',
        'border-x-[3px]',
        'border-white',
        'select-none',
        'rounded',
        'resize-none',
        // Typography
        '!font-body',
        'font-regular',
        'text-ellipsis',
        'tracking-normal',
        // Transition
        'duration-300',
        'ease-smooth',
        'transition-combined',
        // Color
        '!text-gray-900', // important otherwise sizse="13" overrides it
        'placeholder-gray-600/40',
        'focus:outline-none',
    ],
    variants: {
        color: {
            amber: ['ring-amber-300'],
            blue: ['ring-blue-300'],
            pink: ['ring-pink-300'],
            purple: ['ring-purple-300'],
            default: ['focus:ring-gray-600/30', 'hover:ring-gray-600/20'],
        },
    },
    defaultVariants: {
        color: 'default',
    },
});
const pin = tv({
    base: ['[&>div]:rounded-br-md'],
    variants: {
        color: {
            amber: ['[&>div]:bg-amber-300', '[&>div>span]:ring-amber-300'],
            blue: ['[&>div]:bg-blue-300', '[&>div>span]:ring-blue-300'],
            pink: ['[&>div]:bg-pink-300', '[&>div>span]:ring-pink-300'],
            purple: ['[&>div]:bg-purple-300', '[&>div>span]:ring-purple-300'],
            default: [],
        },
    },
    defaultVariants: {
        color: 'default',
    },
});
const EditableTextBase = forwardRef(function EditableTextBase(props, ref) {
    const { className, color, size, weight, realtimeAvatars, onKeyDown } = props, rest = __rest(props, ["className", "color", "size", "weight", "realtimeAvatars", "onKeyDown"]);
    const overrideNativeUndo = useOverrideNativeUndo(props.onKeyDown);
    return (_jsxs("div", { className: "relative w-full", children: [_jsx(TextareaAutosize, Object.assign({ className: editableText({ className, color, size, weight }), ref: ref, onKeyDown: overrideNativeUndo }, rest)), realtimeAvatars && (_jsx(Pin.Br, { className: pin({ color }), children: realtimeAvatars }))] }));
});
/**
 * Input
 */
const EditableTextInput = forwardRef(function EditableTextInputBase(props, ref) {
    const { className, color, size, weight, onKeyDown } = props, rest = __rest(props, ["className", "color", "size", "weight", "onKeyDown"]);
    const overrideNativeUndo = useOverrideNativeUndo(onKeyDown);
    return (_jsx("input", Object.assign({ className: editableText({ className, color, size, weight }), onKeyDown: overrideNativeUndo, ref: ref }, rest)));
});
const EditableTextControlled = forwardRef(function EditableTextControlled(props, ref) {
    const { autoFocus, defaultValue, onBlur: parentOnBlur, 
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSaveValue = () => { }, value } = props, rest = __rest(props, ["autoFocus", "defaultValue", "onBlur", "onSaveValue", "value"]);
    const internalRef = useRef(null);
    useImperativeHandle(ref, () => internalRef.current);
    useLayoutEffect(() => {
        // We don't want to auto focus the textarea when another element is already focused,
        // or we might steal it while the user is typing on another input
        if (!autoFocus || !internalRef.current || document.activeElement) {
            return;
        }
        internalRef.current.focus();
    }, [autoFocus]);
    // Using internal state here as the onChange handler to an input cannot be async.
    // Updates to state in askhub are async so we can't use the value prop directly
    const [internalValue, setInternalValue] = useStateFromProp(value || '');
    const onBlur = (event) => {
        parentOnBlur === null || parentOnBlur === void 0 ? void 0 : parentOnBlur(event);
    };
    const onChange = (event) => {
        setInternalValue(event.target.value);
        onSaveValue(event.target.value);
    };
    return (_jsx(EditableTextBase, Object.assign({ autoFocus: autoFocus, defaultValue: defaultValue, onBlur: onBlur, onChange: onChange, ref: internalRef, value: internalValue }, rest)));
});
export const EditableText = Object.assign(EditableTextControlled, {
    Base: EditableTextBase,
    Input: EditableTextInput,
});
