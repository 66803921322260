import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CategorySlug } from '../types';
export const openEndedQuestion = {
    slug: 'open-ended-question',
    title: 'Open Question Survey Test Template | Ballpark',
    metaDescription: 'Open questions in a survey enables participants to answer freely. Use our open question survey test template to go deeper with feedback and discover insights.',
    heading: 'Open-ended question template',
    subheading: 'A question that respondents can answer freely',
    categories: [CategorySlug.Basics, CategorySlug.Research],
    body: _jsx(OpenEndedQuestionBody, {}),
    templateUUID: 'ut_2e45c7c6-0ee1-4844-9ec6-b9afd1425243',
    recordLink: 'https://app.ballparkhq.com/record/2e45c7c6-0ee1-4844-9ec6-b9afd1425243',
    accordionTitle: 'Open-ended question FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'When are closed questions better than open questions?',
            answerParagraphs: [
                'Closed questions can be quantified whereas open questions must be synthesised first (for example, using card sorting or affinity mapping). However, closed questions limit respondents to certain answers that might not reflect how the respondent truly feels about the topic in question.',
                'Ask closed questions (e.g. yes/no or multiple choice questions) when you’re certain about the range of answers that respondents might want to respond with. When uncertain, it’s better to let them speak freely with an open question.',
            ],
        },
    ],
};
function OpenEndedQuestionBody() {
    return (_jsxs("div", { children: [_jsx("p", { children: "An open-ended question enables respondents to answer using as many words as they feel is necessary, without any binary or non-binary cues. Where closed-ended questions (e.g. yes/no or multiple choice questions) can block respondents from elaborating on their answer or even offering a true answer, open-ended questions allow respondents to fully elaborate." }), _jsx("h3", { children: "3 great ways to use our open-ended question template" }), _jsx("p", { children: "Open-ended questions are useful for asking respondents the following:" }), _jsxs("ul", { children: [_jsx("li", { children: "How they feel about something" }), _jsx("li", { children: "How to talk about examples of something" }), _jsx("li", { children: "How to explain how they would do something" })] })] }));
}
