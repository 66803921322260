import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { tv } from 'tailwind-variants';
import { ChildrenWithIcon, } from './ChildrenWithIcon';
const badge = tv({
    base: ['items-center', '[&>svg]:block', 'font-body', 'tracking-normal'],
    variants: {
        variant: {
            white: ['bg-white', 'text-gray-900'],
            gray: ['bg-gray-600/10', 'text-gray-600'],
            red: ['bg-red-100', 'text-red-700'],
            amber: ['bg-amber-100', 'text-amber-700'],
            green: ['bg-green-100', 'text-green-700'],
            sky: ['bg-sky-100', 'text-sky-700'],
            blue: ['bg-blue-100', 'text-blue-800'],
            pink: ['bg-pink-100', 'text-pink-800'],
            orange: ['bg-orange-100', 'text-orange-800'],
            lime: ['bg-lime-100', 'text-lime-800'],
            purple: ['bg-purple-100', 'text-purple-800'],
            violet: ['bg-violet-100', 'text-violet-800'],
            yellow: ['bg-yellow-100', 'text-yellow-800'],
            fuchsia: ['bg-fuchsia-100', 'text-fuchsia-800'],
            indigo: ['bg-indigo-100', 'text-indigo-800'],
            rose: ['bg-rose-100', 'text-rose-800'],
            legacy: ['bg-legacyBlue', 'text-white'],
            outline: [
                'bg-transparent',
                'ring-1',
                'ring-gray-600/20',
                'text-gray-900',
            ],
        },
        display: {
            flex: ['flex'],
            inlineBlock: ['inline-block'],
            inlineFlex: ['inline-flex'],
        },
        truncate: {
            true: ['max-w-full'],
            false: [],
        },
        size: {
            sm: [
                'h-[18px]',
                'leading-[18px]',
                'gap-1',
                'px-1.5',
                'text-xxs',
                'uppercase',
                'font-semibold',
                'rounded',
                // Tailwind doesn't work with dynamically generated classes so we'll
                // have to hardcode the class name here. It is defined in ./ChildrenWithIcon
                '[&>.inner-icon>svg]:w-[10px]',
                '[&>.inner-icon>svg]:h-[10px]',
                '[&>.inner-icon]:-mx-[1px]',
            ],
            base: [
                'h-6',
                'leading-6',
                'px-2',
                'gap-1.5',
                'text-xs',
                'font-medium',
                'rounded-md',
                // Tailwind doesn't work with dynamically generated classes so we'll
                // have to hardcode the class name here. It is defined in ./ChildrenWithIcon
                '[&>.inner-icon>svg]:w-[14px]',
                '[&>.inner-icon>svg]:h-[14px]',
                '[&>.inner-icon]:-mx-[3px]',
            ],
            lg: [
                'h-7',
                'leading-7',
                'gap-2',
                'px-2.5',
                'text-13',
                'font-medium',
                'rounded-md',
                // Tailwind doesn't work with dynamically generated classes so we'll
                // have to hardcode the class name here. It is defined in ./ChildrenWithIcon
                '[&>.inner-icon>svg]:size-4',
                '[&>.inner-icon]:-mx-[3px]',
            ],
        },
    },
    defaultVariants: {
        size: 'base',
        variant: 'gray',
        display: 'inlineFlex',
    },
}, {
    responsiveVariants: true,
    twMerge: false, // https://github.com/nextui-org/tailwind-variants/issues/18
});
/**
  All purpose badge component
  */
function Badge(props) {
    const { children, className, display, leadingIcon, size, trailingIcon, truncate, variant, testId } = props, rest = __rest(props, ["children", "className", "display", "leadingIcon", "size", "trailingIcon", "truncate", "variant", "testId"]);
    return (_jsx("span", Object.assign({ className: badge({ className, display, variant, size, truncate }), "data-testid": testId }, rest, { children: _jsx(ChildrenWithIcon, { leadingIcon: leadingIcon, trailingIcon: trailingIcon, children: truncate ? _jsx("span", { className: "truncate", children: children }) : children }) })));
}
export { Badge };
