import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { range } from 'lodash-es';
import { tv } from 'tailwind-variants';
import { HeartFilledNoPaddingIcon, StarFilledNoPaddingIcon, ThumbsUpFilledNoPaddingIcon, } from '@marvelapp/ballpark-icons';
import { RatingType } from '@marvelapp/user-test-creator';
import { Stack } from '../Stack';
const ratingIcon = tv({
    base: 'shrink-0',
    variants: {
        size: {
            sm: 'w-4 h-4',
            base: 'h-5 w-5',
            lg: 'h-7 w-7',
        },
        fillColor: {
            gray: 'fill-gray-300',
            red: 'fill-red-500',
            legacyBlue: 'fill-legacyBlue',
            yellow: 'fill-yellow-400',
        },
    },
    defaultVariants: {
        size: 'base',
    },
});
/**
 * RatingScore is a component that displays the rating score.
 * @param props - The props for the RatingScore component.
 * @returns The RatingScore component.
 */
function RatingScore(props) {
    const { ratingType, maxRating, averageRating, size } = props;
    // Creating an array for the total number in the rating scale to loop through and render icons for
    const items = range(maxRating);
    // We remove any decimals and use the whole number to know how many "filled" rating icons to render
    const ratingNumberWhole = averageRating ? Math.floor(averageRating) : 0;
    // And then we use the decimal number to know how much of the partially filled icon to fill
    const ratingNumberDecimal = averageRating
        ? Number((averageRating % ratingNumberWhole).toFixed(1))
        : 0;
    return (_jsx(Stack, { className: "relative", children: _jsx(Stack, { direction: "row", gap: "1.5", children: items.map((value, index) => {
                // If the averageRating has a decimal, render a partially filled icon, which overlays a gray icon
                if (ratingNumberDecimal && index === ratingNumberWhole) {
                    return (_jsxs(Stack, { className: "relative", children: [_jsx("div", { className: "absolute overflow-hidden", style: {
                                    width: `${ratingNumberDecimal * 100}%`,
                                }, children: _jsx(RatingIcon, { size: size, ratingType: ratingType }) }), _jsx(RatingIcon, { size: size, ratingType: ratingType, isEmpty: true })] }, value));
                }
                // Render all icons in with correct colors. If the item in the array is bigger than
                // the whole rating number, make it gray, otherwise icons have a default color based on their type.
                return (_jsx(RatingIcon, { ratingType: ratingType, size: size, isEmpty: index >= ratingNumberWhole }, value));
            }) }) }));
}
/**
 * RatingIcon is a component that renders a rating icon based on the rating type.
 * @param props - The props for the RatingIcon component.
 * @returns The RatingIcon component.
 */
function RatingIcon(props) {
    const { ratingType, size, isEmpty } = props;
    switch (ratingType) {
        case RatingType.Hearts:
            return (_jsx(HeartFilledNoPaddingIcon, { className: ratingIcon({
                    size,
                    fillColor: isEmpty ? 'gray' : 'red',
                }) }));
        case RatingType.Thumbs:
            return (_jsx(ThumbsUpFilledNoPaddingIcon, { className: ratingIcon({
                    size,
                    fillColor: isEmpty ? 'gray' : 'legacyBlue',
                }) }));
        case RatingType.Stars:
            return (_jsx(StarFilledNoPaddingIcon, { className: ratingIcon({
                    size,
                    fillColor: isEmpty ? 'gray' : 'yellow',
                }) }));
        // Ignoring Numerical, because we have no icons to show for it.
        case RatingType.Numerical:
        default:
            return null;
    }
}
export { RatingScore };
