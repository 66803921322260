import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { ScreenerType } from '@marvelapp/user-test-creator';
import { StepTypeIcon } from './StepTypeIcon';
const sizes = {
    xs: {
        width: 18,
        height: 18,
        borderRadius: 'xs',
    },
    s: {
        width: 24,
        height: 24,
        borderRadius: 'xs',
    },
    m: {
        width: 32,
        height: 32,
        borderRadius: 's',
    },
    l: {
        width: 42,
        height: 42,
        borderRadius: 'm',
    },
};
export function ScreenerIcon({ size = 'l', type = ScreenerType.Screener, }) {
    const styleProps = Object.assign({ bg: 'amber6', color: 'amber12' }, sizes[size]);
    if (type === ScreenerType.Screener)
        styleProps.bg = 'cyan5';
    return (_jsx(StepTypeIcon, Object.assign({}, styleProps, sizes[size], { children: _jsx("g", { fill: "#3E4854", fillRule: "nonzero", children: _jsx("path", { d: "M21.5,13 C16.8055796,13 13,16.8055796 13,21.5 C13,26.1944204 16.8055796,30 21.5,30 C26.1944204,30 30,26.1944204 30,21.5 C29.9946467,16.8077988 26.1922012,13.0053533 21.5,13 Z M21.5,27.2690714 C20.8293685,27.2690714 20.2857143,26.7254172 20.2857143,26.0547857 C20.2857143,25.3841542 20.8293685,24.8405 21.5,24.8405 C22.1706315,24.8405 22.7142857,25.3841542 22.7142857,26.0547857 C22.7142857,26.7254172 22.1706315,27.2690714 21.5,27.2690714 Z M22.6135,22.5236429 C22.4919242,22.5664331 22.4106214,22.681328 22.4107143,22.8102143 L22.4107143,23.0178571 C22.4107143,23.5208308 22.0029736,23.9285714 21.5,23.9285714 C20.9970264,23.9285714 20.5892857,23.5208308 20.5892857,23.0178571 L20.5892857,21.8035714 C20.5892857,21.3005978 20.9970264,20.8928571 21.5,20.8928571 C22.3382894,20.8928571 23.0178571,20.2132894 23.0178571,19.375 C23.0178571,18.5367106 22.3382894,17.8571429 21.5,17.8571429 C20.6617106,17.8571429 19.9821429,18.5367106 19.9821429,19.375 C19.9821429,19.8779736 19.5744022,20.2857143 19.0714286,20.2857143 C18.568455,20.2857143 18.1607143,19.8779736 18.1607143,19.375 C18.160957,17.6769286 19.4355133,16.2494301 21.1227115,16.0575739 C22.8099097,15.8657177 24.3724309,16.9706038 24.7539228,18.6252671 C25.1354147,20.2799303 24.2143843,21.9574132 22.6135,22.5236429 Z" }) }) })));
}
