import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { color, display, layout, space } from 'styled-system';
import { cursor, fill, hoverColor, hoverFill, transition, } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
export function Icon(_a) {
    var { paths, children, fill = 'currentColor', height = 24, width = 24, viewBox = '0 0 24 24' } = _a, props = __rest(_a, ["paths", "children", "fill", "height", "width", "viewBox"]);
    return (_jsx(Svg, Object.assign({ height: height, width: width, fill: fill, viewBox: viewBox }, props, { children: paths ? paths.map((path) => _jsx("path", { d: path }, path)) : children })));
}
const Svg = styled.svg `
  ${display};
  ${color};
  ${cursor};
  ${fill};
  ${layout};
  ${space};
  ${transition};

  &:hover {
    ${hoverColor};
    ${hoverFill};
  }
`;
