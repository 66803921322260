import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import { tv } from 'tailwind-variants';
import { BtwModal } from './BtwModal';
import { Image as ImagePrimitive } from './Image';
import { Pin } from './Pin';
import { ScrollArea } from './ScrollArea';
import { Stack } from './Stack';
function FullscreenModalBase(props) {
    const { onOpenChange, open, trigger } = props, rest = __rest(props, ["onOpenChange", "open", "trigger"]);
    return (_jsxs(BtwModal.Root, { onOpenChange: onOpenChange, open: open, children: [_jsx(BtwModal.Trigger, { asChild: true, children: trigger }), _jsx(Content, Object.assign({}, rest))] }));
}
function Content(props) {
    const { centerContent = true, closeButtonTestId, children } = props, rest = __rest(props, ["centerContent", "closeButtonTestId", "children"]);
    return (_jsxs(BtwModal.Content
    // TODO: remove as part of BALL-1861
    , Object.assign({ 
        // TODO: remove as part of BALL-1861
        onPointerDown: (e) => e.preventDefault(), contentClassName: "w-screen h-screen max-w-[calc(100vw-48px)] max-h-[calc(100vh-48px)]", justify: "between", size: "full" }, rest, { children: [_jsx(ScrollArea, { className: "h-screen max-h-[calc(100vh-48px)] w-screen max-w-[calc(100vw-48px)]", children: centerContent ? (_jsx(Stack, { align: "center", justify: "center", className: "min-h-full p-6", children: children })) : (_jsx(_Fragment, { children: children })) }), _jsx(Pin.Tr, { className: "p-5", children: _jsx(BtwModal.CloseButton, { "data-tesitd": closeButtonTestId, size: "intermediate", variant: "secondary" }) })] })));
}
const image = tv({
    base: ['block', 'max-w-full'],
    variants: {
        height: {
            auto: ['h-auto'],
            contain: [
                'w-auto',
                'h-auto',
                'object-contain',
                // Screen height minus overlay and dialog padding
                'max-h-[calc(100vh-96px)]',
            ],
        },
    },
    defaultVariants: {
        height: 'auto',
    },
});
function Image(props) {
    const { className, height } = props, rest = __rest(props, ["className", "height"]);
    return _jsx(ImagePrimitive, Object.assign({ className: image({ className, height }) }, rest));
}
export const FullscreenModal = Object.assign(FullscreenModalBase, {
    Content,
    Image,
    Root: BtwModal.Root,
    Trigger: BtwModal.Trigger,
});
