import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CategorySlug } from '../types';
export const date = {
    slug: 'date',
    title: 'Date Test Survey Template | Ballpark',
    metaDescription: "Use our date template to collect consistent data around participant's date of birth or other important dates.",
    heading: 'Date template',
    subheading: 'A simple way to collect dates in your research',
    categories: [CategorySlug.Basics, CategorySlug.Research],
    body: _jsx(DateBody, {}),
    templateUUID: 'ut_6e1749c3-d661-41f8-88c4-24552957ae1e',
    recordLink: 'https://app.ballparkhq.com/record/6e1749c3-d661-41f8-88c4-24552957ae1e',
    accordionTitle: 'Date template FAQ',
    accordionItems: [
        {
            id: 1,
            question: 'How do I get a list of all the data gathered from my survey or test?',
            answerParagraphs: [
                'You can download all of the responses, including the date as a CSV allowing you to open it in Google Sheets or Excel.',
            ],
        },
        {
            id: 2,
            question: 'What other formats of data can I collect?',
            answerParagraphs: [
                'You can use our email step to gather email address as well as open-ended questions, multiple choice, rating scale, yes or no and more. ',
            ],
        },
    ],
};
function DateBody() {
    return (_jsxs("div", { children: [_jsx("p", { children: "Whether it\u2019s asking a participant\u2019s date of birth or the date they did an action, our date step ensures the data entered is consistent so you\u2019re receiving reliable insights." }), _jsx("h3", { children: "3 great ways to use our date template" }), _jsxs("ul", { children: [_jsx("li", { children: "Gather the date of birth of the participant" }), _jsx("li", { children: "Ask what day someone visited your site" }), _jsx("li", { children: "Determine when someone bought your product" })] })] }));
}
