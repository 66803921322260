import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CategorySlug } from '../types';
export const customerExitSurvey = {
    slug: 'customer-exit-survey',
    title: 'Customer Exit Survey Template | Ballpark',
    metaDescription: 'Speaking to users who have churned can help provide insight into product improvements. Use our customer exit survey template to start gathering feedback.',
    heading: 'Customer exit survey template',
    subheading: 'Find out why customers stopped using a product',
    categories: [CategorySlug.CustomerSuccess, CategorySlug.ProductFeedback],
    body: _jsx(CustomerExitSurveyBody, {}),
    templateUUID: 'ut_dabb800a-89d3-428a-a69f-8d2ec816dd0a',
    recordLink: 'https://app.ballparkhq.com/record/dabb800a-89d3-428a-a69f-8d2ec816dd0a',
    accordionTitle: 'Customer exit survey FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'Why do customers churn?',
            answerParagraphs: [
                'Customers can churn for various reasons. Churners may realize that they’re not fully using the product, or feel that it’s not worth it, or maybe they’re not able to afford it anymore. Perhaps they don’t need it anymore, need it yet, or maybe they never really needed it to begin with.',
                'Sometimes the issues boil down to attracting the wrong customers (flawed marketing), other times the product is suitable but terrible customer support, bugs, and/or user experience issues can make customers want to unsubscribe.',
                'Another reason is that their payment method expired or declined without them realizing, or if it’s a B2B product the business might not be willing to budget for it anymore.',
            ],
        },
        {
            id: 2,
            question: 'When should a customer exit survey be used?',
            answerParagraphs: [
                'Asking at the moment of churn (while the thoughts and feelings are still fresh) can yield higher quality insights.',
                'Plus, with an appropriate response (e.g. a link to an FAQ article that solves a problem they’re having), customers may even change their mind. That being said, the main goal is to listen and use gained insights to boost future retention.',
            ],
        },
    ],
};
function CustomerExitSurveyBody() {
    return (_jsxs("div", { children: [_jsx("p", { children: "Customer exit surveys (sometimes referred to as churn surveys) are used to find out why customers stopped using a product. Using this information, we can make changes to the product to prevent customers from churning in the future." }), _jsx("h3", { children: "3 great ways to use our customer exit survey template" }), _jsxs("ul", { children: [_jsx("li", { children: "Confirm why customers subscribed" }), _jsx("li", { children: "Learn why they stopped subscribing" }), _jsx("li", { children: "Learn how to reacquire their custom" })] })] }));
}
