import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { Icon as Svg } from '../../Icon';
export function StepTypeIcon({ bg, borderRadius = 'm', children, color, height = 42, width = 42, }) {
    return (_jsx(Icon, { "data-testid": "step-icon", borderRadius: borderRadius, bg: bg, color: color, width: width, height: height, children: _jsx(Svg, { viewBox: "0 0 42 42", width: width, height: height, children: children }) }));
}
const Icon = styled(Box) `
  display: flex;
  align-items: center;
  justify-content: center;
`;
