import { jsx as _jsx } from "react/jsx-runtime";
import { createMemoryHistory } from 'history';
import { find } from 'lodash-es';
import { createContext, useEffect, useState } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
export const BackLinkContext = createContext();
const PATH_TO_ENTRY_POINTS = {
    '/project/:id': ['/projects/:filter'],
    '/user-test/:id': ['/project/:id', '/projects/:filter'],
};
function getBackLink(shadowHistory) {
    if (!(shadowHistory.entries && shadowHistory.entries.length > 1)) {
        return {};
    }
    const entryPoints = find(PATH_TO_ENTRY_POINTS, (_, path) => matchPath(shadowHistory.location.pathname, { path, exact: true }));
    if (!entryPoints) {
        return {};
    }
    let i = shadowHistory.index - 1;
    while (i >= 0 &&
        // eslint-disable-next-line no-loop-func
        !entryPoints.some((entryPoint) => matchPath(shadowHistory.entries[i].pathname, {
            path: entryPoint,
            exact: true,
        }))) {
        i -= 1;
    }
    if (i === -1) {
        return {};
    }
    return { backLink: shadowHistory.entries[i] };
}
export function BackLinkProvider({ children }) {
    const history = useHistory();
    const [backLink, setBackLink] = useState({
        backLink: '/',
    });
    useEffect(() => {
        if (!history)
            return;
        // Replay navigation to memory history so we can inspect entries
        const shadowHistory = createMemoryHistory({
            initialEntries: [history.location],
        });
        const unlisten = history.listen((newLocation, action) => {
            if (action === 'PUSH') {
                shadowHistory.push(newLocation);
            }
            else if (action === 'REPLACE') {
                shadowHistory.replace(newLocation);
            }
            else {
                shadowHistory.goBack();
            }
        });
        shadowHistory.listen(() => {
            setBackLink(getBackLink(shadowHistory));
        });
        return unlisten;
    }, [history]);
    return (_jsx(BackLinkContext.Provider, { value: backLink, children: children }));
}
