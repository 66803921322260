import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { CLOSE_ICON } from '@marvelapp/ballpark-theme';
import CircleButton from '../CircleButton';
import Icon from '../Icon';
import { iconSizes, sizes } from './sizes';
function CloseButton(_a) {
    var { size } = _a, rest = __rest(_a, ["size"]);
    return (_jsx(CircleButton, Object.assign({ height: 40, kind: "ghost" }, sizes[size], rest, { children: _jsx(Icon, Object.assign({ display: "block", fill: "inherit", paths: CLOSE_ICON }, iconSizes[size])) })));
}
CloseButton.defaultProps = {
    size: 2,
    kind: 'ghost',
};
export default CloseButton;
