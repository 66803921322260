import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { BtwButton, BtwLink, Logo, Stack, } from '@marvelapp/ballpark-components';
import { CloseIcon } from '@marvelapp/ballpark-icons';
import { NavLinks } from './NavLinks';
import UserBlock from './UserBlock';
export function Sidebar() {
    const [isSidebarExtendedOnMobile, setIsSidebarExtendedOnMobile] = useState(false);
    const toggleSidebarOnMobile = (isExtended) => () => setIsSidebarExtendedOnMobile(isExtended);
    const collapsedClasses = isSidebarExtendedOnMobile
        ? 'translate-x-0'
        : '-translate-x-48';
    return (_jsxs("div", { "data-testid": "sidebar", children: [_jsx(BtwButton, { variant: "secondary", size: "sm", className: `fixed left-3 top-3 ${isSidebarExtendedOnMobile ? 'hidden' : 'block md:hidden'}`, onClick: toggleSidebarOnMobile(!isSidebarExtendedOnMobile), children: "Menu" }), _jsxs(Stack, { direction: "col", align: "start", justify: "between", 
                // TODO: {tailwind} Update the z-index here to use some sort of better defined system
                className: `fixed z-[500] h-screen w-48 bg-gray-50 transition-all duration-300 ease-in-out md:translate-x-0 ${collapsedClasses}`, children: [_jsxs(Stack, { className: "w-48 overflow-y-scroll px-4 pt-11", gap: "8", children: [_jsx(BtwButton, { standaloneIcon: _jsx(CloseIcon, {}), variant: "ghost", size: "sm", onClick: toggleSidebarOnMobile(!isSidebarExtendedOnMobile), className: "fixed right-3 top-3 md:hidden" }), _jsx(Stack, { direction: "row", className: "px-4 py-1", gap: "1.5", children: _jsx(BtwLink, { asChild: true, variant: "secondary", children: _jsx(RouterLink, { to: "/", children: _jsx(Logo, { width: "72" }) }) }) }), _jsx(NavLinks, {})] }), _jsx(UserBlock, {})] })] }));
}
