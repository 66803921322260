import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import { tv } from 'tailwind-variants';
import { AiTextIcon, CalendarIcon, CardSortingClosedIcon, CardSortingHybridIcon, CardSortingOpenIcon, ChecklistIcon, CompassIcon, CursorClickIcon, EmailIcon, FileIcon, FunnelIcon, HandIcon, HourglassIcon, ImageIcon, LogoutIcon, MoveIcon, NumberedListIcon, StarIcon, TargetIcon, TextMessageIcon, TextMessageQuestionIcon, ThumbsUpIcon, } from '@marvelapp/ballpark-icons';
import { FlatStepClass } from '@marvelapp/user-test-creator';
import { BtwText } from './BtwText';
import { FlatFixedStepClass, STEP_CATEGORIES } from './stepUtils';
const stepType = tv({
    base: [
        'flex',
        'min-h-0',
        'min-w-0',
        'font-body',
        'items-center',
        '[&>div>svg]:block',
    ],
    variants: {
        category: {
            default: ['text-gray-900'],
            Misc: ['text-orange-700'],
            Survey: ['text-green-700'],
            Usability: ['text-sky-700'],
            Marketing: ['text-purple-700'],
        },
        grayscale: {
            true: ['text-gray-900'],
            false: [],
        },
        size: {
            xs: [
                'gap-1.5',
                'font-medium',
                '[&>div>svg]:w-3',
                '[&>div>svg]:h-3',
                '[&>div>svg>path]:stroke-1',
            ],
            '13': [
                'gap-1.5',
                'font-semibold',
                '[&>div>svg]:w-3.5',
                '[&>div>svg]:h-3.5',
                '[&>div>svg>path]:stroke-[1.5px]',
            ],
        },
    },
    defaultVariants: {
        category: 'default',
        grayscale: false,
        size: 'xs',
    },
});
function StepTypeBase(props) {
    const { className, size = 'xs', type, grayscale } = props, rest = __rest(props, ["className", "size", "type", "grayscale"]);
    const category = STEP_CATEGORIES.find((category) => category.steps.includes(type));
    return (_jsxs("span", Object.assign({ className: stepType({
            category: (category === null || category === void 0 ? void 0 : category.title) || 'default',
            className,
            grayscale,
            size,
        }) }, rest, { children: [_jsx("div", { children: _jsx(Icon, { type: type }) }), _jsx(BtwText, { className: "flex-grow basis-0", size: size, truncate: true, variant: "current", children: _jsx(Title, { type: type }) })] })));
}
/**
 *
 * @param props
 * @returns StepDetails | null
 */
function getTypeDetails(type) {
    switch (type) {
        case FlatFixedStepClass.Welcome:
            return {
                icon: _jsx(HandIcon, {}),
                title: 'Welcome',
            };
        case FlatFixedStepClass.Exit:
            return {
                icon: _jsx(LogoutIcon, {}),
                title: 'Exit',
            };
        case FlatFixedStepClass.Screener:
            return {
                icon: _jsx(FunnelIcon, {}),
                title: 'Screener',
            };
        case FlatStepClass.Instruction:
            return {
                icon: _jsx(TextMessageIcon, {}),
                title: 'Instruction',
            };
        case FlatStepClass.YesOrNo:
            return {
                icon: _jsx(ThumbsUpIcon, {}),
                title: 'Yes or No',
            };
        case FlatStepClass.QuestionText:
            return {
                icon: _jsx(TextMessageQuestionIcon, {}),
                title: 'Question',
            };
        case FlatStepClass.MultipleChoice:
            return {
                icon: _jsx(ChecklistIcon, {}),
                title: 'Multiple choice',
            };
        case FlatStepClass.RatingScale:
            return {
                icon: _jsx(StarIcon, {}),
                title: 'Rating scale',
            };
        case FlatStepClass.PreferenceTest:
            return {
                icon: _jsx(ImageIcon, {}),
                title: 'Preference test',
            };
        case FlatStepClass.QuestionEmail:
            return {
                icon: _jsx(EmailIcon, {}),
                title: 'Email',
            };
        case FlatStepClass.QuestionNumerical:
            return {
                icon: _jsx(NumberedListIcon, {}),
                title: 'Number',
            };
        case FlatStepClass.QuestionDate:
            return {
                icon: _jsx(CalendarIcon, {}),
                title: 'Date',
            };
        case FlatStepClass.WebsiteTask:
            return {
                icon: _jsx(CompassIcon, {}),
                title: 'Website task',
            };
        case FlatStepClass.FiveSecondTest:
            return {
                icon: _jsx(HourglassIcon, {}),
                title: 'Five second test',
            };
        case FlatStepClass.FirstClickStep:
            return {
                icon: _jsx(CursorClickIcon, {}),
                title: 'First click',
            };
        case FlatStepClass.GoalBasedStep:
            return {
                icon: _jsx(TargetIcon, {}),
                title: 'Prototype task',
            };
        case FlatStepClass.FreeRoamStep:
            return {
                icon: _jsx(MoveIcon, {}),
                title: 'Free roam',
            };
        case FlatStepClass.TaglineCopyTest:
            return {
                icon: _jsx(AiTextIcon, {}),
                title: 'Tagline copy test',
            };
        case FlatStepClass.LegalStep:
            return {
                icon: _jsx(FileIcon, {}),
                title: 'Legal',
            };
        case FlatStepClass.CardSortingOpen:
            return {
                icon: _jsx(CardSortingOpenIcon, {}),
                title: 'Open card sort',
            };
        case FlatStepClass.CardSortingClosed:
            return {
                icon: _jsx(CardSortingClosedIcon, {}),
                title: 'Closed card sort',
            };
        case FlatStepClass.CardSortingHybrid:
            return {
                icon: _jsx(CardSortingHybridIcon, {}),
                title: 'Hybrid card sort',
            };
        default:
            return null;
    }
}
function Icon(props) {
    const { type } = props;
    const details = getTypeDetails(type);
    if (!details) {
        return null;
    }
    return _jsx(_Fragment, { children: details.icon });
}
function Title(props) {
    const { type } = props;
    const details = getTypeDetails(type);
    if (!details) {
        return null;
    }
    return _jsx(_Fragment, { children: details.title });
}
const StepType = Object.assign(StepTypeBase, {
    Icon,
    Title,
});
export { StepType };
