import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import * as TabsPrimitive from '@radix-ui/react-tabs';
import * as React from 'react';
import { tv } from 'tailwind-variants';
import { BtwButton } from './BtwButton';
/**
 * List
 */
const list = tv({
    base: [
        // Layout
        'gap-1',
        'inline-flex',
        'focus-visible:outline-none',
        'focus-visible:outline-gray-300',
    ],
    variants: {
        appearance: {
            default: [],
            boxed: ['bg-gray-100', 'border-600/10', 'border', 'p-1', 'rounded-lg'],
        },
    },
    defaultVariants: {
        appearance: 'default',
    },
});
const List = React.forwardRef(function Item(props, forwardedRef) {
    const { className, appearance } = props, rest = __rest(props, ["className", "appearance"]);
    return (_jsx(TabsPrimitive.List, Object.assign({ className: list({ appearance, className }), ref: forwardedRef }, rest)));
});
/**
 * Trigger
 */
const trigger = tv({
    base: [
        'font-medium',
        'active:!translate-y-px',
        'disabled:active:!translate-y-0',
    ],
    variants: {
        variant: {
            base: [],
            primary: [],
            secondary: [
                // Selected
                'aria-[selected=true]:!bg-white',
                'aria-[selected=true]:!ring-gray-600/20',
                'aria-[selected=true]:text-gray-900',
                'aria-[selected=true]:hover:!ring-gray-600/30',
                // Deselected
                'aria-[selected=false]:shadow-none',
                'aria-[selected=false]:ring-transparent',
                'aria-[selected=false]:text-gray-600/90',
                'aria-[selected=false]:bg-transparent',
                'aria-[selected=false]:hover:bg-gray-600/10',
            ],
            ghost: [
                // Deselected
                'aria-[selected=false]:text-gray-600/90',
                'aria-[selected=false]:hover:text-gray-900',
                'aria-[selected=false]:disabled:bg-transparent',
                'aria-[selected=false]:disabled:text-gray-600/50',
            ],
            danger: [],
            legacyBlue: [],
            amber: [],
        },
    },
    defaultVariants: {
        variant: 'secondary',
    },
});
const Trigger = React.forwardRef(function Item(props, forwardedRef) {
    const { className, value, variant } = props, rest = __rest(props, ["className", "value", "variant"]);
    return (_jsx(TabsPrimitive.Trigger, { asChild: true, value: value, ref: forwardedRef, children: _jsx(BtwButton, Object.assign({ variant: variant, className: trigger({ className, variant }) }, rest)) }));
});
/**
 * Content
 */
const content = tv({
    base: [
        'rounded-lg',
        'focus-visible:outline-none',
        'focus-visible:outline-gray-300',
    ],
    variants: {},
    defaultVariants: {},
});
const Content = React.forwardRef(function Item(props, forwardedRef) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (_jsx(TabsPrimitive.Content, Object.assign({ className: content({ className }), ref: forwardedRef }, rest)));
});
const BtwTabs = {
    Root: TabsPrimitive.Root,
    List,
    Trigger,
    Content,
};
export { BtwTabs };
