import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as RadixSelect from '@radix-ui/react-select';
import * as React from 'react';
import { tv } from 'tailwind-variants';
import { CheckIcon, ChevronBottomIcon } from '@marvelapp/ballpark-icons';
import { BtwButton } from './BtwButton';
import { basePopoverVariants } from './BtwPopover';
import { BtwText } from './BtwText';
import { cn } from './utils';
function Trigger(props) {
    const { children, className, hasPlaceholder, isTruncated, leadingIcon, size = 'base', testId = 'select-trigger', variant, width, disabled, } = props;
    return (_jsx(RadixSelect.Trigger, { "data-testid": testId, asChild: true, children: _jsx(BtwButton, { size: size, className: cn('justify-between', {
                'min-w-0 flex-1': isTruncated,
                'font-normal text-gray-500': hasPlaceholder,
            }, className), disabled: disabled, leadingIcon: leadingIcon, variant: variant, width: width, trailingIcon: _jsx(RadixSelect.Icon, { asChild: true, children: _jsx(ChevronBottomIcon, {}) }), children: _jsx(RadixSelect.Value, { asChild: true, children: _jsx("span", { className: "flex-1 truncate text-left", children: children }) }) }) }));
}
/**
 * Content
 */
const content = tv({
    extend: basePopoverVariants,
    base: [
        'h-max',
        'relative',
        'rounded-xl',
        'overflow-y-scroll',
        'min-w-[var(--radix-select-trigger-width)]',
        'max-w-[var(--radix-select-trigger-width)]',
        'max-h-[var(--radix-select-content-available-height)]',
    ],
    variants: {
        size: {
            base: [
                'rounded-xl',
                '[&_.select-viewport]:p-2',
                '[&_.select-viewport]:gap-1',
            ],
        },
    },
    defaultVariants: {
        size: 'base',
    },
});
function Content(props) {
    const { children, className, testId = 'select-portal', size } = props;
    return (_jsx(RadixSelect.Portal, { "data-testid": testId, className: "z-[100000]", children: _jsxs(RadixSelect.Content, { onCloseAutoFocus: (event) => event.preventDefault(), "data-testid": "select-content", position: "popper", className: content({ className, size }), collisionPadding: { top: 70, bottom: 20, left: 16, right: 16 }, sideOffset: 8, children: [_jsx(RadixSelect.ScrollUpButton, {}), _jsx(RadixSelect.Viewport, { className: "select-viewport flex flex-col", children: children }), _jsx(RadixSelect.ScrollDownButton, {})] }) }));
}
function Item(props) {
    const { className, children, value, testId = 'select-option', placeholder, disabled, } = props;
    return (_jsxs(RadixSelect.Item, { placeholder: placeholder, className: cn(
        // Reset
        'appearance-none', 'bg-transparent', 'border-none', 'cursor-pointer', 'outline-none', 
        // Layout
        'pl-3', 'pt-2', 'pr-2', 'pb-2', 'flex', 'items-center', 'justify-between', 'gap-3', 
        // Base styles
        'bg-white', 'rounded-md', 
        // Transition
        'duration-300', 'ease-smooth', 'transition-combined', 
        // Hover styles
        'data-[highlighted]:bg-gray-400/25', 'data-[highlighted]:outline-none', 
        // Typography
        'text-gray-900', 'text-sm', {
            // TODO: see if there's a data attribute we can use
            'cursor-not-allowed text-gray-400': disabled,
        }, className), value: value, "data-testid": testId, disabled: disabled, children: [_jsx(RadixSelect.ItemText, { asChild: true, children: _jsx(BtwText, { variant: "primary", size: "sm", weight: "medium", className: "flex-1 truncate", children: children }) }), _jsx(RadixSelect.ItemIndicator, { children: _jsx(CheckIcon, { className: "h-5 w-5" }) })] }));
}
function Separator(props) {
    const { className } = props;
    return (_jsx(RadixSelect.Separator, { className: cn(
        // Using a pseudo-element to absolutely position the actual 1px separator
        'before:w-[calc(100%_+_20px)]', // 100% + padding from the parent container
        'before:-left-2.5', // Offsetting the separator by the same amount as padding
        'before:absolute', 'before:content-[""]', 'before:block', 'before:border-b', 'before:border-gray-600/20', 'flex-1', 'my-1', 'relative', className) }));
}
export const Select = {
    Content,
    Item,
    Root: RadixSelect.Root,
    Separator,
    Trigger,
};
