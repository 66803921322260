import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import { CheckIcon, FaceHappyIcon, FaceNeutralIcon, FaceSadIcon, WarningIcon, } from '@marvelapp/ballpark-icons';
import { BtwHeading } from './BtwHeading';
import { BtwLink } from './BtwLink';
import { BtwMessage } from './BtwMessage';
import { BtwText } from './BtwText';
import { Logo } from './Logo';
import { Stack } from './Stack';
function Container(props) {
    const { children, noFooter, maxWidth = 384 } = props;
    return (_jsxs(Stack, { align: "center", className: "min-h-screen px-6 py-10 sm:py-12", gap: { initial: '12', sm: '16' }, justify: "between", width: "full", children: [_jsx(BtwLink, { href: "/", className: "[&>svg]:h-7 [&>svg]:w-auto", underline: false, variant: "secondary", children: _jsx(Logo, {}) }), _jsx(Stack, { align: "center", gap: { initial: '12', sm: '16' }, width: "full", style: { maxWidth }, children: children }), noFooter ? _jsx("div", {}) : _jsx(Footer, {})] }));
}
function Header(props) {
    const { description, descriptionMaxWidth, heading, headingMaxWidth, headingTestId, } = props;
    return (_jsxs(Stack, { align: "center", gap: "1.5", children: [_jsx(BtwHeading, { asChild: true, "data-testid": headingTestId, size: "xl", style: { maxWidth: headingMaxWidth }, children: _jsx("h1", { children: heading }) }), description && (_jsx(BtwText, { size: "sm", leading: "relaxed", style: { maxWidth: descriptionMaxWidth }, children: description }))] }));
}
function Body(props) {
    return _jsx("div", Object.assign({ className: "flex w-full max-w-sm flex-col gap-8" }, props));
}
function Form(props) {
    return _jsx("form", Object.assign({ className: "flex w-full max-w-sm flex-col gap-6" }, props));
}
function Footer(props) {
    const { children } = props;
    return (_jsx(BtwText, { align: "center", className: "w-full max-w-sm", leading: "relaxed", size: "sm", children: children || (_jsxs(_Fragment, { children: ["By proceeding you acknowledge that you have read, understood and agree to our ", _jsx("br", { className: "hidden max-sm:block" }), _jsx(BtwLink, { href: "/terms", variant: "secondary", target: "_blank", children: "Terms of Service" }), "."] })) }));
}
function ErrorMessage(props) {
    const { children } = props;
    return (_jsx(BtwMessage, { leadingIcon: _jsx(WarningIcon, {}), variant: "red", children: children }));
}
function SuccessMessage(props) {
    const { children } = props;
    return (_jsx(BtwMessage, { leadingIcon: _jsx(CheckIcon, {}), variant: "green", children: children }));
}
function Message(props) {
    const { children, icon } = props, rest = __rest(props, ["children", "icon"]);
    return (_jsxs(Stack, { align: "center", width: "full", gap: "6", children: [_jsxs(Stack, { align: "center", width: "full", gap: "4", children: [icon && (_jsx(Stack, { align: "center", className: "text-gray-900 [&>svg>path]:stroke-[3px] [&>svg]:h-12 [&>svg]:w-12", width: "full", children: icon })), _jsx(FullScreenPage.Header, Object.assign({}, rest))] }), _jsx(Stack, { align: "center", width: "full", children: children })] }));
}
function PasswordStrength(props) {
    const { password } = props;
    const strength = getPasswordStrength(props.password);
    if (!password) {
        return null;
    }
    return (_jsxs(BtwText, { className: "flex items-center gap-1 [&>svg]:h-3.5 [&>svg]:w-3.5", size: "xs", variant: strength === null || strength === void 0 ? void 0 : strength.variant, weight: "medium", children: [strength === null || strength === void 0 ? void 0 : strength.icon, " ", strength === null || strength === void 0 ? void 0 : strength.text] }));
}
const getPasswordStrength = (password) => {
    if (!password) {
        return null;
    }
    let strength = 0;
    // Check password length
    if (password.length > 8) {
        strength += 1;
    }
    // Check for mixed case
    if (password.match('.*[a-z].*') && password.match('.*[A-Z].*')) {
        strength += 1;
    }
    // Check for numbers
    if (password.match('.*\\d.*')) {
        strength += 1;
    }
    // Check for special characters
    if (password.match('.*[^a-zA-Z\\d].*')) {
        strength += 1;
    }
    if (strength < 2) {
        return {
            icon: _jsx(FaceSadIcon, {}),
            variant: 'red',
            text: 'Password weak',
        };
    }
    if (strength === 2) {
        return {
            icon: _jsx(FaceNeutralIcon, {}),
            variant: 'yellow',
            text: 'Password average',
        };
    }
    if (strength === 3) {
        return {
            icon: _jsx(FaceNeutralIcon, {}),
            variant: 'green',
            text: 'Password strong',
        };
    }
    if (strength > 3) {
        return {
            icon: _jsx(FaceHappyIcon, {}),
            variant: 'green',
            text: 'Password excellent',
        };
    }
    return null;
};
const FullScreenPage = {
    Body,
    Container,
    ErrorMessage,
    Form,
    Header,
    Message,
    SuccessMessage,
    PasswordStrength,
};
export { FullScreenPage };
