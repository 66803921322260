import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import * as Tooltip from './Tooltip';
export function IconWithTooltip(_a) {
    var { children, content } = _a, boxProps = __rest(_a, ["children", "content"]);
    return (_jsx(IconWrap, Object.assign({ "data-testid": "icon-with-toolti[" }, boxProps, { children: _jsxs(Tooltip.Root, { children: [_jsx(Tooltip.Trigger, { children: children }), _jsxs(Tooltip.Content, { side: "top", avoidCollisions: false, pointerEvents: "none", children: [_jsx(Tooltip.Arrow, { offset: 8, width: 10, height: 5 }), content] })] }) })));
}
const IconWrap = styled(Box) `
  &:last-child {
    padding-right: ${(props) => props.lastChildPr !== undefined ? props.lastChildPr : props.pr};
  }
`;
