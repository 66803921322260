import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CategorySlug } from '../types';
export const taglineTest = {
    slug: 'tagline-test',
    title: 'Marketing Tagline Test Template | Ballpark',
    metaDescription: "A memorable tagline will catch your customer's attention and convey key product messages. Use our tagline test template to get feedback on your ad taglines.",
    heading: 'Tagline test template',
    subheading: 'Discover what messaging drives better conversion',
    categories: [CategorySlug.Marketing, CategorySlug.BrandAndCopyTesting],
    body: _jsx(TaglineTestBody, {}),
    templateUUID: 'ut_a3c28506-0056-4c41-911c-8356e8fdb531',
    recordLink: 'https://app.ballparkhq.com/record/a3c28506-0056-4c41-911c-8356e8fdb531',
    accordionTitle: 'Tagline test template FAQs ',
    accordionItems: [
        {
            id: 1,
            question: 'What are some example questions I could use in my test?',
            answerParagraphs: [
                'You can ask a range of questions to help gather insights for your campaign, including why did you pick your preferred tagline? How would improve the messaging? Is there any information you feel is missing?',
            ],
        },
        {
            id: 2,
            question: 'What type of feedback can I gather in a tagline test?',
            answerParagraphs: [
                'As well as having data on which tagline is preferred you can gather insights from open ended questions, test recall with a 5 second test or see a heatmap of a user engaging with an asset that includes your tagline.',
            ],
        },
        {
            id: 3,
            question: 'What other marketing testing can I do in Ballpark?',
            answerParagraphs: [
                'You can test other campaign assets such as emails, copy, digital ads and landing pages.',
            ],
        },
    ],
};
function TaglineTestBody() {
    return (_jsxs("div", { children: [_jsxs("p", { children: ["Taglines are used within marketing materials to convey key product messages to customers which drive action. Taglines can include important information on product funationality, benefits or actions that a user needs to take.", ' '] }), _jsx("p", { children: "Tagline testing ensures your message is memorable and performs well against your business goals. Our tagline template includes the tagline step where you can ask users to pick their preferred tagline as well as ask follow up questions to gain additional feedback and insight." }), _jsx("p", { children: "5 types of taglines you could test:" }), _jsxs("ul", { children: [_jsx("li", { children: "Headline for a landing page" }), _jsx("li", { children: "Email subject lines" }), _jsx("li", { children: "Social posts" }), _jsx("li", { children: "Digital ads for search or display" }), _jsx("li", { children: "Print advertisements " })] })] }));
}
