import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import * as React from 'react';
import { cn } from './utils';
function Image(props) {
    const { alt, className, disabled, finishedLoading, hasTransition = true, src, src2x } = props, rest = __rest(props, ["alt", "className", "disabled", "finishedLoading", "hasTransition", "src", "src2x"]);
    const [imageLoaded, setImageLoaded] = useState(false);
    useEffect(() => {
        if (!src || !hasTransition) {
            return;
        }
        let isCancelled = false;
        loadImage(src)
            .then(() => {
            if (!isCancelled) {
                setImageLoaded(true);
            }
        })
            .catch(() => {
            if (finishedLoading) {
                finishedLoading({ success: false });
            }
        });
        return () => {
            isCancelled = true;
        };
    });
    return (_jsx("img", Object.assign({ alt: alt, draggable: false, src: src, srcSet: src2x ? `${src2x} 2x` : undefined, className: cn('ease-smooth', 'duration-300', 'transition-opacity', {
            'opacity-50 grayscale': disabled,
            'opacity-0': hasTransition && !imageLoaded,
            'opacity-100': hasTransition && imageLoaded,
        }, className) }, rest)));
}
export function loadImage(url) {
    return new Promise((resolve, reject) => {
        const img = new window.Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = url;
    });
}
export { Image };
