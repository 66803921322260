import { jsx as _jsx } from "react/jsx-runtime";
import { initialize } from 'ldclient-js';
import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';
import { PlanLabel, devOverrides, isDebugMode } from '@marvelapp/core';
import config from '../../config';
let ldclient;
let ldclientReady;
let fakeLdclient;
class FakeLaunchDarklyClient {
    constructor() {
        this.flags = new Proxy({}, {
            get() {
                return false;
            },
        });
    }
    allFlags() {
        return this.flags;
    }
}
export const getLaunchDarklyClient = ({ company, email, pk, role }, enableForAll) => {
    return new Promise((resolve, reject) => {
        var _a, _b, _c, _d;
        if (role === 'ANONYMOUS' ||
            (((_b = (_a = company === null || company === void 0 ? void 0 : company.billing) === null || _a === void 0 ? void 0 : _a.plan) === null || _b === void 0 ? void 0 : _b.label) === PlanLabel.Free && !enableForAll)) {
            // Feature flagging is disabled for anonymous and free users in order to
            // reduce the LD client-side quota usage
            if (!fakeLdclient) {
                fakeLdclient = new FakeLaunchDarklyClient();
            }
            return resolve(fakeLdclient);
        }
        const user = {
            key: pk,
            email,
            custom: {
                companyId: company === null || company === void 0 ? void 0 : company.pk,
                planLabel: (_d = (_c = company === null || company === void 0 ? void 0 : company.billing) === null || _c === void 0 ? void 0 : _c.plan) === null || _d === void 0 ? void 0 : _d.label,
            },
        };
        if (ldclient) {
            ldclientReady.then(() => {
                resolve(ldclient);
            });
            return;
        }
        ldclient = initialize(config.launchDarkly.apiKey, user);
        ldclientReady = new Promise((resolveClientReady) => {
            ldclient.on('ready', resolveClientReady);
        }).then(() => {
            resolve(ldclient);
        });
    });
};
const launchDarklyContext = createContext();
export const LaunchDarklyProvider = ({ client, children }) => {
    const { Provider } = launchDarklyContext;
    return _jsx(Provider, { value: client, children: children });
};
LaunchDarklyProvider.propTypes = {
    client: PropTypes.object.isRequired,
    children: PropTypes.any,
};
export const getFlags = (client) => {
    const flags = client.allFlags();
    if (isDebugMode() && !(client instanceof FakeLaunchDarklyClient)) {
        const flagOverrides = devOverrides.featureFlags.get();
        Object.assign(flags, flagOverrides);
    }
    return flags;
};
export const useFlags = () => {
    const client = useContext(launchDarklyContext);
    return getFlags(client);
};
