import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { Loader as LoaderPrimitive } from './Loader';
const ICON_CLASSNAME = 'inner-icon';
/**
 * To used when you have to conditionally render a child with an icon next to it
 * and also show a loading state.
 */
function ChildrenWithIconBase(props) {
    const { isLoading, standaloneIcon, leadingIcon, trailingIcon, children } = props;
    if (isLoading && standaloneIcon) {
        return _jsx(Loader, {});
    }
    if (!isLoading && standaloneIcon) {
        return _jsx(Icon, { children: standaloneIcon });
    }
    if (isLoading) {
        return (_jsxs(_Fragment, { children: [_jsx(Loader, {}), children] }));
    }
    if (leadingIcon) {
        return (_jsxs(_Fragment, { children: [_jsx(Icon, { children: leadingIcon }), children] }));
    }
    if (trailingIcon) {
        return (_jsxs(_Fragment, { children: [children, _jsx(Icon, { children: trailingIcon })] }));
    }
    return _jsx(_Fragment, { children: children });
}
function Icon(props) {
    return (_jsx("span", { "data-testid": props.testId, className: ICON_CLASSNAME, children: props.children }));
}
function Loader() {
    return (_jsx(Icon, { children: _jsx(LoaderPrimitive, {}) }));
}
const ChildrenWithIcon = Object.assign(ChildrenWithIconBase, {
    Icon,
    Loader,
});
export { ChildrenWithIcon, ICON_CLASSNAME };
