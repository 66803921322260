import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Svg } from './styles';
// TODO: [components consolidation] - Remove this component
// Currently this component is used in other components within
// marvel-ui. We can't yet use the Svg component from ballpark-components
// because it would create a cyclical dependency.
// Once all of the components depending on this Icon component within marvel-ui
// are removed, we can remove this component
/** @param {any} */
function Icon(_a) {
    var { paths, children } = _a, props = __rest(_a, ["paths", "children"]);
    return (_jsx(Svg, Object.assign({}, props, { children: paths ? paths.map((path) => _jsx("path", { d: path }, path)) : children })));
}
/** @type {any} */
Icon.defaultProps = {
    fill: 'currentColor',
    height: 24,
    viewBox: '0 0 24 24',
    width: 24,
};
export default Icon;
