import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import theme, { GHOST_WARNING_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box, PinTL } from '@marvelapp/ui';
import { Icon as Svg } from './Icon';
const kinds = {
    warning: {
        bg: 'blackA10',
        color: theme.colors.dark.amber11, // Need the dark theme color specifially in this case
    },
    default: {
        bg: 'blackA10',
        color: 'whiteA12',
    },
    'recovered-connection': {
        color: theme.colors.dark.grass11, // Need the dark theme color specifially in this case
    },
    external: {
        border: '1px solid',
        borderColor: 'blue6',
        bg: 'blue2',
        color: 'blue12',
    },
    amber: {
        bg: 'amber2',
        color: 'slate12',
    },
    amberDark: {
        bg: 'amber3',
        color: 'slate12',
    },
    success: {
        border: '1px solid',
        borderColor: 'grass6',
        bg: 'grass2',
        color: 'blue12',
    },
    error: {
        border: '1px solid',
        borderColor: 'red6',
        bg: 'red4',
        color: 'red12',
    },
    light: {
        bg: 'whiteA12',
        border: '1px solid',
        borderColor: 'slate6',
        boxShadow: 'button.secondary',
        color: 'slate12',
    },
};
export function InfoBlock(_a) {
    var { children, fontSize = '14px', kind = 'default', maxWidth = 340, minWidth, hasIcon = true, width = 'auto' } = _a, rest = __rest(_a, ["children", "fontSize", "kind", "maxWidth", "minWidth", "hasIcon", "width"]);
    return (_jsxs(Message, Object.assign({}, kinds[kind], { "data-testid": "info-block", maxWidth: maxWidth, minWidth: minWidth, paddingLeft: hasIcon ? 66 : 'm', fontSize: fontSize, width: width }, rest, { children: [hasIcon && (_jsx(IconWrapper, { children: _jsx(Icon, { kind: kind }) })), children] })));
}
function Icon({ kind }) {
    switch (kind) {
        case 'warning':
            return (_jsx(Svg, { height: 18, viewBox: "0 0 20 18", width: 20, paths: GHOST_WARNING_ICON }));
        default:
            return null;
    }
}
const IconWrapper = styled(PinTL) `
  width: 66px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Message = styled(Box).attrs({
    paddingRight: 'm',
    paddingY: 's',
    borderRadius: 'm',
    lineHeight: 'sub',
}) `
  position: relative;
  align-items: center;
  display: flex;
`;
