import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CategorySlug } from '../types';
export const pricingPageTest = {
    slug: 'pricing-page-test',
    title: 'Pricing Page Test Template | Ballpark',
    metaDescription: 'Find the right pricing and packaging for your product with this pricing page test template.',
    heading: 'Pricing page test template',
    subheading: 'Find the right pricing and packaging for your product',
    categories: [CategorySlug.PrototypeTesting, CategorySlug.DesignAndProduct],
    body: _jsx(PricingPageTestBody, {}),
    templateUUID: 'ut_452c5cb2-2705-40e9-986e-ef0970781166',
    recordLink: 'https://app.ballparkhq.com/record/452c5cb2-2705-40e9-986e-ef0970781166',
    accordionTitle: 'Pricing page test template FAQ',
    accordionItems: [
        {
            id: 1,
            question: 'How do I add my Figma prototype to this template?',
            answerParagraphs: ['How do I add my Figma prototype to this template?'],
        },
        {
            id: 2,
            question: 'How can I A/B test my pricing page using Ballpark?',
            answerParagraphs: [
                'Simple, after using this template, head to your project dashboard and duplicate it. Then change the designs, prototypes or any other images used and send it out to another group of people. Once you have a similar amount of responses, you can compare the data in the summary section of your project to the other one you sent out.',
            ],
        },
        {
            id: 3,
            question: 'Give me some ideas I can try!',
            answerParagraphs: [
                'Ok! So there are unlimited things you can try of course, but the most common are:',
                _jsx(PricingPageTestAccordionList, {}),
            ],
        },
    ],
};
function PricingPageTestBody() {
    return (_jsxs("div", { children: [_jsx("p", { children: "Pricing and packaging your digital product is one of the most challenging aspects of creating any SaaS or consumer-facing business. But of course, it\u2019s extremely challenging to get right." }), _jsx("p", { children: "You\u2019ll often find that changing pricing isn\u2019t trivial - the technical and design work involved can sometimes take weeks and months, so you want to make sure you\u2019re on the right track before you start." }), _jsx("p", { children: "Multiple factors attract users to a certain plan on your pricing page, including the design decisions you make. Whether you show annual or monthly pricing first, put your cheapest plan on the far left or right of the screen or display your best features or every feature. The list goes on and on." }), _jsx("p", { children: "And remember, pricing is never static, it should constantly evolve with your product and customers." }), _jsx("p", { children: "This template gives you several pre-made questions and steps to inspire your pricing page research. If you\u2019re looking to find out the price your customers (or potential customers) are willing to pay, check out our price sensitivity template." }), _jsx("h3", { children: "6 great ways to use our pricing page test template" }), _jsxs("ul", { children: [_jsx("li", { children: "Understand the best layout and design of your pricing page" }), _jsx("li", { children: "Test different call-to-actions and copy variations" }), _jsx("li", { children: "Discover whether you\u2019re more successful to be benefit or feature-led" }), _jsx("li", { children: "Quickly test the difference between usage-based or seat-based pricing" }), _jsx("li", { children: "A/B Test your pricing page designs" }), _jsx("li", { children: "Test your pricing in different markets" })] })] }));
}
function PricingPageTestAccordionList() {
    return (_jsxs("ul", { children: [_jsx("li", { children: "Swap the order of your plans, try the most expensive on the left" }), _jsx("li", { children: "Only show the most valuable features on each plan and put everything else in a table below the fold" }), _jsx("li", { children: "Show annual price with a discount as the default price (divided by 12)" }), _jsx("li", { children: "Change the messaging to drive the problem your product is solving" }), _jsx("li", { children: "Test whether a free trial or money-back guarantee performs better" }), _jsx("li", { children: "Test whether fixed pricing or usage-based pricing performs better" }), _jsx("li", { children: "Test whether one or multiple plans perform better - does your buyer prefer the kitchen sink or simplicity?" })] }));
}
