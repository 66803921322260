import { useCallback, useRef } from 'react';
import { createPaginationUpdateQuery } from '@marvelapp/ballpark-application';
export function useInfiniteQuery({ data, fetchMore, getDataConnection, isFetching, }) {
    var _a;
    const fetchingCursorRef = useRef(null);
    const connection = getDataConnection(data);
    const fetchNextPage = useCallback(() => {
        if (!connection)
            return;
        const { pageInfo } = connection;
        const { endCursor } = pageInfo;
        if (isFetching) {
            return;
        }
        fetchingCursorRef.current = endCursor;
        fetchMore({
            variables: {
                cursor: endCursor,
            },
            updateQuery: createPaginationUpdateQuery(getDataConnection),
        });
    }, [connection, fetchMore, getDataConnection, isFetching]);
    return {
        fetchNextPage,
        hasNextPage: (_a = connection === null || connection === void 0 ? void 0 : connection.pageInfo.hasNextPage) !== null && _a !== void 0 ? _a : false,
    };
}
