import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { defaultColumnSizing, flexRender, } from '@tanstack/react-table';
import { useCallback, useLayoutEffect, useRef, useState, } from 'react';
import * as React from 'react';
import { ArrowBottomIcon, ArrowTopIcon, SortIcon, } from '@marvelapp/ballpark-icons';
import { Loader } from '@marvelapp/ui';
import { BtwLink } from '../BtwLink';
import { BtwSkeleton } from '../BtwSkeleton';
import { BtwText } from '../BtwText';
import { Stack } from '../Stack';
import { cn } from '../utils';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from './BaseTable';
export function DataTable({ table, getRows = () => table.getRowModel().rows, paddingTop = 0, paddingBottom = 0, withStickyHeader, loadingStatus = 'none', isRowHighlighted, adjustColumnWidths = false, renderRowAttrs, renderNoItems = () => null, rowTestIdPrefix = 'response', }) {
    const rows = getRows();
    const hasTableLoaded = loadingStatus !== 'loading';
    const { thRefs, thWidths } = useOptimalColumnWidths({
        adjustColumnWidths,
        hasTableLoaded,
    });
    const renderBody = useCallback(() => {
        if (loadingStatus === 'none' && rows.length === 0) {
            return renderNoItems();
        }
        return (_jsxs(_Fragment, { children: [loadingStatus === 'reloading' && (_jsx("tr", { children: _jsx("td", { colSpan: rows.length, children: _jsx(Stack, { align: "center", justify: "center", 
                            // Doing some funky calcs here, but it's basically doing 100vh/vw excluding
                            // the paddings, borders and other elements on the page.
                            className: "absolute left-0 top-0 z-[300] h-[calc(100vh_-_128px)] w-[calc(100vw_-_34px)]", children: _jsx(Stack, { align: "center", justify: "center", width: "full", className: "fixed", children: _jsx(Loader, {}) }) }) }) })), rows.map((row) => (_jsx(TableRow, Object.assign({ className: cn('h-0', loadingStatus === 'reloading' ? 'opacity-25' : null), "data-testid": `${rowTestIdPrefix}-${row.id}`, "data-highlighted": isRowHighlighted ? isRowHighlighted(row) : false, "data-state": row.getIsSelected() && 'selected' }, (renderRowAttrs ? renderRowAttrs(row) : {}), { children: row.getVisibleCells().map((cell) => (_jsx(StyledTableCell, { "data-testid": `cell-${cell.id}`, children: flexRender(cell.column.columnDef.cell, cell.getContext()) }, cell.id))) }), row.id)))] }));
    }, [
        loadingStatus,
        renderNoItems,
        renderRowAttrs,
        rows,
        isRowHighlighted,
        rowTestIdPrefix,
    ]);
    if (loadingStatus === 'loading') {
        return (_jsx("div", { className: "flex h-full items-center justify-center", children: _jsx(Loader, {}) }));
    }
    return (_jsxs(Table, { className: "relative h-fit w-max min-w-full", children: [_jsx(TableHeader, { children: table.getHeaderGroups().map((headerGroup) => (_jsx(TableRow, { className: "relative z-30 h-full", children: headerGroup.headers.map((header, index) => {
                        return (_jsx(TableHead, { ref: (el) => {
                                if (!el)
                                    return;
                                thRefs.current[index] = el;
                            }, colSpan: header.colSpan, className: cn(
                            // Default styles for all header cells
                            'border-l', 'max-w-sm', 
                            // Explicitly set z-index cause Safari
                            'z-10', 
                            // Add a super slight bg blur to the header cells
                            'bg-white/75 backdrop-blur-md', 
                            // Hide left and add a right border to first child
                            'first:border-l-0', 'first:border-r', 
                            // Fix the first child to the left, if there's a sticky first column
                            '[&:has([role=checkbox])]:left-0', '[&:has([role=checkbox])]:z-[30]', '[&:has([role=checkbox])]:relative', 
                            // Hide left border from 2nd child, cause we have a right border on the 1st
                            '[&:nth-child(2)]:border-l-0', 'hover:z-30'), style: Object.assign({ 
                                // Getting column widths from the config in:
                                // src/applications/askhub-ui/src/pages/NewResponses/columnUtils.tsx
                                // while ignoring the library default min width (20), so we can
                                // fix dynamic widths to all other columns
                                //
                                width: header.getSize() !== defaultColumnSizing.minSize
                                    ? header.getSize()
                                    : thWidths[index], boxSizing: 'border-box' }, (withStickyHeader
                                ? {
                                    position: 'sticky',
                                    top: 0,
                                }
                                : {})), children: _jsx(BtwText, { variant: "primary", weight: "medium", leading: "tight", className: "flex h-full w-full flex-col justify-end", "data-testid": "column-header", children: _jsx(SortButton, { header: header, children: header.isPlaceholder
                                        ? null
                                        : flexRender(header.column.columnDef.header, header.getContext()) }) }) }, header.id));
                    }) }, headerGroup.id))) }), _jsxs(TableBody, { children: [_jsx("tr", { className: "h-0", children: _jsx("td", { style: { height: paddingTop || 0 } }) }), renderBody(), loadingStatus === 'fetchMore' && (_jsxs(_Fragment, { children: [_jsx(LoadingRow, { table: table }), _jsx(LoadingRow, { table: table })] })), _jsx("tr", { className: "h-0", children: _jsx("td", { style: { height: paddingBottom || 0 } }) })] })] }));
}
function StyledTableCell({ children, className, 'data-testid': testId, }) {
    return (_jsx(TableCell, { className: cn(
        // Default left border for all cells
        'border-l', 'max-w-sm', 
        // Fixed column-specific styling
        'first:border-l-0', 'first:border-r', '[&:has([role=checkbox])]:sticky', '[&:has([role=checkbox])]:left-0', '[&:has([role=checkbox])]:z-20', '[&:has([role=checkbox])]:bg-white/75 [&:has([role=checkbox])]:backdrop-blur-md', '[&:has([role=checkbox])]:py-2', '[&:nth-child(2)]:border-l-0', className), "data-testid": testId, children: children }));
}
function LoadingRow({ table }) {
    return (_jsx(TableRow, { children: table.getVisibleFlatColumns().map((column) => (_jsx(StyledTableCell, { className: "h-12", children: _jsx(BtwSkeleton, { className: "h-2 w-full" }) }, column.id))) }));
}
function SortButton({ header, children }) {
    const { column } = header;
    if (!column.getCanSort())
        return _jsx(_Fragment, { children: children });
    return (_jsx(BtwLink, { onClick: column.getToggleSortingHandler(), underline: false, variant: "current", className: "select-none", children: _jsx(BtwText, { weight: "medium", variant: "primary", leading: "tight", children: _jsxs(Stack, { direction: "row", align: "center", gap: "1", children: [children, " ", getSortingIcon(column)] }) }) }));
}
function getSortingIcon(column) {
    const isSorted = column.getIsSorted();
    switch (isSorted) {
        case 'asc':
            return (_jsx(ArrowTopIcon, { "data-testid": "asc-icon", className: "h-4 w-4 text-gray-500" }));
        case 'desc':
            return (_jsx(ArrowBottomIcon, { "data-testid": "desc-icon", className: "h-4 w-4 text-gray-500" }));
        default:
            return _jsx(SortIcon, { className: "h-4 w-4 text-gray-300" });
    }
}
// The width of the table cell is adjusted based on its initial rendering.
// This approach ensures that the cell width remains static and does not
// dynamically adjust, preventing any layout shifts when rows are added or removed.
function useOptimalColumnWidths({ adjustColumnWidths, hasTableLoaded, }) {
    const thRefs = useRef([]);
    const [thWidths, setThWidths] = useState([]);
    useLayoutEffect(() => {
        if (!adjustColumnWidths || !hasTableLoaded || thWidths.length > 0)
            return;
        const newWidths = thRefs.current.map((th) => th.getBoundingClientRect().width);
        setThWidths(newWidths);
    }, [adjustColumnWidths, hasTableLoaded, thWidths]);
    return { thRefs, thWidths };
}
