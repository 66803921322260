import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { BtwHeading } from './BtwHeading';
import { BtwText } from './BtwText';
import { BtwToggle } from './BtwToggle';
import { BtwTooltip } from './BtwTooltip';
import { Stack } from './Stack';
function Root(props) {
    const { children, description, disabled, disabledTooltipText, heading, size = 'md', } = props;
    if (!heading && !description) {
        return null;
    }
    return (_jsxs(Stack, { gap: "3", direction: "row", justify: "between", align: "center", width: "full", children: [_jsxs(Stack, { gap: size === 'md' ? '1' : '0.5', align: "start", children: [_jsx(BtwHeading, { weight: "medium", size: size === 'md' ? 'sm' : '13', children: heading }), description && (_jsx(BtwText, { "data-testid": "setting-description", leading: "normal", size: size === 'md' ? '13' : 'xs', children: description }))] }), _jsx(BtwTooltip, { content: disabled ? disabledTooltipText : undefined, disableHoverableContent: true, children: _jsx("div", { children: children }) })] }));
}
function Base(props) {
    const { heading, description, disabled, disabledTooltipText, size } = props, rest = __rest(props, ["heading", "description", "disabled", "disabledTooltipText", "size"]);
    return (_jsx(Root, { heading: heading, description: description, disabled: disabled, disabledTooltipText: disabledTooltipText, size: size, children: _jsx(BtwToggle, Object.assign({ size: size, variant: "legacyBlue", disabled: disabled }, rest)) }));
}
const BtwSettingsItem = Object.assign(Base, {
    Root,
});
export { BtwSettingsItem };
