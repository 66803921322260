import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { AuthedRoute } from '@marvelapp/ballpark-application';
import { UnauthorizedAccess } from './ErrorPages';
export function PrivateRoute(_a) {
    var { hasPermission, errorComponent = UnauthorizedAccess } = _a, rest = __rest(_a, ["hasPermission", "errorComponent"]);
    if (hasPermission) {
        return _jsx(AuthedRoute, Object.assign({}, rest));
    }
    return _jsx(AuthedRoute, { component: errorComponent });
}
