import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@marvelapp/styled';
export const NestedList = ({ children }) => {
    return _jsx(NestedListElement, { children: children });
};
const NestedListElement = styled.ul `
  ul {
    margin-top: 16px !important;
    li {
      list-style-type: circle;
    }
  }
`;
