import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useCurrentSubscription, useCurrentUser, } from '@marvelapp/ballpark-application';
import { UserRole } from '@marvelapp/core';
import { getUserInfo } from '../helpers';
import editor from '../roles/editor';
import externalReviewer from '../roles/external-reviewer';
import internalReviewer from '../roles/internal-reviewer';
import owner from '../roles/owner';
import { getClosedUpsells, setClosedUpsell } from '../storage';
import UpsellRenderer from './UpsellRenderer';
export default function Upsell({ position, }) {
    const [closed, setClosed] = useState(null);
    const user = useCurrentUser();
    const [subscription] = useCurrentSubscription();
    const userInfo = getUserInfo(user, subscription);
    useEffect(() => {
        setClosed(getClosedUpsells({ userPk: user.pk }));
    }, [user.pk]);
    const onClickClose = ({ identifier, daysUntilReshownAfterClose, }) => {
        setClosed(setClosedUpsell({
            identifier,
            userPk: user.pk,
            position,
            daysUntilReshownAfterClose,
        }));
    };
    if (closed === null) {
        return null;
    }
    const props = {
        onClickClose,
        closed,
        position,
        userInfo,
    };
    switch (user.role) {
        case UserRole.Owner:
            return _jsx(UpsellRenderer, Object.assign({ upsells: owner(userInfo)[position] }, props));
        case UserRole.Admin:
            return _jsx(UpsellRenderer, Object.assign({ upsells: owner(userInfo)[position] }, props));
        case UserRole.Editor:
            return _jsx(UpsellRenderer, Object.assign({ upsells: editor(userInfo)[position] }, props));
        case UserRole.InternalReviewer:
            return (_jsx(UpsellRenderer, Object.assign({ upsells: internalReviewer(userInfo)[position] }, props)));
        case UserRole.ExternalReviewer:
            return (_jsx(UpsellRenderer, Object.assign({ upsells: externalReviewer(userInfo)[position] }, props)));
        default:
            return null;
    }
}
