import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import ReactDOM from 'react-dom';
const MODAL_ID = 'root-modal';
export default class ModalPortal extends Component {
    componentDidMount() {
        this.portalDestinationElem = document.getElementById(MODAL_ID);
    }
    render() {
        return this.portalDestinationElem
            ? ReactDOM.createPortal(this.props.children, this.portalDestinationElem)
            : null;
    }
}
export function ModalPortalDestination() {
    return _jsx("div", { id: MODAL_ID });
}
