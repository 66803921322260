import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as React from 'react';
import { tv } from 'tailwind-variants';
import { baseButtonStyles, secondaryButtonStyles } from './BtwButton';
import { BtwText } from './BtwText';
import { Stack } from './Stack';
import { StepSettingsIcons, } from './StepSettingsIcons';
import { StepType } from './StepType';
import { STEP_DESCRIPTION } from './stepUtils';
const card = tv({
    base: [
        ...baseButtonStyles,
        ...secondaryButtonStyles,
        // Layout
        'p-3',
        'flex',
        'gap-1',
        'w-full',
        'flex-col',
        'bg-white',
        'items-start',
        // Misc visual
        'text-left',
        'rounded-md',
        'max-w-full',
        'overflow-hidden',
    ],
    variants: {
        isActive: {
            true: [
                'ring-2',
                'ring-gray-600',
                'hover:ring-gray-700',
                'active:ring-gray-800',
                'focus-visible:ring-gray-600',
            ],
            false: [],
        },
        inMultiplayerMode: {
            true: [
                'ring-2',
                'ring-amber-300',
                'hover:ring-amber-400',
                'active:ring-amber-300',
                'focus-visible:ring-amber-300',
                'focus-visible:outline-amber-300/25',
                // The div holding all multiplayer faces
                'last:[&>div]:hover:ring-gray-50',
                'last:[&>div]:hover:bg-amber-400',
                'last:[&>div]:active:bg-amber-300',
                'last:[&>div]:focus-visible:bg-amber-300',
                '[&>div>span]:last:hover:ring-amber-400',
                '[&>div>span]:last:active:ring-amber-300',
            ],
            false: [],
        },
    },
    compoundVariants: [
        {
            isActive: true,
            inMultiplayerMode: true,
            className: [
                'ring-gray-600',
                'hover:ring-gray-700',
                'active:ring-gray-800',
                'focus-visible:ring-gray-600',
                // The div holding all multiplayer faces
                'last:[&>div]:bg-gray-600',
                'last:[&>div]:hover:ring-gray-50',
                'last:[&>div]:hover:bg-gray-700',
                'last:[&>div]:active:bg-gray-800',
                'last:[&>div]:focus-visible:bg-gray-600',
                '[&>div>span]:last:ring-gray-600',
                '[&>div>span]:last:hover:ring-gray-700',
                '[&>div>span]:last:active:ring-gray-800',
            ],
        },
    ],
    defaultVariants: {
        inMultiplayerMode: false,
        isActive: false,
    },
});
const StepCard = forwardRef(function StepCardBase(props, ref) {
    const { className, hasConditions, inMultiplayerMode, isActive, isScreenerStep, isStepRequired, realtimeAvatars, recordingSettings, title, type, videoGuideStatus } = props, rest = __rest(props, ["className", "hasConditions", "inMultiplayerMode", "isActive", "isScreenerStep", "isStepRequired", "realtimeAvatars", "recordingSettings", "title", "type", "videoGuideStatus"]);
    return (_jsxs("button", Object.assign({ type: "button", className: card({ className, isActive, inMultiplayerMode }), ref: ref }, rest, { children: [_jsxs(Stack, { align: "center", direction: "row", gap: "1", justify: "between", width: "full", className: "min-h-0", children: [_jsx(StepType, { type: type, size: "xs" }), _jsx(StepSettingsIcons, { videoGuideStatus: videoGuideStatus, recordingSettings: recordingSettings, isStepRequired: isStepRequired, hasConditions: hasConditions })] }), _jsx(BtwText, { className: "line-clamp-2", leading: "normal", size: "xs", variant: "primary", weight: "normal", children: title || STEP_DESCRIPTION[type] }), inMultiplayerMode && realtimeAvatars] })));
});
export { StepCard };
