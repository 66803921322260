import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import * as React from 'react';
import { heapTrackEvent } from '@marvelapp/core';
import { BaseError } from './BaseError';
export function NotFound({ heading = 'Page not found', headingMaxWidth, subheading = "We can't find the page you're looking for. Please contact the project owner or account admin for more info.", subheadingMaxWidth, testId = 'page-not-found', }) {
    useEffect(() => {
        heapTrackEvent('404 Appeared', { pathname: window.location.pathname });
    }, []);
    return (_jsx(BaseError, { heading: heading, headingMaxWidth: headingMaxWidth, subheading: subheading, subheadingMaxWidth: subheadingMaxWidth, testId: testId }));
}
