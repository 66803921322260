import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence, motion } from 'framer-motion';
import { layout } from 'styled-system';
import theme from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import Absolute from './Absolute';
import Box from './Box';
import CloseButton from './CloseButton';
import EscKeyHandler from './EscKeyHandler';
import ModalOverlay from './ModalOverlay';
import ModalPortal from './ModalPortal';
import Text from './Text';
/** @param {any} */
export default function ModalDialog({ children, isOpen, maxWidth = 440, size = 1, title = null, trigger, width, }) {
    const headerSizes = [{ padding: 3 }, { padding: 4 }];
    const headerTitleSizes = [{ fontSize: 3 }, { fontSize: 3 }];
    const closeButtonSizes = [
        { top: 15, right: 15 },
        { top: 21, right: 21 },
    ];
    const animation = {
        animate: {
            opacity: 1,
            x: '-50%',
            y: '-50%',
        },
        exit: {
            opacity: 0,
            x: '-50%',
            y: '-35%',
        },
        initial: {
            opacity: 0,
            x: '-50%',
            y: '-35%',
        },
        transition: {
            ease: theme.easings.smooth.array,
            duration: 0.6,
        },
    };
    return (_jsxs(ModalPortal, { children: [isOpen && _jsx(EscKeyHandler, { onEscapeKey: trigger }), _jsxs(AnimatePresence, { children: [isOpen && _jsx(ModalOverlay, { onClick: trigger }), isOpen && (_jsxs(Content, { animate: animation.animate, "data-testid": "modal-dialog", exit: animation.exit, initial: animation.initial, maxWidth: maxWidth, transition: animation.transition, width: width, children: [title && (_jsx(Box, Object.assign({ borderBottom: "1px solid", borderColor: "smoke" }, headerSizes[size], { children: _jsx(Text, Object.assign({ color: "licorice", fontWeight: 500, marginTop: "1px", textAlign: "center" }, headerTitleSizes[size], { children: title })) }))), children, _jsx(Absolute, Object.assign({}, closeButtonSizes[size], { children: _jsx(CloseButton, { fill: "currentColor", kind: "ghost", onClick: trigger, padding: 0, size: size }) }))] }, "modal"))] })] }));
}
export const Content = styled(motion.div) `
  ${layout};

  pointer-events: all;
  border-radius: ${(props) => props.theme.radii[3]}px;
  box-shadow: ${(props) => props.theme.shadows.modal};
  background-color: ${(props) => props.theme.colors.white};

  position: fixed;
  top: 50%;
  left: 50%;
  z-index: ${(props) => props.theme.zIndices.modal};
`;
