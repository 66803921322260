import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
class ErrorBoundary extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            hasError: false,
        };
    }
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    // When the location changes (back button clicked etc.) if the component
    // is in an error state, it is cleared enabling the children to be
    // rendered again.
    componentDidMount() {
        this.unlistenHistory = this.props.history.listen(() => {
            if (this.state.hasError) {
                this.setState({
                    hasError: false,
                });
            }
        });
    }
    componentDidCatch(error, errorInfo) {
        var _a;
        console.log(error);
        console.log(errorInfo);
        if (process.env.DEPLOY_ENV.includes('production') &&
            typeof window !== 'undefined' &&
            ((_a = window.LogRocket) === null || _a === void 0 ? void 0 : _a._isInitialized)) {
            // eslint-disable-next-line no-undef
            LogRocket.captureException(error, { extra: { errorInfo } });
        }
    }
    componentWillUnmount() {
        if (this.unlistenHistory)
            this.unlistenHistory();
    }
    render() {
        const ErrorComponent = this.props.component;
        if (this.state.hasError) {
            return _jsx(ErrorComponent, {});
        }
        // should just this.props.children work here (it's in the docs but breaks
        // here unless wrapped in a fragment 🤔)
        return _jsx(_Fragment, { children: this.props.children });
    }
}
export default withRouter(ErrorBoundary);
