import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MinusIcon, PlusIcon } from '@marvelapp/ballpark-icons';
import { BtwButton } from './BtwButton';
import { BtwTooltip } from './BtwTooltip';
import { Stack } from './Stack';
import { cn } from './utils';
function BtwStepper(props) {
    const { decrementDisabledReason, incrementDisabledReason, onDecrement, onIncrement, size, stepperTestId, value, } = props;
    // We need z-0 on the parent, because the decrement button has z-10 – it needs
    // to stay on top of he middle value button
    return (_jsxs(Stack, { "data-testid": stepperTestId, className: "relative z-0", direction: "row", children: [_jsx(BtwTooltip, { content: decrementDisabledReason, children: _jsx(BtwButton, { className: "relative z-10 rounded-r-none hover:translate-y-0 disabled:ring-gray-200", disabled: Boolean(decrementDisabledReason), onClick: onDecrement, size: size, standaloneIcon: _jsx(MinusIcon, {}) }) }), _jsx(BtwButton, { asChild: true, className: cn('pointer-events-none', 'rounded-none', {
                    'border-l-2 border-gray-600/20': Boolean(decrementDisabledReason),
                    'border-r-2 border-gray-600/20': Boolean(incrementDisabledReason),
                }), size: size, children: _jsx("div", { children: value }) }), _jsx(BtwTooltip, { content: incrementDisabledReason, children: _jsx(BtwButton, { className: "rounded-l-none hover:translate-y-0 disabled:ring-gray-200", disabled: Boolean(incrementDisabledReason), onClick: onIncrement, size: size, standaloneIcon: _jsx(PlusIcon, {}) }) })] }));
}
export { BtwStepper };
