import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CategorySlug } from '../types';
export const socialMediaTest = {
    slug: 'social-media-test',
    title: 'Social Media Test Template | Ballpark',
    metaDescription: 'See how your social media performs in our social media test template. Measure messaging, image and content preferences and optimise your posting.',
    heading: 'Social media test template',
    subheading: 'Test how well your proposed social posts will perform with your audience.',
    categories: [CategorySlug.BrandAndCopyTesting, CategorySlug.Marketing],
    body: _jsx(SocialMediaTestBody, {}),
    templateUUID: 'ut_b9c6f41a-e2bf-4daf-84d0-8f60fc85fa53',
    recordLink: 'https://app.ballparkhq.com/record/b9c6f41a-e2bf-4daf-84d0-8f60fc85fa53',
    accordionTitle: 'Social media test template FAQs',
    accordionItems: [
        {
            id: 1,
            question: 'What are some example questions in a social media test?',
            answerParagraphs: [
                'You can ask a mixture of open and closed questions such as why do you use social media? Do you like engaging with brands? What type of content would you like to see from our product? As well as asking participants to pick their preferred social posts.',
            ],
        },
        {
            id: 2,
            question: 'What type of data can I gather in a social media test?',
            answerParagraphs: [
                'You can gather written feedback, the percentage preference of example posts, as well as be able to measure the memorability of a post by showing it for a few seconds and seeing what information a participant can recall.',
            ],
        },
        {
            id: 3,
            question: 'What other marketing testing can I do?',
            answerParagraphs: [
                'You can test other campaign assets such as paid search ads, copy, taglines and email.',
            ],
        },
    ],
};
function SocialMediaTestBody() {
    return (_jsxs("div", { children: [_jsxs("p", { children: ["Social media platforms enable you to connect with your users and develop conversations, building brand awareness, engagement and traffic.", ' '] }), _jsx("p", { children: "Our social media test template will measure how well your proposed posts perform and whether messaging and images resonate." }), _jsx("h3", { children: "Insights you\u2019ll gain from a social media test:" }), _jsxs("ul", { children: [_jsx("li", { children: "Whether images with posts perform better vs just text" }), _jsx("li", { children: "What content topics your audience are interested in" }), _jsx("li", { children: "Which messaging performs the best" })] })] }));
}
