import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { AsteriskIcon, BranchesIcon, MicrophoneDisabledIcon, MicrophoneIcon, ScreenDisabledIcon, ScreenIcon, VideoIcon, WebcamDisabledIcon, WebcamIcon, } from '@marvelapp/ballpark-icons';
import { MediaUtils } from '@marvelapp/user-test-creator';
import { BtwTooltip } from './BtwTooltip';
import { Stack } from './Stack';
import { cn } from './utils';
const ICON_CLASSNAME = 'h-3 w-3 [&>path]:stroke-1';
var StepSettingsIconStatus;
(function (StepSettingsIconStatus) {
    StepSettingsIconStatus["Disabled"] = "Disabled";
    StepSettingsIconStatus["Enabled"] = "Enabled";
    StepSettingsIconStatus["Failed"] = "Failed";
})(StepSettingsIconStatus || (StepSettingsIconStatus = {}));
// This component is used in a few places, some of which don't use
// context yet. Hence why we still need to use props if `step` is not available
function StepSettingsIcons({ videoGuideStatus, recordingSettings, isStepRequired, hasConditions, }) {
    const { conditionalLogic, screen, microphone, webcam, guides, required } = getSlideRowIcons({
        videoGuideStatus,
        recordingSettings,
        isStepRequired,
        hasConditions,
    });
    return (_jsxs(Stack, { direction: "row", gap: "1", children: [_jsx(ConditionalLogic, { status: conditionalLogic }), _jsx(ScreenRecording, { status: screen }), _jsx(Microphone, { status: microphone }), _jsx(Webcam, { status: webcam }), _jsx(Guides, { status: guides }), _jsx(Required, { status: required })] }));
}
function ConditionalLogic({ status }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (_jsx(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "This step has conditional logic rules defined", children: _jsx(BranchesIcon, { className: ICON_CLASSNAME }) }));
        // For the conditional logic icon we don't have use cases for these other states yet
        case StepSettingsIconStatus.Disabled:
        case StepSettingsIconStatus.Failed:
        default:
            return null;
    }
}
function ScreenRecording({ status }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (_jsx(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Screen recording enabled", children: _jsx(ScreenIcon, { "data-testid": "screen-icon", className: ICON_CLASSNAME }) }));
        case StepSettingsIconStatus.Disabled:
            return (_jsx(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Screen recording disabled", children: _jsx(ScreenDisabledIcon, { className: ICON_CLASSNAME }) }));
        case StepSettingsIconStatus.Failed:
            return (_jsx(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Screen recording upload failed", children: _jsx(ScreenDisabledIcon, { className: ICON_CLASSNAME }) }));
        default:
            return null;
    }
}
function Microphone({ status }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (_jsx(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Microphone enabled", children: _jsx(MicrophoneIcon, { "data-testid": "mic-icon", className: ICON_CLASSNAME }) }));
        case StepSettingsIconStatus.Disabled:
            return (_jsx(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Microphone disabled", children: _jsx(MicrophoneDisabledIcon, { className: ICON_CLASSNAME }) }));
        case StepSettingsIconStatus.Failed:
            return (_jsx(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Microphone recording upload failed", children: _jsx(MicrophoneDisabledIcon, { className: ICON_CLASSNAME }) }));
        default:
            return null;
    }
}
function Webcam({ status }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (_jsx(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Webcam enabled", children: _jsx(WebcamIcon, { "data-testid": "webcam-icon", className: ICON_CLASSNAME }) }));
        case StepSettingsIconStatus.Disabled:
            return (_jsx(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Webcam disabled", children: _jsx(WebcamDisabledIcon, { "data-testid": "webcam-icon", className: ICON_CLASSNAME }) }));
        case StepSettingsIconStatus.Failed:
            return (_jsx(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Webcam disabled", children: _jsx(WebcamDisabledIcon, { "data-testid": "webcam-icon", className: ICON_CLASSNAME }) }));
        default:
            return null;
    }
}
function Guides({ status }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (_jsx(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Video guide enabled", children: _jsx(VideoIcon, { className: ICON_CLASSNAME }) }));
        // For the video guides icon we don't have use cases for these other states yet
        case StepSettingsIconStatus.Disabled:
        case StepSettingsIconStatus.Failed:
        default:
            return null;
    }
}
function Required({ status }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (_jsx(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Required step", children: _jsx(AsteriskIcon, { "data-testid": "required-icon", className: cn(ICON_CLASSNAME, 'text-red-700') }) }));
        // For the required icon we don't have use cases for these other states yet
        case StepSettingsIconStatus.Disabled:
        case StepSettingsIconStatus.Failed:
        default:
            return null;
    }
}
function getSlideRowIcons({ hasConditions, videoGuideStatus, recordingSettings = {
    microphone: false,
    screen: false,
    webcam: false,
}, isStepRequired, }) {
    const { microphone: isMicEnabled, screen: isScreenEnabled, webcam: isWebcamEnabled, } = recordingSettings;
    return {
        required: isStepRequired ? StepSettingsIconStatus.Enabled : undefined,
        guides: videoGuideStatus === MediaUtils.RecordingStatus.Ready
            ? StepSettingsIconStatus.Enabled
            : undefined,
        microphone: isMicEnabled ? StepSettingsIconStatus.Enabled : undefined,
        screen: isScreenEnabled ? StepSettingsIconStatus.Enabled : undefined,
        webcam: isWebcamEnabled ? StepSettingsIconStatus.Enabled : undefined,
        conditionalLogic: hasConditions
            ? StepSettingsIconStatus.Enabled
            : undefined,
    };
}
export { StepSettingsIcons };
