import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useString } from '@marvelapp/hooks';
import { Message, Text } from '@marvelapp/ui';
import { Button } from '../Buttons';
export function DeleteButton({ buttonText, handleFormSubmit }) {
    const [submitErrorMessage, setSubmitErrorMessage, clearSubmitErrorMessage] = useString();
    const [isLoading, setIsLoading] = useState(false);
    function onSubmit() {
        clearSubmitErrorMessage();
        setIsLoading(true);
        // The loading and error state is automatically handled
        // when handleFormSubmit returns a Promise
        Promise.resolve(handleFormSubmit())
            .catch((error) => setSubmitErrorMessage(error.jsxMessage || error.message))
            .finally(() => {
            setIsLoading(false);
        });
    }
    return (_jsxs(_Fragment, { children: [submitErrorMessage && (_jsx(Message, { kind: "error", mb: 25, children: _jsx(Text, { fontSize: 2, children: submitErrorMessage }) })), _jsx(Button, { "data-testid": "delete-modal-button", kind: "negative", isLoading: isLoading, onClick: onSubmit, size: "m", children: buttonText })] }));
}
