import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as React from 'react';
import { Box } from '@marvelapp/ui';
import { Label, LabelContainer } from './Label';
import { Text } from './Text';
import { Textarea } from './Textarea';
export const TextareaWithLabel = forwardRef((_a, ref) => {
    var { id, isOptional, kind, label, labelSize = 'm', subheading, textareaSize = 'm' } = _a, props = __rest(_a, ["id", "isOptional", "kind", "label", "labelSize", "subheading", "textareaSize"]);
    return (_jsxs(_Fragment, { children: [_jsx(LabelContainer, { marginBottom: 12, children: _jsxs(Box, { display: "flex", flexDirection: "column", children: [_jsx(Label, { display: "inline-block", htmlFor: id, isOptional: isOptional, size: labelSize, children: label }), subheading && (_jsx(Text, { color: "slate12", mt: "xs", size: "s", children: subheading }))] }) }), _jsx(Textarea, Object.assign({ id: id, kind: kind, ref: ref, size: textareaSize, width: 1 }, props))] }));
});
