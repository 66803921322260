import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { EyeIcon } from '@marvelapp/ballpark-icons';
import { pluralize } from '@marvelapp/core';
import { AspectImage } from '../AspectImage';
import { BtwButton } from '../BtwButton';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { FullscreenModal } from '../FullscreenModal';
import { Progress } from '../Progress';
import { Stack } from '../Stack';
import { Strong } from '../Strong';
function Root(props) {
    return (_jsx(Stack, Object.assign({ align: "center", className: "w-full", direction: "row", gap: "5" }, props)));
}
function Body(props) {
    return _jsx(Stack, Object.assign({ className: "flex-1", gap: "3" }, props));
}
function Meta(props) {
    const { infoText, participants, percent, title } = props;
    return (_jsxs(Stack, { align: "baseline", direction: "row", gap: "4", justify: "between", width: "full", children: [_jsx(BtwHeading, { truncate: true, size: "sm", children: title }), _jsxs(BtwText, { className: "whitespace-nowrap", size: "13", children: [infoText && (_jsxs(_Fragment, { children: [_jsx("span", { children: infoText }), "\u00A0\u00A0\u00B7\u00A0\u00A0"] })), _jsxs("span", { children: [participants, " ", pluralize(participants, 'participant')] }), "\u00A0\u00A0\u00B7\u00A0\u00A0", _jsxs(Strong, { children: [percent, "%"] })] })] }));
}
function ChoiceProgress(props) {
    const { percent, variant } = props;
    return (_jsx(Progress.AnimatedInView, { rounded: "md", size: "lg", value: percent, variant: getProgressVariant(variant) }));
}
function SummaryChoiseBarBase(props) {
    const { infoText, participants, percent, title, variant = 'default' } = props;
    return (_jsx(Root, { children: _jsxs(Body, { children: [_jsx(Meta, { infoText: infoText, participants: participants, percent: percent, title: title }), _jsx(ChoiceProgress, { percent: percent, variant: variant })] }) }));
}
function WithImage(props) {
    const { alt, image, infoText, participants, percent, title, variant = 'default', } = props;
    return (_jsxs(Root, { children: [image && (_jsx("div", { className: "w-16", children: _jsx(FullscreenModal, { trigger: _jsx(AspectImage, { src: image, srcSet: `${image} 2x`, alt: alt }), children: _jsx(FullscreenModal.Image, { alt: alt, height: "contain", src: image }) }) })), _jsxs(Body, { children: [_jsx(Meta, { infoText: infoText, participants: participants, percent: percent, title: title }), _jsx(ChoiceProgress, { percent: percent, variant: variant })] })] }));
}
function WithTagline(props) {
    const { infoText, participants, percent, tagline, title, variant = 'default', } = props;
    return (_jsxs(Root, { children: [tagline && (_jsx("div", { className: "w-16", children: _jsx(FullscreenModal, { trigger: _jsx(AspectImage.Container, { children: _jsx(BtwButton, { size: "sm", standaloneIcon: _jsx(EyeIcon, {}) }) }), children: _jsxs(Stack, { gap: "1", className: "shadow-sm-longer w-full max-w-md rounded-lg bg-white p-8 ring-1 ring-gray-600/20", children: [_jsx(BtwHeading, { className: "px-1", size: "lg", children: tagline.heading }), tagline.paragraph] }) }) })), _jsxs(Body, { children: [_jsx(Meta, { infoText: infoText, participants: participants, percent: percent, title: title }), _jsx(ChoiceProgress, { percent: percent, variant: variant })] })] }));
}
export const SummaryChoiceBar = Object.assign(SummaryChoiseBarBase, {
    WithImage,
    WithTagline,
});
const getProgressVariant = (variant) => {
    switch (variant) {
        case 'winning':
            return 'green';
        case 'losing':
            return 'red';
        default:
            return 'gray';
    }
};
