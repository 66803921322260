import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Slot, Slottable } from '@radix-ui/react-slot';
import { forwardRef } from 'react';
import * as React from 'react';
import { tv } from 'tailwind-variants';
import { baseButtonStyles } from './BtwButton';
import { Image } from './Image';
import { Stack } from './Stack';
const aspectImage = tv({
    base: [
        ...baseButtonStyles,
        'ring-1',
        'ring-inset',
        'ring-gray-600/10',
        '[&_img]:relative',
        '[&_img]:max-h-[80%]',
        '[&_img]:max-w-[80%]',
        '[&_img]:rounded-sm',
    ],
    variants: {
        aspect: {
            video: ['aspect-video'],
            square: ['aspect-square'],
        },
        background: {
            gray: ['bg-gray-100', 'hover:bg-gray-200/80'],
            dotted: [
                'bg-[length:16px_16px]',
                'bg-[url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB5SURBVHgB7dY7DoAgEEXRJ6ilMcbv/vemEokaSxWUWLiAaWjerWgIJ0wzyRNCxBQiRwABBBBAAAEphG37gXG20FqhbSpUZQFJ4h8wywrnPc7rxmQspIkBzvn/rJV8kuKbQ18jz9Lv8S6MQFrCjQiRI4AAAgggIDrgBSnDHTl1FxX6AAAAAElFTkSuQmCC)]',
            ],
        },
        size: {
            '16': ['w-16', 'rounded'],
            full: ['w-full', 'rounded'],
        },
        hasHover: {
            true: ['active:translate-y-px', 'cursor-pointer'],
            false: ['active:translate-y-0', 'cursor-default'],
        },
    },
    compoundVariants: [
        {
            hasHover: true,
            background: 'gray',
            class: ['hover:ring-gray-600/20'],
        },
        {
            hasHover: true,
            background: 'dotted',
            class: [
                // Hover state needs to be a pseudo-element, otherwise the transition
                // won't work since CSS can't transition between background images
                'before:opacity-0',
                'before:rounded',
                'before:absolute',
                'before:inset-0',
                'before:bg-[length:16px_16px]',
                'before:bg-[url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACCSURBVHgB7dYxCoNQEIThMWQt4hkCgWCliIL3P4FYiNiIIHgFFdxXqFh4gC18zfzVlh9TbbCp2+GxFzxHAAEEEEAAAW8YG8YJVd0hDAVZGuP/+8KSeYGm7aHOYV7WC2LNDFDV+5ZzhccBZZEiij4QEeRJDGsBPyJ4jgACCCCAAO+AAxCyHRniQYeBAAAAAElFTkSuQmCC)]',
                'before:ring-1',
                'before:ring-inset',
                'before:ring-gray-600/10',
                'before:duration-200',
                'before:transition-opacity',
                'hover:before:opacity-100',
                'hover:before:ring-gray-600/20',
            ],
        },
    ],
    defaultVariants: {
        hasHover: true,
        aspect: 'square',
        background: 'gray',
        size: 'full',
    },
});
const Container = forwardRef(function Container(props, ref) {
    const { asChild, aspect, background, children, className, hasHover, size } = props, rest = __rest(props, ["asChild", "aspect", "background", "children", "className", "hasHover", "size"]);
    const Comp = asChild ? Slot : 'button';
    return (_jsx(Stack, { asChild: true, align: "center", justify: "center", children: _jsx(Comp, Object.assign({ className: aspectImage({
                aspect,
                background,
                className,
                hasHover,
                size,
            }), ref: ref }, rest, { children: children })) }));
});
const AspectImageBase = forwardRef(function AspectImageBase(props, ref) {
    const { 'data-testid': testId = 'card-image', alt, children, src, srcSet } = props, rest = __rest(props, ['data-testid', "alt", "children", "src", "srcSet"]);
    return (_jsxs(Container, Object.assign({ ref: ref }, rest, { children: [_jsx(Slottable, { children: children }), _jsx(Image, { alt: alt, src: src, srcSet: srcSet, "data-testid": testId })] })));
});
export const AspectImage = Object.assign(AspectImageBase, {
    Container,
});
