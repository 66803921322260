import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Heading, Icon, Text } from '@marvelapp/ballpark-components';
import { CHECKBOX_TICK_ICON } from '@marvelapp/ballpark-theme';
import { deviceDetection } from '@marvelapp/core';
import { supportedBrowsers } from '@marvelapp/media-recording';
import styled from '@marvelapp/styled';
import { Flex, PinAll, PinBR, Relative } from '@marvelapp/ui';
import { ChromeIcon, EdgeIcon, FirefoxIcon, OperaIcon, SafariIcon, } from './browserIcons';
export const BrowserCheck = observer(function BrowserCheck() {
    const { pathname } = useLocation();
    const intl = useIntl();
    const isPageAccessibleOnMobile = isAccessibleOnMobile(pathname);
    // iOS / iPadOS
    if ((deviceDetection.isIPad || deviceDetection.isIOS) &&
        !isPageAccessibleOnMobile) {
        const isSupportedSafariVersion = deviceDetection.browserVersion &&
            getMajorVersion(deviceDetection.browserVersion) >=
                supportedBrowsers.Safari;
        if (isSupportedSafariVersion) {
            return (_jsx(Container, { children: _jsx(FormattedMessage, { id: 'SmP0bq', defaultMessage: '<heading>Accessing your {brand} account requires using a desktop device</heading> <text>Sign in using your computer to create and edit projects</text>', values: {
                        brand: 'Ballpark',
                        heading: (chunks) => _jsx(Heading, { mt: "m", children: chunks }),
                        text: (chunks) => (_jsx(Text, { mt: "xs", maxWidth: 450, children: chunks })),
                    } }) }));
        }
        return (_jsx(Container, { "data-testid": "browser-check-message", children: _jsx(FormattedMessage, { id: 'YJMm9+', defaultMessage: '<heading>Please use the latest version of {browser} on mobile</heading><text>Sorry, we only support the latest versions of {browser}.</text><text>Please update your OS to version 14 or above.</text>', values: {
                    browser: 'Safari',
                    heading: (chunks) => _jsx(Heading, { mt: "m", children: chunks }),
                    text: (chunks) => (_jsx(Text, { mt: "xs", maxWidth: 450, children: chunks })),
                } }) }));
    }
    if (!isPageAccessibleOnMobile) {
        return (_jsxs(Container, { "data-testid": "browser-check-message", children: [_jsx(Heading, { mt: "m", children: _jsx(FormattedMessage, { id: 'PL90K7', defaultMessage: 'This page can only run in {browserList} on desktop', values: {
                            browserList: getBrowserList(intl, 'disjunction'),
                        } }) }), _jsx(BrowserLinks, {}), _jsx(FormattedMessage, { id: 'if8DXJ', defaultMessage: '<text>Sorry, we only support the latest versions of {browserList} on desktop at the moment.</text> <text>Please open the link in one of those to get started.</text>', values: {
                        browserList: getBrowserList(intl),
                        browserLinks: _jsx(BrowserLinks, {}),
                        text: (chunks) => (_jsx(Text, { mt: "xs", maxWidth: 450, children: chunks })),
                    } })] }));
    }
    return (_jsxs(Container, { "data-testid": "browser-check-message", children: [_jsx(Heading, { mt: "m", children: _jsx(FormattedMessage, { id: 'yQGKz9', defaultMessage: 'Please use {browserList} to access this page', values: {
                        browserList: getBrowserList(intl, 'disjunction'),
                    } }) }), _jsx(BrowserLinks, {}), _jsx(FormattedMessage, { id: 'BLisy0', defaultMessage: '<text>Sorry, we only support the latest versions of {browserList} at the moment.</text> <text>Please open the link in one of those to get started.</text>', values: {
                    browserList: getBrowserList(intl),
                    browserLinks: _jsx(BrowserLinks, {}),
                    text: (chunks) => (_jsx(Text, { mt: "xs", maxWidth: 450, children: chunks })),
                } })] }));
});
export default BrowserCheck;
function getBrowserList(intl, type = 'conjunction') {
    const browserNames = Object.keys(supportedBrowsers);
    return intl.formatList(browserNames, { type });
}
function getMinimumSupportedBrowserVersion() {
    // eslint-disable-next-line no-restricted-syntax
    for (const [browserName, version] of Object.entries(supportedBrowsers)) {
        if (deviceDetection[`is${browserName}`]) {
            return version;
        }
    }
    return Infinity;
}
function isAccessibleOnMobile(pathname) {
    const accessiblePathsOnMobile = [
        '/record/',
        '/preview/',
        '/login',
        '/auth',
        '/website-record',
    ];
    if (deviceDetection.isMobile) {
        return accessiblePathsOnMobile.some((path) => pathname.includes(path));
    }
    return true;
}
const browserProps = {
    Chrome: {
        icon: _jsx(ChromeIcon, {}),
        url: 'https://google.com/chrome',
    },
    Edge: {
        icon: _jsx(EdgeIcon, {}),
        url: 'https://microsoft.com/windows/microsoft-edge',
    },
    Opera: { icon: _jsx(OperaIcon, {}), url: 'https://www.opera.com/' },
    Safari: { icon: _jsx(SafariIcon, {}), url: 'https://apple.com/safari' },
    Firefox: { icon: _jsx(FirefoxIcon, {}), url: 'https://mozilla.org/firefox' },
};
const BrowserLinks = observer(function BrowserLinks() {
    const browsers = Object.keys(supportedBrowsers);
    return (_jsx(Flex, { my: "m", direction: "row", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: 450, children: browsers.map((browser) => {
            const { icon, url } = browserProps[browser];
            return (_jsx("a", { href: url, target: "_blank", rel: "noopener noreferrer", title: `Download ${browser}`, children: _jsxs(Relative, { mr: browser === browsers[browsers.length - 1] ? '0' : 'm', children: [icon, _jsx(IconWrapper, { children: _jsx(Icon, { paths: CHECKBOX_TICK_ICON }) })] }) }, browser));
        }) }));
});
// This must be memoized component because useIsBrowserSupported uses useLocation which
// often changes despite the location not changing. If this re-renders Marvel3Provider unnecessarily
// a large chunk of the app will re-render and it can potentially breaks things where we use MobX
// and deleted children re-render before their parents who conditionally render them. (notably Prototype Slide Edit and Undo)
export const EnsureBrowserSupported = observer(function EnsureBrowserSupported({ children, ignoredPaths = [], }) {
    const { pathname } = useLocation();
    const isBrowserSupported = useIsBrowserSupported();
    // Record page provides is its own localized browser check
    if (ignoredPaths.some((path) => pathname.startsWith(path))) {
        return _jsx(_Fragment, { children: children });
    }
    if (!isBrowserSupported) {
        return _jsx(BrowserCheck, {});
    }
    return _jsx(_Fragment, { children: children });
});
export function useIsBrowserSupported() {
    const { pathname } = useLocation();
    if (!deviceDetection.osVersion || !deviceDetection.browserVersion) {
        return false;
    }
    if (deviceDetection.isMobile) {
        // On mobile we only support certain routes, anything else is not responsive yet
        const accessibleOnMobile = isAccessibleOnMobile(pathname);
        if (!accessibleOnMobile) {
            return false;
        }
        // If it is iPadOS, we only support a recent version of Safari / OS.
        // (Until iPadOS16, Safari and the OS use the same version number. From 16
        // onwards, the OS comes back as MacOS with a different version number)
        if (deviceDetection.isIPad) {
            // We don't need to check the browser version, since every other browser
            // downloadable from the App Store is just a reskinned version of Safari Mobile
            return (getMajorVersion(deviceDetection.osVersion) >=
                supportedBrowsers.Safari ||
                // From iPadOS 16 onwards, the OS version is reported as MacOS
                deviceDetection.isMacOs);
        }
        // If it is iOS we only support a recent version of Safari / OS
        // (they both use the same version number)
        if (deviceDetection.isIOS) {
            // We don't need to check the browser version, since every other browser
            // downloadable from the App Store is just a reskinned version of Safari Mobile
            return (getMajorVersion(deviceDetection.osVersion) >= supportedBrowsers.Safari);
        }
    }
    // On Android and desktop we only support modern browsers
    return (getMajorVersion(deviceDetection.browserVersion) >=
        getMinimumSupportedBrowserVersion());
}
function getMajorVersion(version) {
    return parseInt(version.split('.')[0], 10);
}
const Container = styled(PinAll).attrs({
    bg: 'background',
}) `
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 25px;
`;
const IconWrapper = styled(PinBR).attrs({
    bg: 'grass9',
    color: 'background',
    border: '2px solid',
    borderColor: 'background',
    borderRadius: 'circle',
}) `
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  bottom: -4px;
  right: -6px;
`;
