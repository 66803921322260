import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { useFeatureFlag } from '@marvelapp/ballpark-application';
import { BaseError } from './ErrorPages';
import { Link } from './Link';
export function Maintenance({ testId = 'maintenance', flag, children, }) {
    const isMaintenance = useFeatureFlag(flag);
    if (!isMaintenance)
        return _jsx(_Fragment, { children: children });
    return (_jsx(BaseError, { heading: "Scheduled Maintenance", subheading: _jsxs(_Fragment, { children: ["We're currently performing scheduled maintenance on this part of Ballpark. Visit our", ' ', _jsx(Link, { to: "https://status.ballparkhq.com/", children: "status page" }), " for updates."] }), testId: testId }));
}
