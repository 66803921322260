import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CLOSE_ICON } from '@marvelapp/ballpark-theme';
import { Box } from '@marvelapp/ui';
import { Button, CornerButton } from './Buttons';
import { Heading } from './Heading';
import { Icon } from './Icon';
import { ModalPortal } from './ModalPortal';
import { Text } from './Text';
export const ConfirmDialog = function ConfirmDialog({ onConfirmAction, description, heading, onCloseDialog, }) {
    return (_jsx(ModalPortal, { children: _jsx(Box, { position: "absolute", left: "0", top: "0", width: "100%", height: "100%", children: _jsxs(Box, { "data-testid": "confirm-dialog", position: "absolute", top: "40%", left: "40%", bg: "background", borderRadius: "xl", boxShadow: "popover.default", padding: ['m', 'm', 'l', 'xl'], maxWidth: "420px", minWidth: "290px", "text-align": "center", zIndex: "modal", children: [_jsx(CornerButton, { icon: _jsx(Icon, { paths: CLOSE_ICON }), kind: "negative", testId: "close-button", onClick: onCloseDialog }), _jsx(Heading, { "data-testid": "dialog-heading", fontWeight: "bold", children: heading }), _jsx(Text, { "data-testid": "dialog-description", size: "m", py: 3, children: description }), _jsx(Button, { "data-testid": "confirm-cta", kind: "negative", onClick: onConfirmAction, children: "Confirm" })] }) }) }));
};
