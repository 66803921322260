import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import * as React from 'react';
import { cn } from './utils';
const Root = React.forwardRef(function Root(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (_jsx(ScrollAreaPrimitive.Root, Object.assign({ className: cn('w-full', 'h-full', 'overflow-hidden', className), ref: ref, scrollHideDelay: 300 }, rest)));
});
const Viewport = React.forwardRef(function Viewport(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (_jsx(ScrollAreaPrimitive.Viewport, Object.assign({ className: cn('w-full', 'h-full', '[&>div]:!block', '[&>div]:min-h-full', '[&>div]:h-full', className), ref: ref }, rest)));
});
const Scrollbar = React.forwardRef(function Scrollbar(props, ref) {
    const { className, orientation } = props, rest = __rest(props, ["className", "orientation"]);
    return (_jsx(ScrollAreaPrimitive.Scrollbar, Object.assign({ className: cn('data-[state=visible]:motion-safe:animate-in', 'data-[state=visible]:motion-safe:fade-in', 'data-[state=visible]:motion-safe:duration-300', 'data-[state=hidden]:motion-safe:animate-out', 'data-[state=hidden]:motion-safe:fade-out', 'data-[state=hidden]:motion-safe:duration-500', 
        // Scrollbar
        '[&>div]:bg-gray-600/10', '[&>div]:hover:bg-gray-600/20', 
        // Thumb
        '[&>div>div]:bg-gray-600/30', '[&>div>div]:hover:bg-gray-600/50', {
            'py-3': orientation === 'vertical',
            'px-2': orientation === 'vertical',
            'py-2': orientation === 'horizontal',
            'px-3': orientation === 'horizontal',
        }, className), orientation: orientation, ref: ref }, rest, { children: _jsx("div", { className: cn('ease-smooth', 'rounded-full', 'duration-300', 'transition-colors', {
                'w-1.5': orientation === 'vertical',
                'h-full': orientation === 'vertical',
                'h-1.5': orientation === 'horizontal',
                'w-full': orientation === 'horizontal',
            }), children: _jsx(ScrollAreaPrimitive.Thumb, Object.assign({ className: cn('rounded-full', 'duration-300', 'ease-smooth', 'transition-colors', {
                    '!w-1.5': orientation === 'vertical',
                    '!h-1.5': orientation === 'horizontal',
                }), ref: ref }, rest)) }) })));
});
const ScrollAreaBase = React.forwardRef(function Scrollarea(props, ref) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (_jsxs(Root, Object.assign({ ref: ref }, rest, { children: [_jsx(Viewport, { children: children }), _jsx(Scrollbar, { orientation: "horizontal" }), _jsx(Scrollbar, { orientation: "vertical" })] })));
});
const ScrollArea = Object.assign(ScrollAreaBase, {
    Root,
    Viewport,
    Scrollbar,
    Thumb: ScrollAreaPrimitive.Thumb,
    Corner: ScrollAreaPrimitive.Corner,
});
export { ScrollArea };
