import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import { BigBuildingIcon, BuildingsIcon, CalendarTimeIcon, GrowthIcon, LockIcon, } from '@marvelapp/ballpark-icons';
import { BtwButton } from '../BtwButton';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { Progress } from '../Progress';
import { AppSidebar, SidebarNavItem, SidebarPanel } from '../Sidebar';
import { Stack } from '../Stack';
import { cn } from '../utils';
import { SettingsHeader } from './SettingsHeader';
var ActivePlan;
(function (ActivePlan) {
    ActivePlan["trial"] = "Trial";
    ActivePlan["starter"] = "Starter";
    ActivePlan["business"] = "Business";
    ActivePlan["enterprise"] = "Enterprise";
})(ActivePlan || (ActivePlan = {}));
function PlanUsageSettingsPage(props) {
    const { subscriptionFee, trialStartDate, trialEndDate, nextPaymentDate, activeProjects, totalProjects, creditsUsed, creditsBalance, totalCredits, annualCredits, activePlan, } = props;
    const currentDate = new Date();
    const isTrialExpired = currentDate >= trialEndDate;
    return (_jsxs(Stack, { width: "full", className: "h-full", direction: "row", children: [_jsx(AppSidebar, { initialMenuItem: SidebarNavItem.Settings_PlanUsage, initialActivePanel: SidebarPanel.Settings }), _jsxs(Stack, { className: "h-full overflow-y-scroll ", width: "full", children: [_jsxs(SettingsHeader.Container, { children: [_jsx(SettingsHeader.Title, { children: "Plan & Usage" }), _jsx(SettingsHeader.Actions, { children: _jsx(BtwButton, { size: "sm", children: "Change billing details" }) })] }), _jsx(Stack, { width: "full", gap: "8", className: "mx-auto h-full max-w-6xl flex-1 overflow-y-scroll", children: _jsxs(Stack, { width: "full", className: "h-full p-8", gap: "8", children: [_jsx(ActivePlanCard, { activePlan: activePlan, subscriptionFee: subscriptionFee, annualCredits: 100, nextPaymentDate: nextPaymentDate, trialStartDate: trialStartDate, trialEndDate: trialEndDate, currentDate: currentDate, isTrialExpired: isTrialExpired }), _jsxs(Stack, { direction: { initial: 'col', md: 'row' }, gap: "8", align: "stretch", width: "full", children: [_jsx(ProjectsCard, { activeProjects: activeProjects, totalProjects: totalProjects, activePlan: activePlan, isTrialExpired: isTrialExpired }), _jsx(CreditsCard, { creditsBalance: creditsBalance, totalCredits: totalCredits, annualCredits: annualCredits, activePlan: activePlan, isTrialExpired: isTrialExpired, creditsUsed: creditsUsed })] })] }) })] })] }));
}
function ActivePlanCard(props) {
    const { activePlan, subscriptionFee, trialStartDate, trialEndDate, annualCredits, nextPaymentDate, isTrialExpired, currentDate, } = props;
    return (_jsxs(Stack, { width: "full", className: "rounded-xl bg-white shadow-sm ring-1 ring-gray-600/10", children: [_jsxs(Stack, { width: "full", direction: { initial: 'col', sm: 'row' }, gap: { initial: '6', md: '8' }, className: "relative px-8 py-6", align: "start", children: [_jsx(Stack, { className: cn('absolute', 'top-6', 'right-8', 'sm:relative', 'sm:top-0', 'sm:right-0'), children: _jsx(ActivePlanIcon, { activePlan: activePlan, isTrialExpired: isTrialExpired }) }), _jsxs(Stack, { direction: { initial: 'col', sm: 'row' }, width: "full", gap: "8", wrap: "wrap", className: "gap-y-2 *:flex-1 lg:*:flex-initial lg:last:*:flex-1", children: [_jsxs(Stack, { className: 
                                // Making sure the first column doesn't grow in size when the plan is Enterprise
                                // or Trial that is expired. Helps with layout consistency. Trust me!
                                activePlan === ActivePlan.enterprise ||
                                    (activePlan === ActivePlan.trial && isTrialExpired)
                                    ? '!flex-initial'
                                    : '', children: [_jsx(BtwText, { size: "13", weight: "medium", children: "Plan" }), _jsx(BtwHeading, { size: "lg", weight: "medium", children: activePlan })] }), activePlan === ActivePlan.trial && !isTrialExpired && (_jsxs(_Fragment, { children: [_jsxs(Stack, { children: [_jsx(BtwText, { size: "13", weight: "medium", children: "Amount" }), _jsx(BtwHeading, { size: "lg", weight: "medium", children: "Free" })] }), _jsxs(Stack, { children: [_jsx(BtwText, { size: "13", weight: "medium", children: "Trial ends" }), _jsx(BtwHeading, { size: "lg", weight: "medium", className: "text-nowrap", children: trialEndDate && formatDate(trialEndDate) })] })] })), activePlan === ActivePlan.trial && isTrialExpired && (_jsxs(Stack, { className: "flex-auto md:w-min", children: [_jsx(BtwText, { size: "13", weight: "medium", children: "Details" }), _jsx(BtwText, { size: "sm", variant: "primary", className: "pt-1", children: "Your trial period has ended. Upgrade to a paid plan to continue using the platform." })] })), (activePlan === ActivePlan.starter ||
                                activePlan === ActivePlan.business) && (_jsxs(_Fragment, { children: [_jsxs(Stack, { children: [_jsx(BtwText, { size: "13", weight: "medium", children: "Amount" }), _jsxs(BtwHeading, { size: "lg", weight: "medium", className: "text-nowrap", children: ["$", subscriptionFee, " / month"] })] }), _jsxs(Stack, { children: [_jsx(BtwText, { size: "13", weight: "medium", children: "Credits" }), _jsxs(BtwHeading, { size: "lg", weight: "medium", className: "text-nowrap", children: [annualCredits, " / year"] })] }), _jsxs(Stack, { children: [_jsx(BtwText, { size: "13", weight: "medium", children: "Next payment" }), _jsx(BtwHeading, { size: "lg", weight: "medium", className: "text-nowrap", children: nextPaymentDate &&
                                                    nextPaymentDate.toLocaleDateString('en-GB', {
                                                        dateStyle: 'medium',
                                                    }) })] })] })), activePlan === ActivePlan.enterprise && (_jsxs(Stack, { className: "flex-auto md:w-min", children: [_jsx(BtwText, { size: "13", weight: "medium", children: "Details" }), _jsxs(BtwText, { size: "sm", variant: "primary", className: "pt-1", children: ["This plan is billed manually. Please contact", ' ', _jsx("a", { href: "mailto:experience@ballparkhq.com", className: "!underline", children: "experience@ballparkhq.com" }), ' ', "to discuss your plan."] })] }))] }), activePlan === ActivePlan.trial && (_jsx(Stack, { className: "shrink-0 pt-1.5", children: _jsx(BtwButton, { size: "intermediate", variant: "primary", children: "Purchase plan" }) })), (activePlan === ActivePlan.starter ||
                        activePlan === ActivePlan.business) && (_jsx(Stack, { className: "shrink-0 pt-1.5", children: _jsx(BtwButton, { size: "intermediate", variant: "primary", children: "Upgrade" }) }))] }), activePlan === ActivePlan.trial && !isTrialExpired && (_jsx(TrialProgressBar, { trialStartDate: trialStartDate, trialEndDate: trialEndDate, currentDate: currentDate }))] }));
}
function ActivePlanIcon(props) {
    const { activePlan, isTrialExpired } = props;
    const activePlanIconBaseClasses = [
        // Base styles
        'rounded-full',
        'p-3',
    ];
    switch (activePlan) {
        case ActivePlan.trial:
            return isTrialExpired ? (_jsx(Stack, { className: cn(activePlanIconBaseClasses, 'bg-amber-400'), children: _jsx(LockIcon, { className: "size-5 text-amber-50" }) })) : (_jsx(Stack, { className: cn(activePlanIconBaseClasses, 'bg-amber-200'), children: _jsx(CalendarTimeIcon, { className: "size-5 text-amber-600" }) }));
        case ActivePlan.starter:
            return (_jsx(Stack, { className: cn(activePlanIconBaseClasses, 'bg-lime-200'), children: _jsx(GrowthIcon, { className: "size-5 text-lime-600" }) }));
        case ActivePlan.business:
            return (_jsx(Stack, { className: cn(activePlanIconBaseClasses, 'bg-indigo-200'), children: _jsx(BuildingsIcon, { className: "size-5 text-indigo-600" }) }));
        case ActivePlan.enterprise:
            return (_jsx(Stack, { className: cn(activePlanIconBaseClasses, 'bg-purple-200'), children: _jsx(BigBuildingIcon, { className: "size-5 text-purple-600" }) }));
        default:
            return null;
    }
}
function TrialProgressBar(props) {
    const { trialStartDate, trialEndDate, currentDate } = props;
    let trialLength;
    let trialDaysUsed;
    let trialDaysLeft;
    let percentage = 0;
    // Get the lenght of the trial period in days
    if (trialEndDate && trialStartDate) {
        trialLength = calculateDaysDifference(trialEndDate, trialStartDate);
    }
    // Get the number of trial days used
    if (trialStartDate) {
        trialDaysUsed = calculateDaysDifference(currentDate, trialStartDate);
    }
    // Get the number of days left in the trial
    if (trialEndDate) {
        trialDaysLeft = calculateDaysDifference(trialEndDate, currentDate);
    }
    if (trialDaysUsed && trialLength) {
        percentage = (trialDaysUsed / trialLength) * 100;
    }
    return (_jsxs(Stack, { width: "full", className: "px-8 py-3", direction: "row", gap: "6", align: "center", children: [_jsx(Stack, { width: "full", children: _jsx(Progress.Animated, { value: percentage }) }), _jsx(Stack, { className: "shrink-0", children: _jsxs(BtwText, { size: "13", weight: "medium", children: [trialDaysLeft, " days left"] }) })] }));
}
function ProjectsCard(props) {
    const { activeProjects, totalProjects, activePlan, isTrialExpired } = props;
    // Setting the corrent heading
    let heading;
    if (activePlan === ActivePlan.trial) {
        if (isTrialExpired) {
            heading = 'Projects locked';
        }
        else {
            heading = 'Unlimited';
        }
    }
    else {
        heading = `${activeProjects} active projects`;
    }
    // Setting the correct description
    let description;
    if (activePlan === ActivePlan.trial && !isTrialExpired) {
        description =
            'You can create unlimited projects as part of your trial. Explore our plans to find our more.';
    }
    else if (activePlan === ActivePlan.trial && isTrialExpired) {
        description = `You created ${activeProjects} projects during your trial \u2014 sounds productive! To create new projects and access previous ones, you need to upgrade to a paid plan.`;
    }
    else if (activePlan !== ActivePlan.trial &&
        activeProjects >= totalProjects) {
        description =
            'You have reached the maximum concurrent active projects under your current plan. You can either archive some projects or consider upgrading.';
    }
    else {
        description = ` You can have a total of ${totalProjects} active projects with your current plan \u2014 this means you can launch ${totalProjects - activeProjects} more active studies! 👏`;
    }
    const actions = activePlan !== ActivePlan.enterprise && (_jsx(_Fragment, { children: activePlan === ActivePlan.trial ? (_jsx(BtwButton, { size: "intermediate", variant: "primary", className: "w-full lg:w-auto", children: "Purchase plan" })) : (_jsx(BtwButton, { size: "intermediate", variant: "primary", className: "w-full lg:w-auto", children: "Upgrade" })) }));
    return (_jsx(_Fragment, { children: _jsx(AllowanceCard, { subHeading: "Projects", heading: heading, description: description, allowanceValue: activeProjects, allowanceMax: totalProjects, showProgressBar: activePlan !== ActivePlan.trial ||
                (activePlan === ActivePlan.trial && !isTrialExpired), actions: actions, isTrialExpired: isTrialExpired && activePlan === ActivePlan.trial }) }));
}
function CreditsCard(props) {
    const { creditsUsed, creditsBalance, totalCredits, annualCredits, activePlan, isTrialExpired, } = props;
    // Setting the description based on plan
    let heading;
    if (creditsBalance <= 0) {
        heading = "You've used up all your credits";
    }
    else {
        heading = `${creditsBalance} credits left`;
    }
    let description;
    if (activePlan === ActivePlan.trial && !isTrialExpired) {
        description =
            'You have 10 recruitment minutes as part of your trial allowance. You can purchase more as needed.';
    }
    else if (activePlan === ActivePlan.trial && isTrialExpired) {
        description =
            'To continue using your recruitment minutes, you need to upgrade to a paid plan.';
    }
    else if (activePlan !== ActivePlan.trial && creditsBalance <= 0) {
        description =
            "You've used up all of your recruitment credits. You need to top up your credits balance if you want to recruit more participants for your studies.";
    }
    else {
        description = `You have ${annualCredits} recruitment minutes per year with your current plan. You can always buy more credits when you need them.`;
    }
    // Actions
    const actions = (
    // Doing some funky dance here to make sure the buttons look nice across the various viewports.
    _jsxs(Stack, { direction: { initial: 'col', sm: 'row', md: 'col', lg: 'row' }, gap: "4", width: "full", className: "*:w-full *:lg:w-auto", children: [_jsx(BtwButton, { size: "intermediate", children: "Buy credits" }), _jsx(BtwButton, { size: "intermediate", variant: "ghost", children: "View usage" })] }));
    return (_jsx(AllowanceCard, { subHeading: "Recruitment credits", heading: heading, description: description, showProgressBar: true, allowanceValue: creditsUsed, allowanceMax: totalCredits, actions: actions, isTrialExpired: isTrialExpired && activePlan === ActivePlan.trial }));
}
function AllowanceCard(props) {
    const { subHeading, heading, description, showProgressBar, allowanceValue, allowanceMax, actions, isTrialExpired, } = props;
    return (_jsx(Stack, { className: "relative overflow-hidden rounded-xl border border-gray-600/10 bg-gray-50 bg-[radial-gradient(#D1D5DB_1px,transparent_1px)] bg-right-top shadow-sm [background-size:16px_16px]", width: "full", children: _jsxs(Stack, { gap: "6", className: "flex-1 bg-gradient-to-tr from-gray-50 to-gray-50/50 px-8 py-6", width: "full", children: [_jsxs(Stack, { gap: "6", width: "full", className: "flex-1", children: [_jsxs(Stack, { gap: "3", width: "full", children: [_jsxs(Stack, { direction: { initial: 'colReverse', md: 'row' }, gap: "4", width: "full", align: { initial: 'start', md: 'center' }, children: [_jsxs(Stack, { gap: { initial: '2', md: '3' }, width: "full", children: [_jsx(BtwText, { size: "13", weight: "medium", children: subHeading }), _jsx(BtwHeading, { size: "lg", weight: "medium", className: "text-nowrap", children: heading })] }), isTrialExpired && (_jsx("div", { className: "", children: _jsx(ActivePlanIcon, { isTrialExpired: true, activePlan: ActivePlan.trial }) }))] }), _jsx(BtwText, { size: "13", children: description })] }), showProgressBar && (_jsx(AllowanceProgressBar, { value: allowanceValue, max: allowanceMax }))] }), actions && actions] }) }));
}
function AllowanceProgressBar(props) {
    const { value, max } = props;
    const percentage = (value / max) * 100;
    return (_jsxs(Stack, { direction: "row", gap: "8", align: "center", width: "full", children: [_jsx(Stack, { width: "full", children: _jsx(Progress.Animated, { value: percentage }) }), _jsx(Stack, { className: "shrink-0", children: _jsxs(BtwText, { size: "sm", weight: "medium", children: [value, "/", max, ' ', _jsx("span", { className: "sm:inline md:hidden lg:inline", children: "used" })] }) })] }));
}
function formatDate(date) {
    return new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium' }).format(date);
}
function calculateDaysDifference(start, end) {
    // I have to create new Date objects again because Storybook's knobs will create
    // a Unix timestamp and the getTime() will poop out.
    const startDate = new Date(start);
    const endDate = new Date(end);
    const timeDifference = startDate.getTime() - endDate.getTime();
    return Math.ceil(timeDifference / (1000 * 3600 * 24));
}
export { PlanUsageSettingsPage, ActivePlan };
