import { __rest } from "tslib";
import { useQuery } from '@apollo/client';
import { cloneDeep, get, once } from 'lodash-es';
import { useCallback } from 'react';
import { NetworkStatusCode } from '@marvelapp/core';
/** @type any */
export const createPaginationUpdateQuery = (getDataConnection) => {
    return (previousResult, { fetchMoreResult }) => {
        const newConnection = getDataConnection(fetchMoreResult);
        const nextResult = cloneDeep(previousResult);
        const nextConnection = getDataConnection(nextResult);
        nextConnection.pageInfo = newConnection.pageInfo;
        nextConnection.edges.push(...newConnection.edges);
        return nextResult;
    };
};
export function usePaginatedQuery(query, _a) {
    var { dataConnection, getVariables = ({ pageInfo: { endCursor } }) => ({
        cursor: endCursor,
    }) } = _a, queryOptions = __rest(_a, ["dataConnection", "getVariables"]);
    const getDataConnection = useCallback((data) => get(data, dataConnection, {
        edges: undefined,
        pageInfo: {
            hasNextPage: undefined,
        },
    }), [dataConnection]);
    const queryResult = useQuery(query, queryOptions);
    const { data, error, fetchMore, networkStatus } = queryResult;
    const connection = getDataConnection(data);
    const { edges, pageInfo: { hasNextPage }, } = connection;
    const isFirstLoad = networkStatus === NetworkStatusCode.Loading;
    const isLoading = isFirstLoad || networkStatus === NetworkStatusCode.SetVariables;
    const isLoadingMore = networkStatus === NetworkStatusCode.FetchMore;
    const loadNextPage = useCallback(once(() => {
        if (edges === undefined || error || !hasNextPage)
            return Promise.resolve();
        return fetchMore({
            variables: getVariables(connection),
            updateQuery: createPaginationUpdateQuery(getDataConnection),
        });
        // only create a new loadNextPage function if variables will change to prevent
        // multiple of the same page being loaded before the networkStatus is updated
    }), [error, hasNextPage, ...Object.values(getVariables(connection))]);
    return Object.assign({ data,
        edges,
        getDataConnection,
        hasNextPage,
        isLoading,
        isLoadingMore,
        isFirstLoad,
        loadNextPage }, queryResult);
}
