import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { Heading } from './Heading';
import { Text } from './Text';
export function EmptyState({ image, title = 'No results just yet', description = (_jsxs(_Fragment, { children: ["Nobody has completed the test yet. ", _jsx("br", {}), " Come back to check later!"] })), testid = 'empty-state', }) {
    return (_jsxs(Container, { "data-testid": testid, children: [image, _jsx(Heading, { size: "l", children: title }), _jsx(Text, { as: "p", size: "m", fontSize: 14, children: description })] }));
}
export const Container = styled(Box) `
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  p {
    margin-top: ${(props) => props.theme.space.xxs}px;
  }
`;
export const EmptyStateWrapper = styled(Box).attrs((props) => ({
    borderRadius: 1,
    px: 4,
    py: 6,
    border: '1px dashed',
    borderColor: 'smoke',
    height: props.height,
})) `
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;
