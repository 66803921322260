import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Popover from '@radix-ui/react-popover';
import * as React from 'react';
import styled, { keyframes } from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { Label } from './Label';
import { Text } from './Text';
import { Toggle as InputToggle } from './Toggle';
const slideUp = keyframes `
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
const slideDown = keyframes `
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
const Content = styled(Popover.Content) `
  max-height: 600px;
  overflow-y: scroll;
  border-radius: ${(props) => props.borderRadius
    ? props.theme.radii[props.borderRadius]
    : props.theme.raddi.m}px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: ${(props) => props.theme.shadows.popover.default};
  z-index: ${(props) => props.zIndex || props.theme.zIndices.popover};

  /* animation-duration: 350ms;
  animation-timing-function: ${(props) => props.theme.easings.smooth.string};

  &[data-side='top'] {
    animation-name: ${slideUp};
  }
  &[data-side='bottom'] {
    animation-name: ${slideDown};
  } */
`;
Content.defaultProps = {
    borderRadius: 'm',
    sideOffset: 8,
};
// Dropdown.Inner
const Inner = styled(Box).attrs({}) `
  width: ${(props) => (props.width ? props.width : '320px')};
  padding: ${(props) => (props.padding ? props.padding : '24px')};
`;
// Dropdown.Row
const Row = styled(Box).attrs({
    borderBottom: '1px solid',
    borderColor: 'slate6',
    marginBottom: 'm',
    paddingBottom: 'm',
}) `
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }
`;
const Inline = styled(Box) `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Icon = styled(Inline) `
  justify-content: flex-start;
  svg {
    margin-right: ${(props) => props.theme.space.xs}px;
  }
`;
// Dropdown.Toggle
function Toggle({ checked, description, disabled, icon, id, onChange, testId, title, }) {
    return (_jsxs(Row, { children: [_jsxs(Inline, { width: 1, children: [_jsxs(Icon, { children: [icon, _jsx(Label, { paddingTop: "2px", fontSize: 14, htmlFor: id, size: "m", children: title })] }), _jsx(InputToggle, { checked: checked, disabled: disabled, id: id, onChange: onChange, size: 40, testId: testId })] }), description && (_jsx(Text, { marginTop: "xxs", size: "s", paddingRight: "34px", children: description }))] }));
}
const { Root, Trigger, Anchor, Close, Arrow, Portal } = Popover;
export { Root, Trigger, Anchor, Close, Arrow, Content, Toggle, Inner, Portal };
