import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { LoaderIcon } from '@marvelapp/ballpark-icons';
import { cn } from './utils';
function Loader(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (_jsx(LoaderIcon, Object.assign({ "data-testid": "loader", className: cn('animate-spin', className) }, rest)));
}
export { Loader };
