import { useCallback, useEffect } from 'react';
export function useFetchMoreOnScroll({ isLoadingResults, hasNextPage, fetchNextPage, scrollContainerRef, }) {
    // Called on scroll and possibly on mount to fetch more data as the user
    // scrolls and reaches bottom of table
    const fetchMoreOnBottomReached = useCallback((containerRefElement) => {
        if (containerRefElement) {
            const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
            // Once the user has scrolled within 300px of the bottom of the table,
            // fetch more data if there is any
            const threshold = 300;
            if (scrollHeight - scrollTop - clientHeight < threshold &&
                !isLoadingResults &&
                hasNextPage) {
                fetchNextPage();
            }
        }
    }, [fetchNextPage, isLoadingResults, hasNextPage]);
    // Check on mount and after a fetch to see if the container is already scrolled
    // to the bottom and immediately needs to fetch more data
    useEffect(() => {
        fetchMoreOnBottomReached(scrollContainerRef.current);
    }, [fetchMoreOnBottomReached, scrollContainerRef]);
    const onScroll = useCallback((e) => fetchMoreOnBottomReached(e.target), [fetchMoreOnBottomReached]);
    return onScroll;
}
