import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';
import { tv } from 'tailwind-variants';
const stack = tv({
    base: ['flex'],
    variants: {
        align: {
            baseline: ['items-baseline'],
            center: ['items-center', 'text-center'],
            end: ['items-end', 'text-right'],
            start: ['items-start', 'text-left'],
            stretch: ['items-stretch'],
        },
        direction: {
            row: ['flex-row'],
            col: ['flex-col'],
            colReverse: ['flex-col-reverse'],
            rowReverse: ['flex-row-reverse'],
        },
        flex: {
            '1': ['flex-1'],
            auto: ['flex-auto'],
            initial: ['flex-initial'],
            none: ['flex-none'],
        },
        wrap: {
            wrap: ['flex-wrap'],
            nowrap: ['flex-nowrap'],
        },
        justify: {
            start: ['justify-start'],
            center: ['justify-center'],
            end: ['justify-end'],
            between: ['justify-between'],
            stretch: ['justify-stretch'],
        },
        gap: {
            '0': ['gap-0'],
            px: ['gap-px'],
            '0.5': ['gap-0.5'],
            '1': ['gap-1'],
            '1.5': ['gap-1.5'],
            '2': ['gap-2'],
            '2.5': ['gap-2.5'],
            '3': ['gap-3'],
            '3.5': ['gap-3.5'],
            '4': ['gap-4'],
            '5': ['gap-5'],
            '6': ['gap-6'],
            '7': ['gap-7'],
            '8': ['gap-8'],
            '9': ['gap-9'],
            '10': ['gap-10'],
            '11': ['gap-11'],
            '12': ['gap-12'],
            '14': ['gap-14'],
            '16': ['gap-16'],
            '20': ['gap-20'],
            '24': ['gap-24'],
            '28': ['gap-28'],
            '32': ['gap-32'],
            '36': ['gap-36'],
            '40': ['gap-40'],
            '44': ['gap-44'],
            '48': ['gap-48'],
            '52': ['gap-52'],
            '56': ['gap-56'],
            '60': ['gap-60'],
            '64': ['gap-64'],
            '72': ['gap-72'],
            '80': ['gap-80'],
            '96': ['gap-96'],
        },
        width: {
            full: ['w-full'],
        },
        height: {
            full: ['h-full'],
        },
    },
    defaultVariants: {
        align: 'start',
        direction: 'col',
        wrap: 'nowrap',
    },
}, {
    responsiveVariants: true,
});
/**
  Renders a stack of elements with a gap in-between
  */
const Stack = React.forwardRef(function Stack(props, ref) {
    const { align, asChild, className, direction, flex, gap, height, justify, width, wrap } = props, rest = __rest(props, ["align", "asChild", "className", "direction", "flex", "gap", "height", "justify", "width", "wrap"]);
    const Comp = asChild ? Slot : 'div';
    return (_jsx(Comp, Object.assign({ className: stack({
            align,
            className,
            direction,
            flex,
            gap,
            height,
            justify,
            width,
            wrap,
        }), ref: ref }, rest)));
});
export { Stack };
