import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { forwardRef } from 'react';
import * as React from 'react';
import { tv } from 'tailwind-variants';
const checkToggleVariants = tv({
    base: [
        // Basic reset
        'select-none',
        'cursor-pointer',
        'appearance-none',
        'border',
        'border-transparent',
        'ring-1',
        'ring-transparent',
        // Typography
        'tracking-normal',
        // Transition
        'duration-300',
        'ease-smooth',
        'transition-combined',
        'will-change-transform',
        // Active styles
        'active:translate-y-px',
        'active:outline-transparent',
        // Focus styles
        'focus-visible:outline-none',
        'focus-visible:outline-gray-300',
        // Disabled styles
        'disabled:bg-gray-200',
        'disabled:text-gray-500',
        'disabled:ring-transparent',
        'disabled:shadow-none',
        'disabled:cursor-not-allowed',
        'disabled:hover:translate-y-0',
        'disabled:active:translate-y-0',
    ],
    variants: {
        variant: {
            base: [],
            outline: [
                // Unchecked
                'data-[state=unchecked]:border-gray-600/10',
                'data-[state=unchecked]:hover:-translate-y-px',
                'data-[state=unchecked]:hover:bg-gray-50',
                'data-[state=unchecked]:hover:border-gray-600/20',
                'data-[state=unchecked]:active:translate-y-px',
                'data-[state=unchecked]:active:bg-gray-100',
                'data-[state=unchecked]:hover:border-gray-600/30',
                // Checked
                'data-[state=checked]:ring-gray-600',
                'data-[state=checked]:border-gray-600',
            ],
            radio: [
                'size-4',
                'border-gray-600/20',
                'bg-white',
                // Checked
                'data-[state=checked]:relative',
                'data-[state=checked]:[&>span]:after:absolute',
                'data-[state=checked]:[&>span]:after:top-1/2',
                'data-[state=checked]:[&>span]:after:left-1/2',
                'data-[state=checked]:[&>span]:after:-translate-x-2/4',
                'data-[state=checked]:[&>span]:after:-translate-y-2/4',
                'data-[state=checked]:[&>span]:after:bg-gray-900',
                'data-[state=checked]:[&>span]:after:rounded-full',
                'data-[state=checked]:[&>span]:after:size-[7px]',
                'data-[state=checked]:[&>span]:after:block',
            ],
        },
        size: {
            base: [],
            sm: ['p-0', 'rounded-full'],
            md: ['px-5', 'py-4', 'rounded-lg'],
        },
    },
    compoundVariants: [],
    defaultVariants: {
        size: 'md',
        variant: 'outline',
    },
});
const CheckToggle = forwardRef(function Root(props, ref) {
    const { className, variant, size } = props, rest = __rest(props, ["className", "variant", "size"]);
    return (_jsx(CheckboxPrimitive.Root, Object.assign({ className: checkToggleVariants({ className, variant, size }), ref: ref }, rest)));
});
export { CheckToggle, checkToggleVariants };
